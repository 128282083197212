import React from 'react';
import { Switch } from 'react-router-dom';

import Projects from '../../user/projects/Projects';
import Project from '../../user/projects/Project';
import NoMatch from '../NoMatch';
import Companies from '../../user/companies/Companies';
import Company from '../../user/companies/Company';
import Questionnaires from '../../user/questionnaires/Questionnaires';
import Questionnaire from '../../user/questionnaires/Questionnaire';
import Route from '../../Route';

const UserRoutes = () =>
    <Switch>
        <Route path='/' exact component={Projects} />
        <Route path='/projects/:id/:type' component={Project} />
        <Route path='/companies' exact component={Companies} />
        <Route path='/companies/:id/:type' component={Company} />
        <Route path='/questionnaires' exact component={Questionnaires} />
        <Route path='/questionnaires/:id/:type' component={Questionnaire} />
        <Route component={NoMatch} />
    </Switch>;

export default UserRoutes;
