import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path, toPairs, isEmpty, pathOr } from 'ramda';
import { Button, Tooltip } from 'antd';
import { GlobalOutlined, LayoutOutlined, EditOutlined, TranslationOutlined } from '@ant-design/icons';

import { Title, Block } from '../ProjectInfo';
import { openProjectLanguagesModal, openProjectPageElementsModal, openDefaultProjectLanguageModal } from '../../../../actions/modalActions';

class ProjectLanguages extends Component {
    render() {
        const { project, openProjectLanguagesModal, openProjectPageElementsModal, openDefaultProjectLanguageModal } = this.props;
        const pageElements = pathOr({}, ['translations', 'pageElements'], project);

        return <div style={{ marginTop: 10 }}>
            <Title>
                <span><GlobalOutlined /> Языки</span>
                <Button shape='circle' icon={<EditOutlined />} onClick={() => openProjectLanguagesModal(project)} />
            </Title>
            <Block>
                <div><strong>Языки: </strong>{ !isEmpty(project.languages) ?
                    toPairs(project.languages).map(([_, language], index) => <span key={`language-${index}`}>{ language }{ index < toPairs(project.languages).length - 1 ? ', ' : '' }</span>) : '-'
                }</div>
                { project.defaultLanguage &&
                    <div>
                        <strong>Язык по умолчанию: </strong>{ project.languages[project.defaultLanguage] }
                        { toPairs(project.languages).length > 1 &&
                            <Tooltip title='Редактировать дефолтный язык'>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    icon={<TranslationOutlined />}
                                    onClick={() => openDefaultProjectLanguageModal(project)} />
                            </Tooltip>
                        }
                    </div>
                }
                { !!toPairs(project.languages).length &&
                    <div><strong>Выбор языка первым экраном: </strong>{ path(['settings', 'theme', 'chooseLanguageFirst'], project) ? 'Да' : 'Нет' }</div>
                }
            </Block>
            <Title>
                <span><LayoutOutlined /> Элементы страницы</span>
                <Button shape='circle' icon={<EditOutlined />} onClick={() => openProjectPageElementsModal(project)} />
            </Title>
            <Block>
                <div><strong>Кнопка "Начать":</strong> { pageElements.startButton || 'Начать опрос' }</div>
                <div><strong>Кнопка "Продолжить":</strong> { pageElements.continueButton || 'Продолжить' }</div>
                <div><strong>Кнопка "Ответить":</strong> { pageElements.submitButton || 'Ответить' }</div>
                <div><strong>Заголовок на завершение:</strong> { pageElements.finishTitle || 'Опрос завершен' }</div>
                <div><strong>Текст на завершение:</strong> { pageElements.finishText ||  'Спасибо за участие!' }</div>
                <div><strong>Текст выбора:</strong> { pageElements.chooseText || 'Ниже представлен ряд утверждений, которые Вам предлагается оценить. Выберите насколько Вы согласны со следующими утверждениями' }</div>
                <div><strong>Текст выбора языка:</strong> { pageElements.chooseLanguage || 'Выберите язык' }</div>
                <div><strong>Плейсхолдер текстовых полей:</strong> { pageElements.placeholder || 'Ответ' }</div>
                <div><strong>Кнопка "Комментарий":</strong> { pageElements.commentButton || 'Комментарий' }</div>
                <div><strong>Чекбокс "Не знаю":</strong> { pageElements.knowNothing || 'Не знаю' }</div>
            </Block>
        </div>;
    }
}

export default connect(null, { openProjectLanguagesModal, openProjectPageElementsModal, openDefaultProjectLanguageModal })(ProjectLanguages);
