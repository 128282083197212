import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { toPairs, pathOr, keys, path } from 'ramda';

import { getReportBlockDistribution } from '../../../../actions/asyncActions';
import withReportFilters from '../../../hocs/withReportFilters';
import CenterSpin from '../../../CenterSpin';
import { TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';
import withReportWrapper from '../../../hocs/withReportWrapper';
import withChartPngDownload from '../../../hocs/withChartPngDownload';

export class ReportBlockDistributionChartComponent extends Component {
    static defaultProps = {
        settings: {}
    };

    getOptions = () => {
        const { getReportBlockDistribution, width, fontFamily, title, pdf, staticData, settings, titleColor, titleSize, two, three, config } = this.props;
        const data = toPairs(staticData || getReportBlockDistribution.data);
        const scale = keys(pathOr({}, [0, 1], data));

        return {
            chart: {
                type: 'bar',
                width,
                marginRight: 20,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                },
                events: path(['chartEvents'], config)
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            title: {
                text: title,
                style: pdf ? {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1)
                } : {}
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: data.map(([ category ]) => category)
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    step: 2
                },
                title: {
                    text: null
                }
            },
            legend: {
                symbolHeight: 11,
                symbolWidth: 11,
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: 'normal',
                    fontSize: pdf ? 10 : undefined
                },
                ...pathOr({}, ['legend'], config)
            },
            tooltip: {
                pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            series: scale.map(name => ({
                name,
                data: data.map(([ _, stat ]) => pathOr(0, [name, 'percent'], stat)),
                color: (settings.scaleColor || {})[name]
            }))
        };
    }

    render() {
        const { getReportBlockDistribution, staticData } = this.props;

        return !staticData && getReportBlockDistribution.meta.pending && !getReportBlockDistribution.meta.lastSucceedAt ?
            <CenterSpin /> :
            <HighchartsReact
                ref={this.props.getRef}
                highcharts={Highcharts}
                options={this.getOptions()} />;
    }
}

export const ReportBlockDistributionChart = withChartPngDownload(withReportWrapper(ReportBlockDistributionChartComponent, true));

export default withReportFilters(withAsyncActions({
    getReportBlockDistribution: getReportBlockDistribution
        .withParams(({ params }) => params)
        .withPayload(({ id, reportFilter }) => ({
            id,
            q: reportFilter
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ReportBlockDistributionChart));
