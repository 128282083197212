import { all } from 'redux-saga/effects';
import { getAsyncSagas } from 'react-async-client';

import appRun from './appRun';
import login from './login';

export default function* () {
    yield all([
        ...getAsyncSagas(),
        appRun(),
        login()
    ]);
}
