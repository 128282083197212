import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { splitEvery } from 'ramda';
import styled from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const Item = styled(Col)`
    border: 1px solid #e8e8e8;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: ${({ selected }) => selected ? '#40a9ff' : 'rgba(0, 0, 0, 0.65);'};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .anticon {
        font-size: 30px;
        margin-bottom: 10px;
    }
`;

class PdfGeneratorPageType extends Component {
    render() {
        const { options, input: { value, name }, onChange } = this.props;
        const rows = splitEvery(options.length / 2, options);

        return <div style={{ border: '1px solid #e8e8e8' }}>
            { rows.map((row, index) =>
                <Row key={`${name}-row-${index}`}>
                    { row.map(col =>
                        <Item
                            key={`${name}-${col.id}`}
                            sm={24 / row.length}
                            xs={24}
                            selected={value === col.id}
                            onClick={() => onChange(col.id)}>
                            { col.icon }
                            <div>{ col.value }</div>
                        </Item>
                    )}
                </Row>
            )}
        </div>
    }
}

export default withFieldWrapper(PdfGeneratorPageType);
