import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';
import { Field } from 'react-final-form';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import VariablesInput from './formComponents/VariablesInput';
import { COMPANY_LETTER_VARIABLES } from '../../constants/company';

class SendCompanyLetterForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='subject'
                component={Input}
                label='Заголово письма' />
            <Field
                name='content'
                component={VariablesInput}
                label='Текст письма'
                variables={COMPANY_LETTER_VARIABLES} />
            <Field
                name='detailTemplate'
                component={VariablesInput}
                label='Шаблон для респондентов'
                variables={COMPANY_LETTER_VARIABLES} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    subject: yup.string().required(),
    content: yup.string().required(),
    detailTemplate: yup.string().required()
});

export default withFormWrapper(SendCompanyLetterForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
