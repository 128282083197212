import React, { Component, Fragment } from 'react';
import { find, path, toPairs, fromPairs, pathOr } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Form, Button } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Radio from './formComponents/Radio';
import Switch from './formComponents/Switch';
import ListenerField from './ListenerField';
import { parseToPatch } from '../../utils/forms';

const LanguageItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .ant-form-item {
        margin-bottom: 0;
        width: 50%;
        margin-right: 10px;
    }
`;

const required = value => !value ? 'required' : undefined

class ProjectLanguagesForm extends Component {
    onChangeDefaultLang = (i, fields) => {
        const languages = fields.value.map((lang, index) => ({
            ...lang,
            defaultLang: index === i
        }));

        this.props.form.change('languages', languages);
    }

    renderLanguagesFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <LanguageItem key={name}>
                    <Field
                        name={`${name}.defaultLang`}
                        component={Radio}
                        onChange={() => this.onChangeDefaultLang(index, fields)} />
                    <Field
                        name={`${name}.id`}
                        component={Input}
                        hideErrorMsg
                        validate={required} />
                    <span style={{ marginRight: 10 }}>:</span>
                    <Field
                        name={`${name}.lang`}
                        component={Input}
                        hideErrorMsg
                        validate={required} />
                    <Button danger icon={<DeleteOutlined />} onClick={() => {
                        if (fields.value[index].defaultLang && fields.length > 1) {
                            this.onChangeDefaultLang(0, fields);
                        }

                        fields.remove(index);
                    }} />
                </LanguageItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push({ defaultLang: !fields.length })}>Добавить язык</Button>
        </div>;
    }

    render() {
        return <Fragment>
            <Form.Item label='Языки' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='languages' render={this.renderLanguagesFields} />
            </Form.Item>
            <ListenerField listenFieldName='languages'>
                { ({ languages }) => !!(languages || []).length &&
                    <Field
                        name='settings.theme.chooseLanguageFirst'
                        component={Switch}
                        label='Выбор языка первым экраном' />
                }
            </ListenerField>
            <Field name='defaultLanguage' component={() => null} />
            <SubmitButton>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(ProjectLanguagesForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        languages: toPairs(item.languages).map(([id, lang]) => ({ id, lang, defaultLang: item.defaultLanguage === id }))
    }),
    validationSchema,
    mapBeforeSubmit: (values, props) => {
        const defaultLanguage = path(['id'], find(({ defaultLang }) => defaultLang, values.languages));
        const data = {
            ...values,
            languages: fromPairs(values.languages.map(({ id, lang }) => ([id, lang]))),
            defaultLanguage: path(['id'], find(({ defaultLang }) => defaultLang, values.languages)),
            translations: {
                ...(values.translations || {}),
                settings: {
                    ...pathOr({}, ['translations', 'settings'], values),
                    defaultLanguage
                }
            }
        };

        return parseToPatch(data, props.item, [
            'languages',
            'defaultLanguage',
            path(['settings', 'theme'], props.item) ? 'settings.theme.chooseLanguageFirst' : 'settings.theme',
            path(['translations', 'settings'], props.item) ? 'translations.settings.defaultLanguage' : 'translations.settings'
        ]);
    }
});
