import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { postRespondent, putRespondent } from '../../actions/asyncActions';
import { message } from 'antd';
import RespondentForm from '../forms/RespondentForm';

class RespondentModal extends Component {
    render() {
        const { modal, params: { groups, item, languages }, putRespondent, postRespondent } = this.props;

        return <Modal
            {...modal}
            title={item.id ? 'Редактировать респондента' : 'Добавить респондента'}
            footer={null}>
            <RespondentForm
                item={item}
                groups={groups}
                languages={languages}
                formAction={item.id ? putRespondent : postRespondent} />
        </Modal>;
    }
}

export default withAsyncActions({
    postRespondent: postRespondent
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить респондента'))
        .withOptions({ resetOnUnmount: true }),
    putRespondent: putRespondent
        .withSuccessHandler(({ close }) => {
            message.success('Респондент успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить респондента'))
        .withOptions({ resetOnUnmount: true })
})(RespondentModal);
