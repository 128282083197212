import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Tabs, Button } from 'antd';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';
import { EditOutlined } from '@ant-design/icons';

import { getCompany } from '../../../actions/asyncActions';
import DetailToolbar from '../DetailToolbar';
import CompanyInfo from './CompanyInfo';
import CompanyRespondents from './CompanyRespondents';
import { openCompanyModal } from '../../../actions/modalActions';
import { PUT_COMPANY } from '../../../constants/actionTypes';
import CenterSpin from '../../CenterSpin';
import Route from '../../Route';

const Wrapper = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-tabs-bar {
        margin: 0;
    }
`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const TABS = [
    { name: 'Информация', key: 'info', component: CompanyInfo },
    { name: 'Респонденты', key: 'respondents', component: CompanyRespondents }
];

class Company extends Component {
    onChangeTab = key => {
        const { history, match: { params: { id }}} = this.props;

        history.replace(`/companies/${id}/${key}`);
    }

    render() {
        const { getCompany: { data, meta }, match: { params: { id, type }}, openCompanyModal } = this.props;

        return <Fragment>
            <DetailToolbar title={
                <ToolbarTitle>
                    <h1>{ data.name }</h1>
                    { type === 'info' &&
                        <Button type='primary' icon={<EditOutlined />} disabled={meta.pending} onClick={() => openCompanyModal(data)}>
                            Редактировать
                        </Button>
                    }
                </ToolbarTitle>
            } />
            { meta.pending && !meta.lastSucceedAt ?
                <CenterSpin /> :
                meta.lastSucceedAt ?
                    <Wrapper>
                        <Tabs onChange={this.onChangeTab} animated={false} activeKey={type}>
                            { TABS.map(tab =>
                                <Tabs.TabPane key={tab.key} tab={tab.name}>
                                    <Route path={`/companies/${id}/${tab.key}`} render={props =>
                                        <tab.component {...props} company={data} />
                                    } />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Wrapper> :
                    null
            }
        </Fragment>
    }
}

export default asyncConnect({
    getCompany: getCompany
        .withPayload(({ match: { params: { id }}}) => id)
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_COMPANY)], () => {
                getProps().getCompany.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, null, { openCompanyModal })(Company);
