import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import ProjectPdfSettingsForm from '../forms/ProjectPdfSettingsForm';
import { patchProject } from '../../actions/asyncActions';

class ProjectPdfSettingsModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Настройки отчета'
            footer={null}>
            <ProjectPdfSettingsForm
                formAction={patchProject}
                item={params.project} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withParams(() => ({ type: 'settings' }))
        .withSuccessHandler(({ close }) => {
            message.success('Настройки отчета были успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить настройки отчета'))
        .withOptions({ resetOnUnmount: true })
})(ProjectPdfSettingsModal);
