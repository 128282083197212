import React, { Component, Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button, List, Popconfirm, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { omit, pathOr } from 'ramda';
import uniqid from 'uniqid';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { openProjectPdfTemplateModal } from '../../actions/modalActions';
import { parseToPatch } from '../../utils/forms';

class ProjectPdfTemplatesForm extends Component {
    deleteTemplate = (fields, index) => {
        const item = fields.value[index];

        item.id && this.props.form.change(
            'report.data',
            pathOr([], ['values', 'report', 'data'], this.props.form.getState()).map(i => item.template === i.id ? omit(['template'], i) : i)
        );
        fields.remove(index);
    }

    renderTemplates = ({ fields }) => {
        const { openProjectPdfTemplateModal, filters, statuses, drivers } = this.props;

        return <div>
            <div style={{ marginBottom: 5, textAlign: 'right' }}>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => openProjectPdfTemplateModal({
                        onSubmit: item => fields.push(item),
                        filters,
                        statuses,
                        drivers,
                        project: this.props.item
                    })}>
                    Добавить
                </Button>
            </div>
            <List
                style={{ marginBottom: 10 }}
                dataSource={fields.value}
                locale={{
                    emptyText: 'Нет добавленных шаблонов'
                }}
                renderItem={(item, index) =>
                    <List.Item
                        actions={[
                            <Button.Group>
                                <Tooltip title='Копировать шаблон'>
                                    <Button
                                        icon={<CopyOutlined />}
                                        onClick={() => openProjectPdfTemplateModal({
                                            onSubmit: item => fields.push({ ...item, id: uniqid() }),
                                            filters,
                                            statuses,
                                            drivers,
                                            project: this.props.item,
                                            item
                                        })} />
                                </Tooltip>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => openProjectPdfTemplateModal({
                                        item,
                                        project: this.props.item,
                                        filters,
                                        statuses,
                                        drivers,
                                        onSubmit: item => fields.update(index, item, item)
                                    })} />
                                <Popconfirm
                                    onConfirm={() => this.deleteTemplate(fields, index)}
                                    title='Вы уверены, что хотите удалить шаблон?'
                                    okText='Да'
                                    cancelText='Нет'>
                                    <Button danger icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </Button.Group>
                        ]}>
                        { item.name }
                    </List.Item>
                } />
        </div>;
    }

    render() {
        return <Fragment>
            <FieldArray name='report.templates'>
                { this.renderTemplates }
            </FieldArray>
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default connect(null, { openProjectPdfTemplateModal })(
    withFormWrapper(ProjectPdfTemplatesForm, {
        mapPropsToValues: ({ item }) => item,
        mapBeforeSubmit: (values, props) =>
            parseToPatch(values, props.item, ['report'])
    })
);
