import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { withRouter, matchPath, Link } from 'react-router-dom';
import { find } from 'ramda';

import MENU_ITEMS from '../../../constants/menuItems';

class Sidebar extends Component {
    state = {
        collapsed: false
    };

    onCollapse = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    getSelectedKey() {
        const { pathname } = this.props.location;

        const selected = find(item => {
            return (item.key === '/' && item.key === pathname) ||
                matchPath(pathname, {
                    path: item.route || item.key
                });
        }, MENU_ITEMS);

        return selected ? [selected.key] : null;
    }

    render() {
        return <Layout.Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            theme='dark'>
            <Menu
                selectable={false}
                selectedKeys={this.getSelectedKey()}
                mode='inline'
                theme='dark'>
                { MENU_ITEMS.map(item =>
                    <Menu.Item key={item.key}>
                        <Link to={item.key}>{ item.icon }<span>{ item.title }</span></Link>
                    </Menu.Item>
                )}
            </Menu>
        </Layout.Sider>
    }
}

export default withRouter(Sidebar);
