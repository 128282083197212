import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Button } from 'antd';
import { filter, prop } from 'ramda';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { ColorField } from './PdfChartForm';
import ColorPicker, { ColorPickerField } from './formComponents/ColorPicker';
import { PDF_FONTS } from '../../constants/charts';
import Select from './formComponents/Select';
import ImageUpload from './formComponents/ImageUpload';
import NumberInput from './formComponents/NumberInput';
import { parseToPatch } from '../../utils/forms';

class ProjectPdfSettingsForm extends Component {
    renderColorFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <ColorField key={`color-${index}`}>
                    <Field
                        name={name}
                        component={ColorPicker} />
                    <Button icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </ColorField>
            )}
            <div>
                <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>Добавить цвет</Button>
            </div>
        </div>;
    }

    render() {
        return <Fragment>
            <Field
                name='report.font'
                component={Select}
                label='Шрифт'
                options={PDF_FONTS} />
            <Field
                name='report.logo'
                component={ImageUpload}
                label='Логотип' />
            <Form.Item label='Палитра' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='report.colors'>
                    { this.renderColorFields }
                </FieldArray>
            </Form.Item>
            <h3>Заголовок страницы</h3>
            <Field
                name='report.pageTitleColor'
                component={ColorPickerField}
                label='Цвет заголовка страницы' />
            <h3>Заголовок графика</h3>
            <Field
                name='report.titleColor'
                component={ColorPickerField}
                label='Цвет заголовка' />
            <Field
                name='report.titleSize'
                component={NumberInput}
                label='Размер шрифта заголовка' />
            <h3>Декоративная полоска</h3>
            <Field
                name='report.stripColor'
                component={ColorPickerField}
                label='Цвет полоски' />
            <Field
                name='report.stripSize'
                component={NumberInput}
                label='Толщина полоски' />
            <h3>Таблицы</h3>
            <Field
                name='report.tableHeaderColor'
                component={ColorPickerField}
                label='Цвет фона шапки' />
            <Field
                name='report.tableTitlesColor'
                component={ColorPickerField}
                label='Цвет заголовка таблицы' />
            <Field
                name='report.tableEvenColor'
                component={ColorPickerField}
                label='Цвет фона четных строк' />
            <Field
                name='report.tableOddColor'
                component={ColorPickerField}
                label='Цвет фона нечетных строк' />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(ProjectPdfSettingsForm, {
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: (values, props) => {
        const colors = filter(color => !!color, values.report.colors || []);

        return parseToPatch({
            ...values,
            report: {
                ...values.report,
                colors: colors.length ? colors : null
            }
        }, props.item, [
            'report.font',
            'report.logo',
            'report.colors',
            'report.pageTitleColor',
            'report.titleColor',
            'report.titleSize',
            'report.stripColor',
            'report.stripSize',
            'report.tableHeaderColor',
            'report.tableTitlesColor',
            'report.tableEvenColor',
            'report.tableOddColor'
        ]);
    }
});
