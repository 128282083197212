import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchProject } from '../../actions/asyncActions';
import ProjectPdfForm from '../forms/ProjectPdfForm';

class ProjectPdfModal extends Component {
    render() {
        const { modal, params: { project, add, index, filters, statuses, segment, drivers, copy, segmentIndexes }, close, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Страница отчета'
            footer={null}>
            <ProjectPdfForm
                project={project}
                filters={filters}
                statuses={statuses}
                onCancel={close}
                formAction={patchProject}
                index={index}
                drivers={drivers}
                add={add}
                copy={copy}
                segment={segment}
                segmentIndexes={segmentIndexes} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(({ close }) => {
            message.success('Страница отчета успешно сохранена');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить страницу отчета'))
        .withOptions({ resetOnUnmount: true })
})(ProjectPdfModal);
