import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, message } from 'antd';
import { withState } from 'recompose';
import { equals } from 'ramda';
import { QuestionCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getQuestionnaires, deleteQuestionnaires } from '../../../actions/asyncActions';
import QuestionnairesFilter from '../../forms/filters/QuestionnairesFilter';
import { getUrlParams } from '../../../utils/urlParams';
import { DELETE_QUESTIONNAIRES, POST_QUESTIONNAIRE } from '../../../constants/actionTypes';
import { openQuestionnaireModal } from '../../../actions/modalActions';

class Questionnaires extends Component {
    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.clearSelected();
        }
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (name, { id }) => <Link to={`/questionnaires/${id}/info`}>{ name }</Link>
            },
            {
                title: 'Кол-во вопросов',
                dataIndex: 'drivers',
                key: 'amount',
                render: drivers => {
                    const questionsAmount = (drivers || []).reduce((res, cur) => res + (cur.questions || []).length, 0);

                    return <span><QuestionCircleOutlined /> { questionsAmount }</span>;
                }
            }
        ];
    }

    deleteSelected = () => {
        this.props.deleteQuestionnaires.dispatch(this.props.selected);
    }

    renderButtons = () => {
        const { selected, openQuestionnaireModal } = this.props;

        return <Button.Group>
            <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => openQuestionnaireModal()}>
                Добавить
            </Button>
            { selected.length ?
                <Popconfirm
                    title='Вы уверены, что хотите удалить выбранные опросники?'
                    placement='left'
                    okText='Да'
                    cancelText='Нет'
                    onConfirm={this.deleteSelected}>
                    <Button
                        icon={<DeleteOutlined />}
                        danger>
                        Удалить
                    </Button>
                </Popconfirm> :
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    disabled>
                    Удалить
                </Button>
            }
        </Button.Group>
    }

    setSelected = selected => this.props.setSelected(selected);

    clearSelected = () => this.setSelected([]);

    render() {
        return <TableList
            action={getQuestionnaires}
            columns={this.getColumns()}
            filterForm={QuestionnairesFilter}
            buttons={this.renderButtons()}
            refreshActions={[DELETE_QUESTIONNAIRES, POST_QUESTIONNAIRE]}
            rowSelection={{
                selectedRowKeys: this.props.selected,
                onChange: this.setSelected,
                type: 'checkbox'
            }} />;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteQuestionnaires: deleteQuestionnaires
            .withSuccessHandler(({ setSelected }) => {
                message.success('Выбранные опросники успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить выбранные опросники'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openQuestionnaireModal })(Questionnaires)
);
