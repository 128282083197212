import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { keys, path, uniq, unnest } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import { parseToPatch } from '../../utils/forms';

class ProjectPageElementsForm extends Component {
    render() {
        return <div>
            <Field
                name='translations.pageElements.startButton'
                component={Input}
                label='Кнопка "Начать"'
                translationName='startButton'
                translations />
            <Field
                name='translations.pageElements.continueButton'
                component={Input}
                label='Кнопка "Продолжить"'
                translationName='continueButton'
                translations />
            <Field
                name='translations.pageElements.submitButton'
                component={Input}
                label='Кнопка "Отправить"'
                translationName='submitButton'
                translations />
            <Field
                name='translations.pageElements.finishTitle'
                component={Input}
                label='Заголовок на завершение'
                translationName='finishTitle'
                translations />
            <Field
                name='translations.pageElements.finishText'
                component={Input}
                label='Текст на завершение'
                translationName='finishText'
                translations />
            <Field
                name='translations.pageElements.chooseText'
                component={Input}
                label='Текст выбора'
                translationName='chooseText'
                translations />
            <Field
                name='translations.pageElements.chooseLanguage'
                component={Input}
                label='Текст выбора языка'
                translationName='chooseLanguage'
                translations />
            <Field
                name='translations.pageElements.placeholder'
                component={Input}
                label='Плейсхолдер текстовых полей'
                translationName='placeholder'
                translations />
            <Field
                name='translations.pageElements.commentButton'
                component={Input}
                label='Кнопка "Комментарий"'
                translationName='commentButton'
                translations />
            <Field
                name='translations.pageElements.knowNothing'
                component={Input}
                label='Чекбокс "Не знаю"'
                translationName='knowNothing'
                translations />
            <SubmitButton>Сохранить</SubmitButton>
        </div>;
    }
}

export default withFormWrapper(ProjectPageElementsForm, {
    mapPropsToValues: ({ item }) => {
        const pageElements = item.translations.pageElements || {};

        return {
            ...item,
            translations: {
                ...item.translations,
                pageElements: {
                    startButton: pageElements.startButton || 'Начать опрос',
                    continueButton: pageElements.continueButton || 'Продолжить',
                    submitButton: pageElements.submitButton || 'Ответить',
                    finishTitle: pageElements.finishTitle || 'Спасибо за участие!',
                    finishText: pageElements.finishText || 'Спасибо за участие!',
                    chooseText: pageElements.chooseText || 'Ниже представлен ряд утверждений, которые Вам предлагается оценить. Выберите насколько Вы согласны со следующими утверждениями',
                    chooseLanguage: pageElements.chooseLanguage || 'Выберите язык',
                    placeholder: pageElements.placeholder || 'Ответ',
                    commentButton: pageElements.commentButton || 'Комментарий',
                    knowNothing: pageElements.knowNothing || 'Не знаю'
                }
            }
        };
    },
    mapBeforeSubmit: (values, props) =>
        parseToPatch(values, props.item, uniq(unnest([
                'startButton',
                'continueButton',
                'submitButton',
                'finishTitle',
                'finishText',
                'chooseText',
                'chooseLanguage',
                'placeholder',
                'commentButton',
                'knowNothing',
            ].map(field => keys(values.languages).map(lang =>
                path(['translations', lang], props.item) ? `translations.${lang}.${field}` : `translations.${lang}`
            )))
        ))
});
