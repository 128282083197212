import React , { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchProject } from '../../actions/asyncActions';
import GroupVariablesForm from '../forms/GroupVariablesForm';

class GroupVariablesModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Редактировать переменные'
            footer={null}>
            <GroupVariablesForm
                item={params}
                formAction={patchProject} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(({ close }) => {
            message.success('Переменные проекта успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить переменные проекта'))
        .withOptions({ resetOnUnmount: true })
})(GroupVariablesModal);
