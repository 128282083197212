import React, { Component } from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { LogoutOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';

import Sidebar from './Sidebar';
import { logout } from '../../../actions/appActions';
import { getUser } from '../../../actions/asyncActions';
import UserRoutes from './UserRoutes';
import Modals from '../../modals/Modals';
import Search from './Search';

const Header = styled(Layout.Header)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProfileBtn = styled.span`
    color: hsla(0,0%,100%,.65);
    cursor: pointer;
    .anticon {
        margin-right: 8px;
    }
    &:hover {
        color: #fff;
    }
`;

const Wrapper = styled.div`
    margin: 20px;
`;

class UserLayout extends Component {
    render() {
        const { user, logout } = this.props;

        return <Layout>
            <Header>
                <Search />
                <Dropdown trigger={['click']} overlay={
                    <Menu>
                        <Menu.Item onClick={logout}>
                            <LogoutOutlined /> Выйти
                        </Menu.Item>
                    </Menu>
                }>
                    <ProfileBtn>
                        <UserOutlined />{ user.lastName } { user.firstName } <DownOutlined />
                    </ProfileBtn>
                </Dropdown>
            </Header>
            <Layout>
                <Sidebar />
                <Layout.Content>
                    <Wrapper>
                        <UserRoutes />
                    </Wrapper>
                </Layout.Content>
            </Layout>
            <Modals />
        </Layout>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { logout })(UserLayout);
