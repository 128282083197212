import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { postReport, putReport } from '../../actions/asyncActions';
import { message } from 'antd';
import ReportForm from '../forms/ReportForm';

class ReportModal extends Component {
    render() {
        const { modal, params, putReport, postReport } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать отчет' : 'Добавить отчет'}
            footer={null}>
            <ReportForm
                formAction={params.id ? putReport : postReport}
                data={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postReport: postReport
        .withSuccessHandler(({ close }) => {
            message.success('Отчет успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить отчет'))
        .withOptions({ resetOnUnmount: true }),
    putReport: putReport
        .withSuccessHandler(({ close }) => {
            message.success('Отчет успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить отчет'))
        .withOptions({ resetOnUnmount: true })
})(ReportModal);
