import React, { Component } from 'react';
import { message, Button, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { withState } from 'recompose';
import { remove, find, propEq, path, keys, pathOr } from 'ramda';
import uniqid from 'uniqid';

import { patchProject, getProject } from '../../../../actions/asyncActions';
import { openProjectResponseFieldFormModal } from '../../../../actions/modalActions';
import { reorder } from '../../../../utils/dnd';
import { PROJECT_RESPONSE_FORM_FIELD_TYPES } from '../../../../constants/project';
import { MenuOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const FieldWrapper = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: flex;
    background: #fff;
`;

const Handler = styled.div`
    padding: 15px;
    cursor: move;
    display: flex;
    align-items: center;
`;

const FieldContent = styled.div`
    padding: 10px 15px 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

class ProjectForm extends Component {
    onDragEnd = result => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const sections = reorder(
            this.props.sections,
            result.source.index,
            result.destination.index
        );

        this.props.setSections(sections);
        this.props.patchProject.dispatch({
            id: this.props.project.id,
            data: [
                {
                    op: 'replace',
                    path: `/sections`,
                    value: sections
                },
                ...keys(this.props.project.languages).map(lang => ({
                    op: 'replace',
                    path: `/translations/${lang}/sections`,
                    value: reorder(
                        pathOr([], ['translations', lang, 'sections'], this.props.project),
                        result.source.index,
                        result.destination.index
                    )
                }))
            ]
        });
    }

    removeField = index => {
        this.props.setSections(remove(index, 1, this.props.sections));
        this.props.patchProject.dispatch({
            id: this.props.project.id,
            data: [{
                op: 'remove',
                path: `/sections/${index}`
            },
            ...keys(this.props.project.languages).map(lang => ({
                op: 'remove',
                path: `/translations/${lang}/sections/${index}`
            }))]
        });
    }

    render() {
        const { sections, project, openProjectResponseFieldFormModal } = this.props;

        return <div style={{ padding: 15 }}>
            <div style={{ textAlign: 'right', marginBottom: 15 }}>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openProjectResponseFieldFormModal({
                    add: true,
                    sectionsAmount: sections.length,
                    project: project.id,
                    translations: project.translations,
                    values: {
                        key: uniqid()
                    },
                    languages: keys(project.languages)
                })}>
                    Добавить поле
                </Button>
            </div>
            { sections.length ?
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId='droppable'>
                        { provided =>
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                { sections.map((item, index) =>
                                    <Draggable key={`item-${item.key}`} draggableId={item.key} index={index}>
                                        { provided =>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{ ...provided.draggableProps.style, paddingBottom: 15 }}>
                                                <FieldWrapper>
                                                    <Handler {...provided.dragHandleProps}>
                                                        <MenuOutlined />
                                                    </Handler>
                                                    <FieldContent>
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                            <div style={{ color: '#999' }}>{ path(['value'], find(propEq('id', item.type), PROJECT_RESPONSE_FORM_FIELD_TYPES)) }</div>
                                                        </div>
                                                        <Button.Group>
                                                            <Button
                                                                icon={<EditOutlined />}
                                                                onClick={() => openProjectResponseFieldFormModal({
                                                                    index,
                                                                    project: project.id,
                                                                    values: item,
                                                                    translations: project.translations,
                                                                    languages: keys(project.languages)
                                                                })} />
                                                            <Popconfirm
                                                                title='Вы уверены, что хотите удалить поле?'
                                                                okText='Да'
                                                                cancelText='Нет'
                                                                onConfirm={() => this.removeField(index)}
                                                                placement='left'>
                                                                <Button type='danger' icon={<DeleteOutlined />} />
                                                            </Popconfirm>
                                                        </Button.Group>
                                                    </FieldContent>
                                                </FieldWrapper>
                                            </div>
                                        }
                                    </Draggable>
                                )}
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                </DragDropContext> :
                <div style={{ textAlign: 'center' }}>Нет добавленных полей</div>
            }
        </div>
    }
}

export default withState('sections', 'setSections', props => props.project.sections || [])(
    asyncConnect({
        getProject: getProject
            .withSuccessHandler(({ getProject, setSections }) => setSections(getProject.data.sections)),
        patchProject: patchProject
            .withParams(() => ({ type: 'list' }))
            .withErrorHandler(({ project, setSections }) => {
                setSections(project.sections);
                message.error('Не удалось изменить форму');
            })
            .withOptions({ resetOnUnmount: true })
    }, null, { openProjectResponseFieldFormModal })(ProjectForm)
);
