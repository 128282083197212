import React, { Component, Fragment } from 'react';
import { Card, Row, Col } from 'antd';
import { Field, FormSpy } from 'react-final-form';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { update, path, find, propEq, pathOr, contains, toPairs, flatten } from 'ramda';
import * as yup from 'yup';
import { BarChartOutlined, PieChartOutlined, TableOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import withFormWrapper from '../hocs/withFormWrapper';
import { PDF_GENERATOR_TYPES, REPORT_TYPES, PDF_FONTS, PDF_GENERATOR_SEGMENT_TYPES } from '../../constants/charts';
import ListenerField from './ListenerField';
import FileUpload from './formComponents/FileUpload';
import { openPdfChartModal } from '../../actions/modalActions';
import Input from './formComponents/Input';
import FooterVariablesField from './formComponents/FooterVariablesField';
import RangeField from './formComponents/RangeField';
import { ColorPickerField } from './formComponents/ColorPicker';
import ImageUpload from './formComponents/ImageUpload';
import PdfGeneratorPageType from './formComponents/PdfGeneratorPageType';
import Switch from './formComponents/Switch';

export const ChartWrapper = styled.div`
    cursor: pointer;
    border: 1px dashed #ccc;
    padding: 30px 15px 0;
    color: #ccc;
    text-align: center;
    font-size: 100px;
    .anticon-bar-chart {
        ${({ reverseIcon }) => reverseIcon && css`
            transform: rotate(90deg) scale(-1, 1) ;
        `}
    }
`;

export const ChartTitle = styled.div`
    background: #ccc;
    height: 18px;
    width: 50%;
    margin: auto;
`;

class ProjectPdfForm extends Component {
    onChangeChart = (values, index) => {
        const { form } = this.props;

        form.change('charts', update(index, values, form.getState().values.charts || []));
    }

    renderChartBlock = (index = 0) => {
        const { openPdfChartModal, project, submitFailed, drivers } = this.props;

        return <FormSpy subscription={{ values: true, errors: true }}>
                { ({ values: { charts, type }, errors }) => {
                    const chart = pathOr({}, [index], charts);
                    const title = chart.type === 'filters' ? chart.property : path(['value'], find(propEq('id', chart.type), REPORT_TYPES));
                    const error = path(['charts', index, 'type'], errors);

                    return <div>
                        <ChartWrapper
                            reverseIcon={contains('distribution', chart.type || '')}
                            onClick={() => openPdfChartModal({
                                project: project.id,
                                chart,
                                type,
                                onSubmit: values => this.onChangeChart(values, index),
                                segment: this.props.segment,
                                drivers
                            })}>
                            { title ? <div style={{ fontSize: 18 }}><strong>{ title }</strong></div> : <ChartTitle /> }
                            { contains(chart.type, ['dynamic', 'questions-distribution-table']) ? <TableOutlined /> : chart.type === 'filters' ? <PieChartOutlined /> : <BarChartOutlined /> }
                        </ChartWrapper>
                        { submitFailed && error && <div style={{ color: 'red', marginTop: 10 }}>{ error }</div> }
                    </div>;
                }}
            </FormSpy>;
    }

    renderCommonFields = () => {
        return <Fragment>
            { !this.props.segment &&
                <Fragment>
                    <Field
                        name='title'
                        component={Input}
                        label='Заголовок страницы' />
                     <Field
                        name='pageTitleColor'
                        component={ColorPickerField}
                        label='Цвет заголовка страницы' />
                </Fragment>
            }
            <Field
                name='font'
                component={Select}
                label='Шрифт'
                options={PDF_FONTS} />
            <ListenerField listenFieldName='footerImage'>
                { ({ footerImage }) => !footerImage &&
                    <FooterVariablesField
                        {...this.props} />
                }
            </ListenerField>
            <Field
                name='footerImage'
                component={ImageUpload}
                label='Изображение в футере' />
        </Fragment>;
    }

    onChangeProperty = () => {
        this.props.form.change('value', null);
        this.props.form.change('propertyReportView', null);
    }

    renderFields = ({ type }) => {
        switch(type) {
            case 'file':
                return <Field
                    name='file'
                    component={FileUpload}
                    label='PDF-файл'
                    accept='.pdf' />;
            case 'one':
                return <Fragment>
                    { this.renderCommonFields() }
                    <Card style={{ marginBottom: 15 }}>
                        { this.renderChartBlock() }
                    </Card>
                </Fragment>;
            case 'two':
                return <Fragment>
                    { this.renderCommonFields() }
                    <Card style={{ marginBottom: 15 }}>
                        <Row gutter={15}>
                            <Col span={12}>
                                { this.renderChartBlock() }
                            </Col>
                            <Col span={12}>
                                { this.renderChartBlock(1) }
                            </Col>
                        </Row>
                    </Card>
                </Fragment>;
            case 'three':
                return <Fragment>
                    { this.renderCommonFields() }
                    <Card style={{ marginBottom: 15 }}>
                        <Row gutter={15}>
                            <Col span={8}>
                                { this.renderChartBlock() }
                            </Col>
                            <Col span={8}>
                                { this.renderChartBlock(1) }
                            </Col>
                            <Col span={8}>
                                { this.renderChartBlock(2) }
                            </Col>
                        </Row>
                    </Card>
                </Fragment>;
            case 'questions-distribution-driver':
                return this.renderCommonFields();
            case 'segment':
                return <Fragment>
                    <Field
                        name='property'
                        component={Select}
                        label='Сегмент'
                        options={toPairs(this.props.filters).map(([ value ]) => ({ id: value, value }))}
                        onChange={this.onChangeProperty}
                        allowClear={type !== 'filters'} />
                    <ListenerField listenFieldName={'property'}>
                        { ({ property }) => property &&
                            <Fragment>
                                <Field
                                    name='template'
                                    component={Select}
                                    label='Шаблон'
                                    options={pathOr([], ['report', 'templates'], this.props.project)}
                                    namePath='name'
                                    allowClear />
                                <Field
                                    name='propertyReportView'
                                    component={Input}
                                    placeholder={`По умолчанию: ${property}`}
                                    label='Сегмент в отчете' />
                                <Field
                                    name='value'
                                    component={Select}
                                    label='Значение'
                                    options={this.props.filters[property]}
                                    namePath='_id'
                                    valuePath='_id'
                                    isMulti
                                    allowClear />
                                <ListenerField listenFieldName='value'>
                                    { ({ value }) => value &&
                                        <Field
                                            name='value'
                                            component={RangeField}
                                            label='Последовательность' />
                                    }
                                </ListenerField>
                            </Fragment>
                        }
                    </ListenerField>
                </Fragment>
            default:
                return null;
        }
    }

    onChangeType = type => {
        this.props.form.change('charts', type === 'one' ? [{}] : type === 'two' ? [{}, {}] : type === 'three' ? [{}, {}, {}] : null);
    }

    render() {
        return <Fragment>
            <Field
                name='type'
                component={PdfGeneratorPageType}
                options={this.props.segment ? PDF_GENERATOR_SEGMENT_TYPES : PDF_GENERATOR_TYPES}
                onChange={this.onChangeType} />
            <ListenerField listenFieldName='type'>
                { this.renderFields }
            </ListenerField>
            <Field
                name='hide'
                component={Switch}
                label='Скрыть страницу из выгрузки' />
            <Field name='charts' component={() => null} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    type: yup.string().required(),
    file: yup.string().when('type', (type, schema) => type === 'file' ? schema.required() : schema),
    charts: yup.array().when('type', (type, schema) => type === 'file' ? schema : schema.of(yup.object().shape({
        type: yup.string().required('Необходимо указать тип отчета')
    }))).nullable(),
    property: yup.string().nullable().when('type', (type, schema) => type === 'segment' ? schema.required() : schema),
    value: yup.array().nullable().when('type', (type, schema) => type === 'segment' ? schema.min(1).required() : schema)
});

export const ProjectPdfTemplatePageForm = withFormWrapper(
    connect(null, { openPdfChartModal })(ProjectPdfForm),
    {
        mapPropsToValues: ({ item }) => item,
        validationSchema
    }
);

export default withFormWrapper(
    connect(null, { openPdfChartModal })(ProjectPdfForm),
    {
        mapPropsToValues: ({ project, add, index, segment, segmentIndexes }) => add ? {} :
            (segment ? path([...flatten(segmentIndexes.map(i => (['data', i]))), 'data', index], project.report) : project.report.data[index]),
        mapBeforeSubmit: (report, { project, add, copy, index, segment, segmentIndexes }) => {
            const segmentsPath = flatten((segmentIndexes || []).map(i => ([i, 'data']))).reduce((res, cur) => `${res}/${cur}`, '');
            const value = report.type === 'segment' && !report.data ? { ...report, data: [] } : report;

            return {
                id: project.id,
                data: (add || copy) ? [{
                    op: 'add',
                    path: segment ? `/report/data${segmentsPath}/-` : '/report/data/-',
                    value
                }] : [{
                    op: 'replace',
                    path: segment ? `/report/data${segmentsPath}/${index}` : `/report/data/${index}`,
                    value
                }]
            };
        },
        validationSchema
    }
);
