import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { prop } from 'ramda';

import Input from './formComponents/Input';
import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';

class RespondentForm extends Component {
    render() {
        const { groups, languages } = this.props;

        return <Fragment>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия' />
            <Field
                name='firstName'
                component={Input}
                label='Имя' />
            <Field
                name='middleName'
                component={Input}
                label='Отчество' />
            <Field
                name='email'
                component={Input}
                label='Email'
                htmlType='email' />
            <Field
                name='group'
                component={Select}
                label='Группа'
                options={groups}
                allowClear />
            <Field
                name='language'
                component={Select}
                label='Язык прохождения'
                options={languages}
                allowClear />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required()
});

export default withFormWrapper(RespondentForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
