import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import * as yup from 'yup';

import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';

class CompanyForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(CompanyForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
