import { propOr, path, filter, isNil } from 'ramda';
import qs from 'qs';

import { LINES_PER_PAGE } from '../constants/table';

export const getUrlParams = location => {
    return qs.parse(propOr('', 'search', location), { ignoreQueryPrefix: true, strictNullHandling: true });
};

export const getFilters = (location, prefix) => {
    const params = getUrlParams(location);

    try {
        return JSON.parse(prefix ? path([prefix, 'filter'], params) : params.filter);
    } catch {
        return null;
    }
};

export const getUrlPagination = (location, prefix) => {
    const params = getUrlParams(location);
    const { offset = 0, limit = LINES_PER_PAGE } = prefix ? propOr({}, prefix, params) : params;

    return ({
        offset: Number(offset),
        limit: Number(limit),
    });
};

export const getSorting = location => {
    const { sorting } = getUrlParams(location);

    return sorting || {};
}

export const extendSearchPath = (location, searchPath = {}) => {
    const current = qs.parse(location.search, { ignoreQueryPrefix: true });
    const query = filter(v => !isNil(v), { ...current, ...searchPath });

    return `${location.pathname}${qs.stringify(query, { addQueryPrefix: true, strictNullHandling: true })}`;
}
