import React, { Component } from 'react';
import { Upload, Button } from 'antd';
import styled from 'styled-components';
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';

export const PreviewWrapper = styled.div`
    .ant-btn {
        margin-left: 10px
    }
`;

export const ImageWrapper = styled.div`
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    border: 1px solid #e8e8e8;
    img {
        border-radius: 4px;
    }
`;

class ImageUpload extends Component {
    reader = new FileReader();

    beforeUpload = file => {
        this.reader.readAsDataURL(file);
        this.reader.onload = () => this.props.onChange(this.reader.result);

        return false;
    }

    render() {
        const { input: { value, name }, onChange } = this.props;

        return value ?
            <PreviewWrapper>
                <ImageWrapper>
                    <img src={value} width={200} alt={`${name}-preview`} />
                </ImageWrapper>
                <Upload
                    beforeUpload={this.beforeUpload}
                    showUploadList={false}
                    accept='image/*'>
                    <Button
                        icon={<EditOutlined />}
                        shape='circle' />
                </Upload>
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    shape='circle'
                    onClick={() => onChange(null)} />
            </PreviewWrapper> :
            <Upload
                beforeUpload={this.beforeUpload}
                showUploadList={false}
                accept='image/*'>
                <Button>
                    <UploadOutlined /> Загрузить изображение
                </Button>
            </Upload>;
    }
}

export default withFieldWrapper(ImageUpload);
