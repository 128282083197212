import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import HighchartsReact from 'highcharts-react-official';
import { toPairs, flatten, find, propEq, path, pathOr, fromPairs, is } from 'ramda';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { getReportDriverTotal, getDriverImportance } from '../../../../actions/asyncActions';
import withReportFilters from '../../../hocs/withReportFilters';
import { GroupedCategoriesHighcharts, getSortedData } from '../../../../utils/charts';
import CenterSpin from '../../../CenterSpin';
import { Sorter } from './ReportBlockTotal';
import { DRIVER_TOTAL_COLORS, TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';
import withReportWrapper from '../../../hocs/withReportWrapper';
import withChartPngDownload from '../../../hocs/withChartPngDownload';

const SortBtn = styled(Sorter)`
    width: 360px;
    left: calc(50% - 180px);
`;

const Wrapper = styled.div`
    position: relative;
`;

export class ReportDriverTotalChartComponent extends Component {
    static defaultProps = {
        grouped: true
    };

    constructor(props) {
        super(props);

        this.state = {
            showCommon: !!props.showCommon,
            commonColumn: props.commonColumn,
            sort: null,
            grouped: props.grouped
        }
    }

    getColor = (id, value) => {
        const isTop5 = find(propEq('_id', id), this.props.importance || this.props.getDriverImportance.data || []);

        return this.props.extended ? (isTop5 ? (value > 50 ? '#CF020D' : '#3B75BB') : '#7F7F7F') : path(['color'], isTop5 ?
            find(({ kmp, top5 }) => top5 && (kmp ? kmp(value) : true), DRIVER_TOTAL_COLORS) :
            find(propEq('top5', false), DRIVER_TOTAL_COLORS)
        );
    }

    getSeries = data => {
        const { reportFilter, staticData, pdf } = this.props;
        const value = path(['value'], reportFilter);

        const common = flatten(data.map(([_, series]) =>
            toPairs(series).map(([ id, y ]) => ({
                y,
                ...(value && value.length > 1 ? {} : { color: this.getColor(id, y) })
            }))
        ));

        const series = value && value.length ?
            value.map((item, index) => ({
                name: item,
                color: value.length === 1 ? '#A6A6A6' : undefined,
                data: flatten(toPairs((staticData && (is(Array, staticData) ? staticData[index] : staticData)) || this.props[`getReportDriverTotal${index}`].data).map(([ _, series ]) => getSortedData(toPairs(series), this.state.sort).map(([ id, y ]) => ({
                    y,
                    ...(value && value.length > 1 ? {} : { color: this.getColor(id, y) }),
                    ...(pdf ? {
                        dataLabels: {
                            enabled: true,
                            formatter: () => y,
                            borderWidth: 0,
                            style: {
                                fontSize: 8,
                                textOutline: 0,
                                fontWeight: 'normal'
                            }
                        }
                    } : {})
                }))))
            })) :
            [{
                name: '2019',
                data: common,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '10px'
                    }
                }
            }];

        return value && value.length && this.state.showCommon ? series.concat({
            name: 'Общие результаты',
            type: this.state.commonColumn ? undefined : 'spline',
            color: '#ed0214',
            data: common.map(({ y }) => ({
                y,
                color: '#ed0214',
                ...((pdf && this.state.commonColumn) ? {
                    dataLabels: {
                        enabled: true,
                        formatter: () => y,
                        borderWidth: 0,
                        style: {
                            fontSize: 8,
                            textOutline: 0,
                            fontWeight: 'normal'
                        }
                    }
                } : {})
            }))
        }) : series;
    }

    getUngroupedData = items => {
        const { getReportDriverTotal, commonData } = this.props;
        const data = toPairs(items || commonData || getReportDriverTotal.data);

        return getSortedData(data.map(([ _, series ]) => toPairs(series)).reduce((res, cur) => [...res, ...cur], []), this.state.sort);
    }

    getUngroupedSeries = data => {
        const { reportFilter, pdf, staticData, extended } = this.props;
        const value = path(['value'], reportFilter);
        const common =  data.map(([ id, y ]) => ({
            y,
            ...(value && value.length > 1 ? {} : { color: this.getColor(id, y) }),
            ...(pdf ? {
                dataLabels: {
                    enabled: true,
                    formatter: () => y,
                    borderWidth: 0,
                    style: {
                        fontSize: 8,
                        textOutline: 0,
                        fontWeight: 'normal'
                    }
                }
            } : {})
        }));

        const series = value && value.length ?
            value.map((item, index) => ({
                name: item,
                color: value.length === 1 ? '#A6A6A6' : undefined,
                data: (staticData ? this.getUngroupedData(is(Array, staticData) ? staticData[index] : staticData) : (this.getUngroupedData(this.props[`getReportDriverTotal${index}`].data) || data)).map(([ id, y ]) => ({
                    y,
                    ...(value && value.length > 1 ? {} : { color: this.getColor(id, y) }),
                    ...(pdf ? {
                        dataLabels: {
                            enabled: true,
                            formatter: () => y,
                            borderWidth: 0,
                            y: -5,
                            style: {
                                fontSize: 8,
                                color: 'black',
                                textOutline: '1px white',
                                fontWeight: 'normal'
                            }
                        }
                    } : {})
                }))
            })) :
            [{
                name: '2019',
                data: common,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '10px'
                    }
                }
            }];

        return value && value.length && this.state.showCommon ? series.concat({
            name: 'Общие результаты',
            type: this.state.commonColumn ? undefined : 'spline',
            color: extended ? '#D8E3EB' : '#ed0214',
            data: common.map(({ y }) => ({
                y,
                color: extended ? '#D8E3EB' : '#ed0214',
                ...(extended ? {
                    dataLabels: {
                        enabled: true,
                        formatter: () => y,
                        borderWidth: 0,
                        style: {
                            fontSize: 8,
                            textOutline: 0,
                            fontWeight: 'normal'
                        }
                    }
                } : {})
            }))
        }) : series;
    }

    getOptions = () => {
        const { scale, getReportDriverTotal, reportFilter, pdf, width, fontFamily, title, hideScale, commonData, staticData, vertical, titleColor, titleSize, two, three, extended, config, hideTitle, publicReport } = this.props;
        const { grouped } = this.state;
        const data = grouped ? toPairs(commonData || staticData || getReportDriverTotal.data).map(([ name, series ]) =>
            ([name, fromPairs(getSortedData(toPairs(series), this.state.sort))])
        ) : this.getUngroupedData();
        const value = path(['value'], reportFilter);
        const series = grouped ? this.getSeries(data) : this.getUngroupedSeries(data);

        return {
            chart: {
                type: vertical ? 'bar' : 'column',
                height: pdf ? (extended ? 380 : 420) : 550,
                width,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                },
                events: path(['chartEvents'], config),
                marginTop: hideTitle ? 0 : 40
            },
            title: {
                text: hideTitle ? null : title,
                style: pdf ? {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1)
                } : {}
            },
            legend: {
                enabled: extended ? false : value && value.length,
                symbolHeight: 11,
                symbolWidth: 11,
                symbolRadius: 0,
                verticalAlign: 'bottom',
                itemStyle: {
                    fontWeight: 'normal'
                },
                ...pathOr({}, ['legend'], config)
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                spline: {
                    marker: {
                        symbol: 'circle',
                        fillColor: '#ed0214'
                    }
                }
            },
            xAxis: {
                labels: {
                    groupedOptions: [{
                        ...(vertical ? { x: 0 } : { y: 0 }),
                        rotation: 0,
                        style: {
                            textAlign: 'center'
                        }
                    }],
                    ...(vertical ? {
                        x: -5,
                        style: {
                            textAlign: 'right'
                        }
                    } : {
                        rotation: -90,
                        align: 'right',
                        y: 5,
                        style: {
                            width: pdf ? 200 : undefined
                        }
                    })
                },
                categories: grouped ? data.map(([ name, series ]) => ({
                    name,
                    categories: toPairs(series).map(([ name ]) => ({ name }))
                })) : data.map(([ category ]) => category),
                crosshair: publicReport && series.length > 1
            },
            yAxis: {
                ...(hideScale ? ({}) : scale ? ({
                    min: scale.min,
                    max: scale.max
                }) : ({
                    min: 0,
                    max: 100
                })),
                title: {
                    text: null
                }
            },
            tooltip: publicReport && series.length > 1 ? {
                shared: true
            } : {
                headerFormat: grouped ? '{point.key.name} ({point.key.parent.name})<br>' : '{point.key.name}<br>',
                pointFormat: '<b>{point.y}</b>'
            },
            series
        };
    }

    onToggleShowCommon = e => this.setState({ showCommon: e.target.checked });

    sort = () => {
        this.setState(prev => ({
            sort: !prev.sort ? 'asc' : prev.sort === 'asc' ? 'desc' : null
        }));
    }

    onToggleGrouped = () => {
        this.setState(prev => ({ grouped: !prev.grouped }));
    }

    toggleCommonColumn = e => this.setState({ commonColumn: e.target.checked });

    render() {
        const { getReportDriverTotal, staticData, getDriverImportance, pdf, getRef } = this.props;
        const value = path(['value'], this.props.reportFilter);
        const { sort } = this.state;

        return !staticData && ((getReportDriverTotal.meta.pending && !getReportDriverTotal.meta.lastSucceedAt) || (getDriverImportance.meta.pending && !getDriverImportance.meta.lastSucceedAt)) ?
            <CenterSpin /> :
            <Wrapper>
                { !pdf && (!value || (value && !value.length)) &&
                    <SortBtn onClick={this.sort}>
                        <CaretUpOutlined style={{ color: sort === 'asc' ? '#1890ff' : null }} />
                        <CaretDownOutlined style={{ color: sort === 'desc' ? '#1890ff' : null }} />
                    </SortBtn>
                }
                <HighchartsReact
                    ref={getRef}
                    highcharts={GroupedCategoriesHighcharts}
                    options={this.getOptions()} />
                { !!(!pdf && value && value.length && !staticData) &&
                    <div style={{ textAlign: 'center', paddingBottom: 15 }}>
                        { value && value.length &&
                            <Fragment>
                                <Checkbox checked={this.state.showCommon} onChange={this.onToggleShowCommon}>
                                    Вывести общие результаты
                                </Checkbox>
                                { this.state.showCommon &&
                                    <Checkbox checked={this.state.commonColumn} onChange={this.toggleCommonColumn}>
                                        Вывести общие колонкой
                                    </Checkbox>
                                }
                            </Fragment>
                        }
                        <Checkbox checked={this.state.grouped} onChange={this.onToggleGrouped}>
                            Группировать по блокам
                        </Checkbox>
                    </div>
                }
            </Wrapper>;
    }
}

export const ReportDriverTotalChart = withReportWrapper(withChartPngDownload(ReportDriverTotalChartComponent));

export default withReportFilters(withAsyncActions(props => {
    const values = pathOr([], ['reportFilter', 'value'], props);
    const actions = values.length ?
        fromPairs(values.map((value, index) => ([
            `getReportDriverTotal${index}`,
            getReportDriverTotal
                .withParams(() => ({ property: props.reportFilter.property, value }))
                .withPayload(({ id, reportFilter }) => ({
                    id,
                    q: { property: reportFilter.property, value }
                }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
        ]))) : {};

    return {
        getDriverImportance: getDriverImportance
            .withParams(({ params }) => params)
            .withPayload(({ id, reportFilter }) => ({
                id,
                q: path(['value', 'length'], reportFilter) === 1 ? ({
                    property: reportFilter.property,
                    value: reportFilter.value[0]
                }) : undefined,
            }))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true  }),
        getReportDriverTotal: getReportDriverTotal
            .withParams(({ params }) => params)
            .withPayload(({ id, reportFilter }) => ({
                id,
                q: { property: reportFilter.property }
            }))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        ...actions
    };
})(ReportDriverTotalChart), { multiple: true });
