import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { toPairs, keys, pathOr, unnest, filter, contains, mapObjIndexed, isNil } from 'ramda';

import { GroupedCategoriesHighcharts, filterQuestionsDistribution } from '../../../../utils/charts';
import CenterSpin from '../../../CenterSpin';
import { TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';

export default class ReportQuestionsDistribution extends Component {
    static defaultProps = {
        settings: {}
    };

    getOptions = () => {
        const {
            getReportQuestionsDistribution,
            pdf,
            width,
            fontFamily,
            title,
            staticData,
            settings,
            drivers,
            titleColor,
            titleSize,
            two,
            three,
            percent,
            absolute,
            footerImage,
            hideLegend,
            absoluteMin,
            percentMin
        } = this.props;
        const data = filter(([ key ]) => contains(key, drivers || []), toPairs(filterQuestionsDistribution(staticData || getReportQuestionsDistribution.data))).map(([title, item]) => ([
            title,
            mapObjIndexed(i => filter(x => {
                return (isNil(absoluteMin) || (!isNil(absoluteMin) && absoluteMin <= x.count)) && (isNil(percentMin) || (!isNil(percentMin) && percentMin <= x.percent))
            }, i), item)
        ]));
        const scale = keys(pathOr({} , [0, 1], toPairs(pathOr({}, [0, 1], data))));

        return {
            chart: {
                type: 'bar',
                height: footerImage ? 380 : 440,
                width,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                }
            },
            title: {
                text: title || '',
                style: pdf ? {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1)
                } : {}
            },
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    groupedOptions: [{
                        rotation: -90,
                        y: 19,
                        x: -2,
                        align: 'center',
                        style: {
                            width: 70,
                        }
                    }],
                    style: {
                        width: 400,
                        fontSize: 8,
                        lineHeight: 8
                    },
                    x: -5
                },
                categories: data.map(([ name, series ]) => ({
                    name,
                    categories: toPairs(series).map(([ name ]) => ({ name }))
                }))
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '<span>{value}</span>',
                },
                title: {
                    text: null
                }
            },
            legend: {
                enabled: !hideLegend,
                symbolHeight: 11,
                symbolWidth: 11,
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: 'normal',
                    fontSize: 10
                }
            },
            tooltip: {
                headerFormat: '{point.key.name} ({point.key.parent.name})<br>',
                pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: scale.map(name => ({
                type: 'bar',
                name,
                data: unnest(data.map(([ _, series ]) => toPairs(series))).map(([ _, stat ]) => {
                    const percentValue = pathOr(0, [name, 'percent'], stat);
                    const absoluteValue = pathOr(0, [name, 'count'], stat);

                    return {
                        y: percentValue,
                        dataLabels: {
                            enabled: true,
                            formatter: () => !percentValue && !absoluteValue ? null : percent && absolute ? `${absoluteValue}; ${Math.ceil(percentValue)}%` : percent ? `${Math.ceil(percentValue)}%` : absolute ? absoluteValue : null,
                            borderWidth: 0,
                            style: {
                                fontSize: 7,
                                textOutline: 0,
                                fontWeight: 'normal'
                            }
                        }
                    }
                }),
                color: (settings.scaleColor || {})[name]
            }))
        };
    }

    render() {
        const { staticData, getReportQuestionsDistribution } = this.props;

        return !staticData && getReportQuestionsDistribution.meta.pending && !getReportQuestionsDistribution.meta.lastSucceedAt ?
            <CenterSpin /> :
            <HighchartsReact
                ref={this.props.getRef}
                highcharts={GroupedCategoriesHighcharts}
                options={this.getOptions()} />;
    }
}
