export const PROJECT_LETTER_TEMPLATE_VARIABLES = [
    { id: 'FirstName', name: 'Имя' },
    { id: 'LastName', name: 'Фамилия' },
    { id: 'MiddleName', name: 'Отчество' },
    { id: 'Project', name: 'Название проекта' },
    { id: 'TestLink', name: 'Ссылка на прохождение' },
];

export const PROJECT_RESPONSE_FORM_FIELD_TYPES = [
    { id: 'input', value: 'Строка' },
    { id: 'number', value: 'Число' },
    { id: 'select', value: 'Выпадающий список' },
    { id: 'radio', value: 'Выбор' },
    { id: 'checkbox', value: 'Чекбокс' }
];

export const RESPONDENT_STATUS_COLORS = {
    created: '#ff9800',
    sent: '#2196f3',
    started: '#00bcd4',
    finished: '#4caf50'
};

export const RESPONDENT_STATUS_NAMES = {
    created: 'Не выслан',
    sent: 'Выслан',
    started: 'В работе',
    finished: 'Пройден'
};

export const RESPONDENT_STATUS_FILTER = ['created', 'sent', 'started', 'finished'].map(status => ({
    text: RESPONDENT_STATUS_NAMES[status],
    value: status
}));

export const MESSAGE_TYPES = [
    { id: 'all', value: 'Всем' },
    { id: 'selected', value: 'Выбранным' },
    { id: 'unsent', value: 'Неотправленным' },
    { id: 'unfinished', value: 'Незавершенным' },
    { id: 'started', value: 'Начавшим' },
    { id: 'sent', value: 'Не начавшим' },
];

export const DRIVER_TYPE = [
    { id: 'single', value: 'Single' },
    { id: 'drag', value: 'Drag' },
    { id: 'textarea', value: 'Textarea' },
    { id: 'individual', value: 'Individual' },
    { id: 'matrix', value: 'Matrix' },
    { id: 'slider', value: 'Slider' },
    { id: 'select', value: 'Select' },
    { id: 'nps', value: 'eNPS' }
];

export const DRIVER_OPTIONS = {
    slider: [
        { type: 'allowSkip', label: 'Разрешить пропуск', fieldType: 'checkbox' },
        { type: 'group', label: 'Группировать', fieldType: 'checkbox' },
        { type: 'digitLabel', label: 'Выводить значение', fieldType: 'checkbox' },
        { type: 'step', label: 'Шаг', fieldType: 'number' },
        { type: 'gradient', label: 'Градиент', fieldType: 'checkbox' },
        { type: 'dots', label: 'Показать границы шага', fieldType: 'checkbox' }
    ],
    select: [
        { type: 'multiple', label: 'Множественный выбор', fieldType: 'checkbox' },
        { type: 'group', label: 'Группировать', fieldType: 'checkbox' }
    ],
    drag: [
        { type: 'limitAnswers', label: 'Максимальное количество выбранных', fieldType: 'number' },
        { type: 'minAnswers', label: 'Минимальное количество выбранных', fieldType: 'number' },
        { type: 'disableSort', label: 'Отключить сортировку', fieldType: 'checkbox' }
    ],
    textarea: [
        { type: 'allowEmpty', label: 'Разрешить пустой ответ', fieldType: 'checkbox' }
    ]
};

export const PROJECT_THEMES = [
    { id: 'defaultTheme', value: 'Стандартная' },
    { id: 'ancorTheme', value: 'Анкор' }
];
