import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { withAsyncActions } from 'react-async-client';
import { isNil } from 'ramda';
import * as yup from 'yup';

import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import { PDF_FONTS } from '../../constants/charts';
import FooterVariablesField from './formComponents/FooterVariablesField';
import { getRespondentsStatuses, getReportFilters } from '../../actions/asyncActions';

class ReportPageForm extends Component {
    render() {
        const { getRespondentsStatuses, getReportFilters } = this.props;

        return <Fragment>
            <Field
                name='title'
                component={Input}
                label='Заголовок страницы' />
            <Field
                name='font'
                component={Select}
                label='Шрифт'
                options={PDF_FONTS} />
            <FooterVariablesField
                {...this.props}
                statuses={getRespondentsStatuses.data}
                filters={getReportFilters.data} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    title: yup.string().nullable().required()
});

export default withAsyncActions({
    getReportFilters: getReportFilters
        .withPayload(({ project }) => ({ id: project }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getRespondentsStatuses: getRespondentsStatuses
        .withPayload(({ project }) => ({
            q: { project }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
})(
    withFormWrapper(ReportPageForm, {
        validationSchema,
        mapPropsToValues: ({ index, data }) => !isNil(index) ? data.pages[index].settings : ({}),
        mapBeforeSubmit: (values, { data, index, add }) => ({
            id: data.id,
            data: add ? [{
                op: 'add',
                path: `/pages/-`,
                value: { settings: values, charts: [] }
            }] : [{
                op: 'replace',
                path: `/pages/${index}/settings`,
                value: values
            }]
        })
    })
);
