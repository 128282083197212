import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchProject } from '../../actions/asyncActions';
import ProjectLetterTemplateForm from '../forms/ProjectLetterTemplateForm';

class ProjectLetterTemplateModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Редактировать шаблон письма'
            footer={null}>
            <ProjectLetterTemplateForm
                formAction={patchProject}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон письма успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить шаблон письма'))
        .withOptions({ resetOnUnmount: true })
})(ProjectLetterTemplateModal);
