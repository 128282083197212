import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import HighchartsReact from 'highcharts-react-official';
import { toPairs, pathOr, unnest, keys, path } from 'ramda';

import { getReportDriverDistribution } from '../../../../actions/asyncActions';
import { GroupedCategoriesHighcharts } from '../../../../utils/charts';
import withReportFilters from '../../../hocs/withReportFilters';
import CenterSpin from '../../../CenterSpin';
import { TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';
import withReportWrapper from '../../../hocs/withReportWrapper';
import withChartPngDownload from '../../../hocs/withChartPngDownload';

export class ReportDriverDistributionChartComponent extends Component {
    static defaultProps = {
        settings: {},
        grouped: true
    };

    getUngroupedData = () => {
        const { getReportDriverDistribution, commonData, staticData } = this.props;
        const data = toPairs(commonData || staticData || getReportDriverDistribution.data);

        return data.map(([ _, series ]) => toPairs(series)).reduce((res, cur) => [...res, ...cur], []);
    }

    getOptions = () => {
        const { getReportDriverDistribution, pdf, width, fontFamily, title, staticData, settings, grouped, titleColor, titleSize, two, three, config } = this.props;
        const data = grouped ? toPairs(staticData || getReportDriverDistribution.data) : this.getUngroupedData();
        const scale = keys(pathOr({} , [0, 1], grouped ? toPairs(pathOr({}, [0, 1], data)) : data));

        return {
            chart: {
                type: 'bar',
                height: pdf ? 420 : 800,
                width,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                },
                events: path(['chartEvents'], config)
            },
            title: {
                text: title,
                style: pdf ? {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1)
                } : {}
            },
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    groupedOptions: [{
                        x: 0,
                        rotation: 0,
                        style: {
                            textAlign: 'center'
                        }
                    }],
                    style: {
                        textAlign: 'right'
                    },
                    x: -5
                },
                categories: grouped ? data.map(([ name, series ]) => ({
                    name,
                    categories: toPairs(series).map(([ name ]) => ({ name }))
                })) : data.map(([ category ]) => category)
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '<span>{value}</span>',
                },
                title: {
                    text: null
                }
            },
            legend: {
                symbolHeight: 11,
                symbolWidth: 11,
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: 'normal',
                    fontSize: pdf ? 10 : undefined
                },
                ...pathOr({}, ['legend'], config)
            },
            tooltip: {
                headerFormat: '{point.key.name} ({point.key.parent.name})<br>',
                pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: scale.map(name => ({
                name,
                data: grouped ?
                    unnest(data.map(([ _, series ]) => toPairs(series))).map(([ _, stat ]) => pathOr(0, [name, 'percent'], stat)) :
                    data.map(([ _, stat ]) => pathOr(0, [name, 'percent'], stat)),
                color: (settings.scaleColor || {})[name]
            }))
        };
    }

    render() {
        const { getReportDriverDistribution, staticData } = this.props;

        return !staticData && getReportDriverDistribution.meta.pending && !getReportDriverDistribution.meta.lastSucceedAt ?
            <CenterSpin /> :
            <HighchartsReact
                ref={this.props.getRef}
                highcharts={GroupedCategoriesHighcharts}
                options={this.getOptions()} />;
    }
}

export const ReportDriverDistributionChart = withReportWrapper(withChartPngDownload(ReportDriverDistributionChartComponent));

export default withReportFilters(withAsyncActions({
    getReportDriverDistribution: getReportDriverDistribution
        .withParams(({ params }) => params)
        .withPayload(({ id, reportFilter }) => ({
            id,
            q: reportFilter
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ReportDriverDistributionChart));
