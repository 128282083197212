import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';
import { toPairs, contains, isNil, update, append, path, isEmpty, filter, is, find, propEq, any } from 'ramda';
import { Button, Form } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import Input from './formComponents/Input';
import ColorPicker from './formComponents/ColorPicker';
import ListenerField from './ListenerField';
import { getReportFilters } from '../../actions/asyncActions';
import { PUBLIC_REPORT_TYPES, LEGEND_POSITIONS } from '../../constants/charts';
import Switch from './formComponents/Switch';
import { ColorField } from './PdfChartForm';
import NumberInput from './formComponents/NumberInput';

class ReportChartForm extends Component {
    renderColorFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <ColorField key={`color-${index}`}>
                    <Field
                        name={name}
                        component={ColorPicker} />
                    <Button icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </ColorField>
            )}
            <div>
                <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>Добавить цвет</Button>
            </div>
        </div>;
    }

    onChangeProperty = name => this.props.form.change(name ? `${name}.value` : 'filter.value', null);

    onChangeUsePropertyVariable = name => {
        this.props.form.batch(() => {
            this.props.form.change(name ? `${name}.property` : 'filter.property', null);
            this.props.form.change(name ? `${name}.value` : 'filter.value', null);
        });
    }

    onChangeUseValueVariable = name => this.props.form.change(name ? `${name}.value` : 'filter.value', null);

    renderSlices = (fields, type) => {
        return <div>
            { fields.map((name, index) =>
                <div key={name}>
                    { this.renderFilterFields(type, fields.value[index].property, name) }
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => fields.remove(index)}
                        style={{ marginBottom: 15 }}
                        danger>
                        Удалить фильтр
                    </Button>
                </div>
            )}
            <Button
                icon={<PlusOutlined />}
                onClick={() => fields.push({})}>
                Добавить фильтр
            </Button>
        </div>
    }

    renderFilterFields = (type, property, name) => {
        const { getReportFilters: { data, meta }} = this.props;
        const namePath = name ? name.replace('[', '.').replace(']', '').split('.').map(v => isNaN(Number(v)) ? v : Number(v)) : null;

        return <Fragment>
            <Field
                name={name ? `${name}.usePropertyVariable` : 'settings.usePropertyVariable'}
                component={Switch}
                label='Использовать переменные в сегменте'
                onChange={() => this.onChangeUsePropertyVariable(name)}
                disableClear={!!name} />
            <ListenerField listenFieldName={name ? `${name.replace('[', '.').replace(']', '')}.usePropertyVariable` : 'settings.usePropertyVariable'}>
                { values => {
                    const usePropertyVariable = name ? path([...namePath, 'usePropertyVariable'], values) : path(['settings', 'usePropertyVariable'], values);

                    return usePropertyVariable ?
                        <Field
                            name={name ? `${name}.property` : 'filter.property'}
                            component={Input}
                            label='Сегмент'
                            disableClear={!!name}
                            validate={(value, all) => !value && (contains(all.type, ['report_filters', 'report_table']) || !!name || (!name && (all.settings.slices || []).length)) ? 'Это обязательное поле' : undefined} /> :
                        <Field
                            name={name ? `${name}.property` : 'filter.property'}
                            component={Select}
                            label='Сегмент'
                            loading={meta.pending}
                            options={toPairs(data).map(([ value ]) => ({ id: value, value }))}
                            allowClear={!name && !contains(type, ['report_filters', 'report_table'])}
                            disableClear={!!name}
                            onChange={() => this.onChangeProperty(name)}
                            validate={(value, all) => !value && (contains(all.type, ['report_filters', 'report_table']) || !!name || (!name && (all.settings.slices || []).length)) ? 'Это обязательное поле' : undefined} />
                }}
            </ListenerField>
            <ListenerField listenFieldName={[name ? `${name.replace('[', '.').replace(']', '')}.usePropertyVariable` : 'settings.usePropertyVariable', name ? `${name.replace('[', '.').replace(']', '')}.useValueVariables` : 'settings.useValueVariables']}>
                { values => {
                    const usePropertyVariable = name ? path([...namePath, 'usePropertyVariable'], values) : path(['settings', 'usePropertyVariable'], values);
                    const useValueVariables = name ? path([...namePath, 'useValueVariables'], values) : path(['settings', 'useValueVariables'], values);

                    return property && !contains(type, ['report_filters', 'report_table']) &&
                        <Fragment>
                            { !usePropertyVariable &&
                                <Field
                                    name={name ? `${name}.useValueVariables` : 'settings.useValueVariables'}
                                    component={Switch}
                                    label='Использовать переменные в фильтре по сегменту'
                                    disableClear={!!name}
                                    onChange={() => this.onChangeUseValueVariable(name)} />
                            }
                            { (useValueVariables || usePropertyVariable) ?
                                <Field
                                    name={name ? `${name}.value` : 'filter.value'}
                                    component={Input}
                                    disableClear={!!name}
                                    label='Фильтр по сегменту'
                                    validate={(value, all) => (!!name || (!name && (all.settings.slices || []).length)) && !value ? 'Это обязательное поле' : undefined} /> :
                                <Field
                                    name={name ? `${name}.value` :'filter.value'}
                                    component={Select}
                                    label='Фильтр по сегменту'
                                    loading={meta.pending}
                                    options={data[property]}
                                    namePath='_id'
                                    valuePath='_id'
                                    isMulti={!name && contains(type, ['block_total', 'driver_total'])}
                                    disableClear={!!name}
                                    validate={(value, all) => (!!name || (!name && (all.settings.slices || []).length)) && !value ? 'Это обязательное поле' : undefined}
                                    allowClear={!name} />
                            }
                        </Fragment>;
                }}
            </ListenerField>
        </Fragment>;
    }

    renderHiddenFields = ({ filter, type }) => {
        const property = path(['property'], filter);
        const value = path(['value'], filter);

        return type &&
            <Fragment>
                { this.renderFilterFields(type, property) }
                { contains(type, ['block_total', 'driver_total', 'block_distribution', 'driver_distribution']) &&
                    <Fragment>
                        <h2>Множественная фильтрация</h2>
                        <FieldArray name='settings.slices'>
                            { ({ fields }) => this.renderSlices(fields, type) }
                        </FieldArray>
                    </Fragment>
                }
                <Field name='settings.slices' component={() => null} />
                { contains(type, ['block_total', 'driver_total']) && !!(value || []).length &&
                    <Fragment>
                        <Field
                            name='settings.showCommon'
                            component={Switch}
                            label='Показывать общий результат' />
                        <ListenerField listenFieldName='settings.showCommon'>
                            { ({ settings: { showCommon }}) =>
                                showCommon &&
                                    <Field
                                        name='settings.commonColumn'
                                        component={Switch}
                                        label='Показывать общий результат колонкой' />
                            }
                        </ListenerField>
                    </Fragment>
                }
                { type === 'driver_total' &&
                    <Fragment>
                        <Field
                            name='settings.vertical'
                            component={Switch}
                            label='Отображать по вертикали' />
                        <Field
                            name='settings.grouped'
                            component={Switch}
                            label='Группировать по блокам' />
                        <Field
                            name='settings.table'
                            component={Switch}
                            label='Вывести таблицу с результатами' />
                    </Fragment>
                }
                <Field
                    name='settings.title'
                    component={Input}
                    label='Заголовок' />
                { type === 'report_filters' &&
                    <Fragment>
                        <Field
                            name='settings.legendPosition'
                            component={Select}
                            label='Позиционирование значений'
                            options={LEGEND_POSITIONS} />
                        <Form.Item label='Палитра' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                            <FieldArray name='settings.colors'>
                                { this.renderColorFields }
                            </FieldArray>
                        </Form.Item>
                    </Fragment>
                }
                { type === 'report_table' &&
                    <Field
                        name='settings.numberAmount'
                        component={NumberInput}
                        label='Количество знаков после запятой' />
                }
                <Field
                    name='settings.footer'
                    component={Input}
                    label='Подпись' />
            </Fragment>;
    }

    onChangeType = type => {
        this.props.form.batch(() => {
            if (type !== 'report_filters') {
                this.props.form.change('settings.colors', null);
            }

            if (contains(type, ['report_filters', 'report_table'])) {
                this.props.form.change('settings.slices', null);
            }

            type && this.props.form.change('settings.title', find(propEq('id', type), PUBLIC_REPORT_TYPES).value);
            this.props.form.change('filter.value', null);
        });
    }

    render() {
        return <Fragment>
            <Field
                name='type'
                component={Select}
                label='Тип'
                options={PUBLIC_REPORT_TYPES}
                onChange={this.onChangeType} />
            <Field name='settings.title' component={() => null} />
            <ListenerField listenFieldName={['type', 'filter']}>
                { this.renderHiddenFields }
            </ListenerField>
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    dataFilters: yup.array().of(
        yup.object().shape({
            type: yup.string().required()
        })
    )
})

export default withAsyncActions({
    getReportFilters: getReportFilters
        .withPayload(({ project }) => ({ id: project }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(ReportChartForm, {
        validationSchema,
        mapPropsToValues: ({ data, pageIndex, chartIndex }) => {
            if (isNil(chartIndex)) {
                return {};
            }

            let chart = data.pages[pageIndex].charts[chartIndex];
            const type = find(({ id }) => any(propEq('type', id), chart.dataFilters), PUBLIC_REPORT_TYPES).id;
            const property = path(['filter', 'property'], find(i => i.type === type && i.filter.property, chart.dataFilters));
            const value = contains(type, ['block_total', 'driver_total']) ?
                filter(i => i.type === type && path(['filter', 'value'], i), chart.dataFilters).map(i => path(['filter', 'value'], i)) :
                path(['filter', 'value'], find(i => i.type === type && i.filter.value, chart.dataFilters));

            return {
                ...chart,
                type,
                filter: {
                    property,
                    value
                }
            };
        },
        mapBeforeSubmit: (values, { data, pageIndex, chartIndex, add }) => {
            const property = path(['filter', 'property'], values);
            const value = path(['filter', 'value'], values);

            const item = {
                settings: values.settings,
                dataFilters: [
                    ...filter(i => !isEmpty(i), [
                        (contains(values.type, ['report_filters', 'report_table']) ? {} : { type: values.type, filter: { property: null, value: null }}),
                        (values.type === 'driver_total' ? { type: 'driver_importance', filter: { property: null, value: null }} : {}),
                        ...(value ? (is(String, value) ? [value] : value).map(v => ({
                            type: values.type,
                            filter: { property, value: v }
                        })) : ([])),
                        (property && !value ? {
                            type: values.type,
                            filter: { property }
                        } : {})
                    ])
                ]
            };

            let charts = isNil(chartIndex) ? append(item, data.pages[pageIndex].charts) : update(chartIndex, item, data.pages[pageIndex].charts);

            return [{
                op: 'replace',
                path: `/pages/${pageIndex}/charts`,
                value: charts
            }];
        }
    })
);
