import React, { Component, Fragment } from 'react';
import { keys, fromPairs, find, propEq, filter, any, path, map, remove } from 'ramda';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { Button, Form, Dropdown, Menu, Input as AntdInput } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import { DRIVER_OPTIONS } from '../../constants/project';
import Switch from './formComponents/Switch';
import NumberInput from './formComponents/NumberInput';
import LanguagesSelector from './formComponents/LanguagesSelector';
import ListenerField from './ListenerField';
import Editor from './formComponents/Editor';
import FieldHelp from './FieldHelp';

const FieldItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-form-item,
    .ant-input-number {
        margin-bottom: 0;
        width: 100%;
        margin-right: 10px;
    }
`;

const FieldValueWrapper = styled.div`
    width: 50%;
    margin-right: 7px;
    .ant-row.ant-form-item {
        width: 100%;
    }
`;

const OptionsFieldItem = styled(FieldItem)`
    .ant-form-item {
        width: 50%;
    }
    .ant-btn-danger {
        min-width: 32px;
    }
`;

const required = value => !value ? 'required' : undefined;

class DriverSettingsForm extends Component {
    removeScaleFromTranslations = (index, translations) => {
        this.props.form.change('translations', map(value => ({
            ...value,
            scale: remove(index, 1, value.scale || [])
        }), translations));
    }

    renderScaleFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <FieldItem key={`scale-field-${index}`}>
                    <Field
                        name={name}
                        component={Input}
                        hideErrorMsg
                        validate={required} />
                    <Button.Group style={{ width: 70 }}>
                        <LanguagesSelector name={name} />
                        <ListenerField listenFieldName='translations'>
                            { ({ translations }) =>
                                <Button danger icon={<DeleteOutlined />} onClick={() => {
                                    fields.remove(index);
                                    this.removeScaleFromTranslations(index, translations);
                                }} />
                            }
                        </ListenerField>
                    </Button.Group>
                </FieldItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>Добавить значение</Button>
        </div>;
    }

    getOptions = () => {
        const { type } = this.props.item;
        const additionalOptions = DRIVER_OPTIONS[type] || [];

        return [
            { type: 'hideQuestions', label: 'Скрывать вопрос', fieldType: 'string' },
            ...additionalOptions,
            { type: 'custom', label: 'Кастомное значение', fieldType: 'string' }
        ];
    }

    getOptionFieldProps = type => {
        const options = this.getOptions();
        const fieldType = path(['fieldType'], find(propEq('type', type), options));

        switch(fieldType) {
            case 'string':
                return {
                    component: Input
                };
            case 'checkbox':
                return {
                    component: Switch,
                    disabled: true
                };
            case 'number':
                return {
                    component: NumberInput,
                    min: 0
                };
            default:
                return {
                    component: Input
                };
        }
    }

    onChangeOptionType = (type, fieldName) => {
        const options = this.getOptions();
        const isCheckbox = propEq('fieldType', 'checkbox', find(propEq('type', type), options) || {});

        this.props.form.change(fieldName, isCheckbox ? true : null);
    }

    renderOptionsMenu = fields => {
        const options = filter(({ type }) => !any(propEq('key', type), fields.value), this.getOptions());

        return <Menu>
            { options.map(option =>
                <Menu.Item
                    key={option.type}
                    onClick={() => fields.push({
                        key: option.type === 'custom' ? '' : option.type,
                        value: option.fieldType === 'checkbox' ? true : null
                    })}>
                    { option.label }
                </Menu.Item>
            )}
        </Menu>;
    }

    renderOptionsFields = ({ fields }) => {
        const options = this.getOptions();

        return <div>
            { fields.map((name, index) =>
                <OptionsFieldItem key={`option-field-${index}`}>
                    { any(field => fields.value[index].key === field.type, options) ?
                        <Fragment>
                            <Field name={`${name}.key`} component={() => null} disableClear />
                            <AntdInput
                                style={{ width: '50%', marginRight: 10 }}
                                value={path(['label'], find(propEq('type', fields.value[index].key), options))}
                                disabled />
                        </Fragment> :
                        <Field
                            name={`${name}.key`}
                            component={Input}
                            hideErrorMsg
                            validate={required}
                            disableClear />
                    }
                    <span style={{ marginRight: 10 }}>:</span>
                    { fields.value[index].key === 'hideQuestions' ?
                        <FieldValueWrapper>
                            <FieldHelp text={`Например: {{answers['Номер вопроса'] != 'Значение'}}`}>
                                <Field
                                    name={`${name}.value`}
                                    hideErrorMsg
                                    validate={required}
                                    disableClear
                                    {...this.getOptionFieldProps(fields.value[index].key)} />
                            </FieldHelp>
                        </FieldValueWrapper> :
                        <Field
                            name={`${name}.value`}
                            hideErrorMsg
                            validate={required}
                            disableClear
                            {...this.getOptionFieldProps(fields.value[index].key)} />
                    }
                    <Button danger icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </OptionsFieldItem>
            )}
            <Dropdown trigger={['click']} overlay={this.renderOptionsMenu(fields)}>
                <Button icon={<PlusOutlined />}>Добавить значение</Button>
            </Dropdown>
        </div>;
    }

    render() {
        return <Fragment>
            { !this.props.hideVariablesField &&
                <Field
                    name='title'
                    component={Editor}
                    label='Заголовок'
                    inline
                    translations />
            }
             <Form.Item label='Шкала' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='scale'>
                    { this.renderScaleFields }
                </FieldArray>
            </Form.Item>
            <Form.Item label='Дополнительные опции' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='options'>
                    { this.renderOptionsFields }
                </FieldArray>
            </Form.Item>
            <Field name='translations' component={() => null} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(DriverSettingsForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        options: keys(item.options || {}).map(key => ({ key, value: item.options[key] }))
    }),
    mapBeforeSubmit: (values, props) => ({
        id: props.project,
        data: [{
            op: 'replace',
            path: `/drivers/${props.index}`,
            value: {
                ...values,
                options: values.options && values.options.length ? fromPairs(values.options.map(({ key, value }) => ([key, value]))) : []
            }
        }]
    })
});
