import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { Button, message, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CheckOutlined, LinkOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { getReports, deleteReport } from '../../../actions/asyncActions';
import { openReportModal } from '../../../actions/modalActions';
import TableList from '../table/TableList';
import { POST_REPORT, DELETE_REPORT } from '../../../constants/actionTypes';

class ProjectPublicReports extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                render: (name, { id }) => <Link to={`/projects/${this.props.project.id}/reports/${id}`}>{ name || 'Отчет' }</Link>
            },
            {
                title: 'Опубликовано',
                key: 'published',
                dataIndex: 'published',
                align: 'center',
                render: published => published && <CheckOutlined style={{ color: '#329244' }} />
            },
            {
                key: 'actions',
                width: 100,
                align: 'right',
                render: item =>
                    <Button.Group>
                        <CopyToClipboard text={this.getReportLink(item.id)} onCopy={this.onCopyLink}>
                            <Button icon={<LinkOutlined />} />
                        </CopyToClipboard>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить отчет?'
                            okText='Да'
                            cancelText='Нет'
                            onConfirm={() => this.delete(item.id)}
                            placement='left'>
                            <Button icon={<DeleteOutlined />} danger />
                        </Popconfirm>
                    </Button.Group>
            }
        ];
    }

    getReportLink = id => `${window.location.origin}/report/${id}`;

    onCopyLink = () => message.success('Ссылка успешно скопирована в буфер обмена');

    getButtons = () => {
        const { openReportModal, project } = this.props;

        return <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => openReportModal({ project: project.id, published: true })}>
            Добавить
        </Button>;
    }

    delete = id => {
        this.props.deleteReport.dispatch(id);
    }

    render() {
        return <Fragment>
            <div style={{ padding: 15, textAlign: 'right', borderBottom: '1px solid #e8e8e8' }}>
                { this.getButtons() }
            </div>
            <TableList
                action={getReports}
                columns={this.getColumns()}
                staticFilter={{
                    project: this.props.project.id
                }}
                refreshActions={[POST_REPORT, DELETE_REPORT]} />
        </Fragment>;
    }
}

export default asyncConnect({
    deleteReport: deleteReport
        .withSuccessHandler(() => message.success('Отчет успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить отчет'))
        .withOptions({ resetOnUnmount: true })
}, null, { openReportModal })(ProjectPublicReports);
