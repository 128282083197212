import React, { Component } from 'react';
import { Button, List, Modal, Collapse } from 'antd';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { toPairs } from 'ramda';
import { PlusOutlined } from '@ant-design/icons';

import Input from './Input';
import { RESPONDENT_STATUS_NAMES } from '../../../constants/project';

export const FooterInput = styled.div`
    display: flex;
    align-items: flex-end;
    .ant-form-item {
        width: 100%;
    }
    .ant-btn {
        margin-bottom: 15px;
        margin-left: 15px;
    }
`;

const Panel = styled(Collapse.Panel)`
    .ant-collapse-content-box {
        padding: 0 16px;
    }
`;

const VariableWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default class FooterVariablesField extends Component {
    state = {
        variablesModal: false
    };

    openVariablesModal = () => this.setState({ variablesModal: true });

    closeVariablesModal = () => this.setState({ variablesModal: false });

    addVariable = text => {
        const { form, name } = this.props;

        form.change(name || 'footer', (form.getState().values.footer || '') + text);
        this.closeVariablesModal();
    }

    renderVariables = () => {
        const { filters, statuses, segment } = this.props;

        return <div>
            { segment &&
                <VariableWrapper style={{ marginBottom: 15 }}>
                    Данные по сегменту
                    <Button icon={<PlusOutlined />} size='small' onClick={() => this.addVariable(`{{segmentData}}`)} />
                </VariableWrapper>
            }
            <h3>Статусы</h3>
            <List
                size='small'
                dataSource={toPairs(statuses)}
                renderItem={([ name ]) => <List.Item>
                    <VariableWrapper>
                        { RESPONDENT_STATUS_NAMES[name] }
                        <Button icon={<PlusOutlined />} size='small' onClick={() => this.addVariable(`{{statuses['${name}']}}`)} />
                    </VariableWrapper>
                </List.Item>} />
            <h3 style={{ marginTop: 15 }}>Фильтры</h3>
            <Collapse accordion>
                { toPairs(filters).map(([ name, data ]) =>
                    <Panel header={name} key={name}>
                        <List
                            size='small'
                            dataSource={data}
                            renderItem={item => <List.Item>
                                <VariableWrapper>
                                    { item._id }
                                    <Button icon={<PlusOutlined />} size='small' onClick={() => this.addVariable(`{{filters['${name}']['${item._id}']}}`)} />
                                </VariableWrapper>
                            </List.Item>} />
                    </Panel>
                )}
            </Collapse>
        </div>
    }

    render() {
        return <FooterInput>
            <Field
                name={this.props.name || 'footer'}
                component={Input}
                label='Футер' />
            <Button icon={<PlusOutlined />} onClick={this.openVariablesModal}>
                Вставить переменную
            </Button>
            <Modal
                title='Вставить переменную'
                footer={<Button onClick={this.closeVariablesModal}>Закрыть</Button>}
                visible={this.state.variablesModal}
                onCancel={this.closeVariablesModal}
                style={{ top: 20 }}
                maskClosable={false}
                destroyOnClose>
                { this.renderVariables() }
            </Modal>
        </FooterInput>;
    }
}
