import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { postMessagesCompany } from '../../actions/asyncActions';
import { message } from 'antd';
import SendCompanyLetterForm from '../forms/SendCompanyLetterForm';

class SendCompanyLetterModal extends Component {
    render() {
        const { modal, params, postMessagesCompany } = this.props;

        return <Modal
            {...modal}
            title='Отправить письмо'
            footer={null}>
            <SendCompanyLetterForm
                item={params}
                formAction={postMessagesCompany} />
        </Modal>;
    }
}

export default withAsyncActions({
    postMessagesCompany: postMessagesCompany
        .withSuccessHandler(({ close }) => {
            message.success('Письмо успешно отправлено');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось отправить письмо'))
        .withOptions({ resetOnUnmount: true })
})(SendCompanyLetterModal);
