import React, { Component } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { getToken } from '../../../utils/token';

class FileUpload extends Component {
    onChange = info => {
        switch (info.file.status) {
            case 'done':
                this.props.onChange({ id: info.file.response.id, name: info.file.response.name });
                break;
            case 'error':
                message.error('Не удалось загрузить файл');
                break;
            default:
                break;
        }
    }

    render() {
        const { input: { value }, onChange, accept } = this.props;

        return value ?
            <div>
                <span style={{ marginRight: 15 }}>{ value.name }</span>
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => onChange(null)}>
                    Удалить
                </Button>
            </div> :
            <Upload
                onChange={this.onChange}
                showUploadList={false}
                accept={accept}
                action={`/api/files?token=${getToken()}`}>
                <Button>
                    <UploadOutlined /> Загрузить файл
                </Button>
            </Upload>;
    }
}

export default withFieldWrapper(FileUpload);
