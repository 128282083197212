import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import ProjectPdfTemplateForm from '../forms/ProjectPdfTemplateForm';

class ProjectPdfTemplateModal extends Component {
    onSubmit = values => {
        if (this.props.params.onSubmit) {
            this.props.params.onSubmit(values);
            this.props.close();
        }
    }

    render() {
        const { modal, params, formAction } = this.props;

        return <Modal
            {...modal}
            title={params.item ? 'Редактировать шаблон' : 'Добавить шаблон'}
            footer={null}>
            <ProjectPdfTemplateForm
                formAction={formAction || this.onSubmit}
                item={params.item}
                filters={params.filters}
                statuses={params.statuses}
                drivers={params.drivers}
                project={params.project}
                mapBeforeSubmit={params.mapBeforeSubmit} />
        </Modal>;
    }
}

export default withAsyncActions(({ params: { submitAction }}) => submitAction ? ({
    formAction: submitAction
        .withParams(() => ({ type: 'template' }))
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон успешно сохранен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить шаблон'))
        .withOptions({ resetOnUnmount: true })
}) : ({}))(ProjectPdfTemplateModal);
