import React, { Component } from 'react';
import { equals } from 'ramda';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';

import { extendSearchPath } from '../../utils/urlParams';
import InitialValuesContext from '../contexts/InitialValuesContext';

function withFilters(WrappedComponent) {
    class WithFilter extends Component {
        componentDidMount() {
            this.setLocation();
        }

        componentDidUpdate({ values }) {
            if (values && !equals(this.props.values, values)) {
                this.setLocation();
            }
        }

        setLocation() {
            const { location, history: { replace }, urlPrefix } = this.props;
            const params = {
                filter: JSON.stringify(this.props.values),
                pagination: null
            };

            replace(extendSearchPath(location, urlPrefix ? { [urlPrefix]: params } : params));
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    return class WithForm extends Component {
        static defaultProps = {
            filterProps: {}
        };

        render() {
            const { initFilters } = this.props;

            return (
                <InitialValuesContext.Provider value={initFilters}>
                    <Form
                        initialValues={initFilters}
                        onSubmit={() => {}}
                        render={props =>
                            <WithFilter {...props} {...this.props} />
                        }
                    />
                </InitialValuesContext.Provider>
            );
        }
    }
}

export default WrappedComponent => withRouter(
    withFilters(WrappedComponent)
);
