import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import { isNil } from 'ramda';

import Modal from './Modal';
import { patchReport } from '../../actions/asyncActions';
import ReportChartForm from '../forms/ReportChartForm';

class ReportChartModal extends Component {
    render() {
        const { modal, params: { pageIndex, chartIndex, project, data }, patchReport } = this.props;

        return <Modal
            {...modal}
            title={!isNil(chartIndex) ? 'Редактировать график' : 'Добавить график'}
            footer={null}>
            <ReportChartForm
                formAction={patchReport}
                data={data}
                project={project}
                pageIndex={pageIndex}
                chartIndex={chartIndex} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchReport: patchReport
        .withSuccessHandler(({ close, params: { chartIndex }}) => {
            message.success(!isNil(chartIndex) ? 'График успешно изменен' : 'График успешно добавлен');
            close();
        })
        .withErrorHandler(({ chartIndex }) => message.error(!isNil(chartIndex) ? 'Не удалось изменить график' : 'Не удалось добавить график'))
        .withOptions({ dispatchOnUnmount: true })
})(ReportChartModal);
