import { find, omit } from 'ramda';

import FORMATTERS from '../mappers';

export const responseMapper = (response) => {
    const url = response.config.url;
    const formatter = find(({ pattern }) => pattern.test(url))(FORMATTERS);

    if (formatter && formatter['decode']) {
        return formatter['decode'](response.data, response);
    }

    return response.data;
};

export const requestMapper = (config) => {
    const url = config.url;
    const formatter = find(({ pattern }) => pattern.test(url))(FORMATTERS);

    if (url === '/refresh_token') {
        config.headers.common = omit(['Authorization'], config.headers.common);
    }

    if (formatter && formatter['encode']) {
        return { ...config, data: formatter['encode'](config.data, config) };
    }

    return config;
}
