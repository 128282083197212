import React from 'react';
import { Switch } from 'react-router-dom';

import Login from '../../guest/Login';
import NoMatch from '../NoMatch';
import Recovery from '../../guest/Recovery';
import Route from '../../Route';

const GuestRoutes = () =>
    <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/recovery' component={Recovery} />
        <Route component={NoMatch} />
    </Switch>;

export default GuestRoutes;
