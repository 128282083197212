import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { any, append, filter, find, prop, propEq, remove } from 'ramda';
import { Button, Form, Select as AntdSelect, Spin } from 'antd';
import debounce from 'debounce';
import { withAsyncActions } from 'react-async-client';
import { withState } from 'recompose';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import { MESSAGE_TYPES, PROJECT_LETTER_TEMPLATE_VARIABLES } from '../../constants/project';
import { getRespondents } from '../../actions/asyncActions';
import ListenerField from './ListenerField';
import Input from './formComponents/Input';
import VariablesInput from './formComponents/VariablesInput';
import { LINES_PER_PAGE } from '../../constants/table';
import { DeleteOutlined } from '@ant-design/icons';

class SendRespondentLetterForm extends Component {
    state = {
        search: ''
    };

    searchRespondent = debounce(value => this.props.setSearch(value.length > 2 ? value : ''), 400);

    search = search => {
        this.setState({ search });
        this.searchRespondent(search);
    };

    addRespondent = (id, respondents) => {
        const respondent = find(propEq('id', id), this.props.getRespondents.data.items || []);

        this.props.form.change('respondents', append(respondent, respondents || []));
    }

    removeRespondent = (index, respondents) => {
        this.props.form.change('respondents', remove(index, 1, respondents || []));
    }

    renderRespondentsField = respondents => {
        const { getRespondents } = this.props;
        const respondentsList = this.state.search.length > 2 ?
            filter(({ id }) => !any(r => r.id === id, respondents), getRespondents.data.items || []) : [];

        return <Fragment>
            <Form.Item label='Респонденты' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <div style={{ position: 'relative' }}>
                    <AntdSelect
                        placeholder='Добавить респондента'
                        showSearch
                        showArrow={false}
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onSearch={this.search}
                        onChange={id => this.addRespondent(id, respondents)}
                        value={null}
                        style={{ width: '100%' }}
                        notFoundContent={this.state.search.length > 2 && !getRespondents.meta.pending ? 'Ничего не найдено' : false}>
                        { respondentsList.map(item =>
                            <AntdSelect.Option key={item.id} value={item.id}>
                                { `${item.lastName} ${item.firstName} ${item.middleName || ''}` }
                            </AntdSelect.Option>
                        )}
                    </AntdSelect>
                    { getRespondents.meta.pending && <Spin style={{ position: 'absolute', top: 7, right: 7 }} size='small' /> }
                </div>
            </Form.Item>
            { (respondents || []).map((item, index) =>
                <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5, alignItems: 'center' }}>
                    <span>{ index + 1 }. { `${item.lastName} ${item.firstName} ${item.middleName || ''}` }</span>
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => this.removeRespondent(index, respondents)} />
                </div>
            )}
        </Fragment>;
    }

    render() {
        return <Fragment>
            <Field
                name='type'
                component={Select}
                label='Тип'
                options={MESSAGE_TYPES} />
            <ListenerField listenFieldName={['type', 'respondents']}>
                { ({ type, respondents }) => type === 'selected' &&
                    this.renderRespondentsField(respondents)
                }
            </ListenerField>
            <Field
                name='subject'
                component={Input}
                label='Заголовок письма'
                getTranslationPath={lang => `translations.${lang}.subject`}
                translationsWithoutRewrite
                translations />
            <Field
                name='content'
                component={VariablesInput}
                label='Текст письма'
                variables={PROJECT_LETTER_TEMPLATE_VARIABLES}
                getTranslationPath={lang => `translations.${lang}.content`}
                translationsWithoutRewrite
                translations />
            <SubmitButton>Отправить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    type: yup.string().required(),
    subject: yup.string().required(),
    content: yup.string().matches(/\[TestLink\]/, 'Необходимо в текст письма добавить ссылку на прохождение теста').required(),
    respondents: yup.array().when('type', (type, schema) => type === 'selected' ? schema.min(1, 'Необходимо выбрать респондентов') : schema)
});

export default withState('search', 'setSearch', '')(
    withAsyncActions({
        getRespondents: getRespondents
            .withParams(() => ({ type: 'modal' }))
            .withPayload(({ item, search }) => ({
                q: {
                    project: item.project,
                    name: search
                },
                offset: 0,
                limit: LINES_PER_PAGE
            }))
            .withOptions({ resetOnUnmount: true, dispatchOnUpdate: true })
    })(
        withFormWrapper(SendRespondentLetterForm, {
            mapPropsToValues: prop('item'),
            validationSchema,
            mapBeforeSubmit: values => ({
                ...values,
                respondents: (values.respondents || []).length ? values.respondents.map(({ id }) => id) : null
            })
        })
    )
);
