import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import HighchartsReact from 'highcharts-react-official';
import { toPairs, pathOr, fromPairs, path, is } from 'ramda';
import Highcharts from 'highcharts';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { getReportBlockTotal } from '../../../../actions/asyncActions';
import withReportFilters from '../../../hocs/withReportFilters';
import CenterSpin from '../../../CenterSpin';
import { getSortedData } from '../../../../utils/charts';
import { TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';
import withReportWrapper from '../../../hocs/withReportWrapper';
import withChartPngDownload from '../../../hocs/withChartPngDownload';

export const Sorter = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    left: calc(50% - 160px);
    width: 320px;
    top: 4px;
    z-index: 1;
    background: transparent;
    border: none;
    font-size: 13px;
    &:focus {
        outline: none;
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

export class ReportBlockTotalChartComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCommon: !!props.showCommon,
            commonColumn: props.commonColumn,
            sort: null
        }
    }

    getSeries = data => {
        const { reportFilter, staticData, commonData } = this.props;
        const value = path(['value'], reportFilter);
        const series = value && value.length ?
            value.map((item, index) => ({
                name: item,
                data: toPairs((staticData && (is(Array, staticData) ? staticData[index] : staticData)) || this.props[`getReportBlockTotal${index}`].data).map(([_, amount]) => amount)
            })) :
            [{
                name: '2019',
                data: data.map(([_, amount]) => amount),
                color: '#EE492F'
            }];

        return value && value.length && this.state.showCommon ? series.concat({
            name: 'Общие результаты',
            type: this.state.commonColumn ? 'column' : 'spline',
            color: '#ed0214',
            data: (commonData ? toPairs(commonData) : data).map(([_, amount]) => amount)
        }) : series;
    }

    getData = () => {
        const { sort } = this.state;
        const data = toPairs(this.props.staticData || this.props.getReportBlockTotal.data);

        return getSortedData(data, sort);
    }

    getOptions = () => {
        const { scale, reportFilter, width, fontFamily, title, pdf, hideScale, titleColor, titleSize, two, three, config } = this.props;
        const data = this.getData();
        const value = path(['value'], reportFilter);
        const series = this.getSeries(data);

        return {
            chart: {
                type: 'column',
                width,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                },
                events: path(['chartEvents'], config)
            },
            title: {
                text: title,
                style: pdf ? {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1)
                } : {}
            },
            legend: {
                enabled: value && value.length,
                symbolHeight: 11,
                symbolWidth: 11,
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: 'normal'
                },
                ...pathOr({}, ['legend'], config)
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                spline: {
                    marker: {
                        symbol: 'circle',
                        fillColor: '#ed0214'
                    }
                }
            },
            xAxis: {
                categories: data.map(([ category ]) => category)
            },
            yAxis: {
                ...(hideScale ? ({}) : scale ? ({
                    min: scale.min,
                    max: scale.max
                }) : ({
                    min: 0,
                    max: 100
                })),
                title: {
                    text: null
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}%</b>'
            },
            series
        };
    }

    onToggleShowCommon = e => this.setState({ showCommon: e.target.checked });

    sort = () => {
        this.setState(prev => ({
            sort: !prev.sort ? 'asc' : prev.sort === 'asc' ? 'desc' : null
        }));
    }

    toggleCommonColumn = e => this.setState({ commonColumn: e.target.checked });

    render() {
        const { getReportBlockTotal, staticData } = this.props;
        const value = path(['value'], this.props.reportFilter);
        const { sort } = this.state;

        return !staticData && getReportBlockTotal.meta.pending && !getReportBlockTotal.meta.lastSucceedAt ?
            <CenterSpin /> :
            <Wrapper>
                { !this.props.pdf && (!value || (value && !value.length)) &&
                    <Sorter onClick={this.sort}>
                        <CaretUpOutlined style={{ color: sort === 'asc' ? '#1890ff' : null }} />
                        <CaretDownOutlined style={{ color: sort === 'desc' ? '#1890ff' : null }} />
                    </Sorter>
                }
                <HighchartsReact
                    ref={this.props.getRef}
                    highcharts={Highcharts}
                    options={this.getOptions()} />
                { !this.props.pdf && value && value.length && !staticData &&
                    <div style={{ textAlign: 'center', paddingBottom: 15 }}>
                        <Checkbox checked={this.state.showCommon} onChange={this.onToggleShowCommon}>
                            Вывести общие результаты
                        </Checkbox>
                        { this.state.showCommon &&
                            <Checkbox checked={this.state.commonColumn} onChange={this.toggleCommonColumn}>
                                Вывести общие колонкой
                            </Checkbox>
                        }
                    </div>
                }
            </Wrapper>;
    }
}

export const ReportBlockTotalChart = withReportWrapper(withChartPngDownload(ReportBlockTotalChartComponent));

export default withReportFilters(withAsyncActions(props => {
    const values = pathOr([], ['reportFilter', 'value'], props);
    const actions = values.length ?
        fromPairs(values.map((value, index) => ([
            `getReportBlockTotal${index}`,
            getReportBlockTotal
                .withParams(() => ({ property: props.reportFilter.property, value }))
                .withPayload(({ id, reportFilter }) => ({
                    id,
                    q: { property: reportFilter.property, value }
                }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
        ]))) : {};

    return {
        getReportBlockTotal: getReportBlockTotal
            .withParams(({ params }) => params)
            .withPayload(({ id, reportFilter }) => ({
                id,
                q: { property: reportFilter.property }
            }))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        ...actions
    }
})(ReportBlockTotalChart), { multiple: true });
