import React from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';

import InputSearch from '../formComponents/InputSearch';

const BaseFilter = ({ searchFieldName, children, searchFieldPlaceholder, action, namePath, span = 8, smallPath, component, allowClear }) => (
    <Row key='header'>
        <Col>
            <Row gutter={5}>
                <Col span={span}>
                    <Field
                        action={action}
                        name={searchFieldName}
                        namePath={namePath}
                        smallPath={smallPath}
                        placeholder={searchFieldPlaceholder}
                        component={component || InputSearch}
                        allowClear={allowClear}
                    />
                </Col>
                { children || null }
            </Row>
        </Col>
    </Row>
);

export default BaseFilter;
