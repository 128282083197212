import React, { Component } from 'react';
import { withAsyncActions, withSagas, toSuccess } from 'react-async-client';
import { Progress, Alert, Popconfirm } from 'antd';
import { append, forEach, addIndex, contains, pathOr, fromPairs, head, map, mergeAll, flatten, concat, toPairs, take, path, filter, keys, unnest, drop, without, isNil, find, dropLast, propEq, any, values, is, last, uniqBy, prop, uniq } from 'ramda';
import { withStateHandlers } from 'recompose';
import { PDFDocument } from 'pdf-lib';
import { takeEvery } from 'redux-saga/effects';
import Twig from 'twig';
import { CloseOutlined } from '@ant-design/icons';
import { asyncConnect } from 'react-async-client/lib/asyncConnect';

import Modal from './Modal';
import { getReportBlockTotal, getDriverImportance, getReportDriverTotal, getReportBlockDistribution, getReportDriverDistribution, getFileDownload, getReportTable, getReportFilters, getReportQuestionsDistribution } from '../../actions/asyncActions';
import { ReportBlockTotalChartComponent } from '../user/projects/reports/ReportBlockTotal';
import { ReportBlockDistributionChartComponent } from '../user/projects/reports/ReportBlockDistribution';
import { ReportDriverTotalChartComponent } from '../user/projects/reports/ReportDriverTotal';
import { ReportDriverDistributionChartComponent } from '../user/projects/reports/ReportDriverDistribution';
import { ReportFilterChart } from '../user/projects/reports/ReportFilters';
import { FONTS_DEFINITIONS, REPORT_TYPES } from '../../constants/charts';
import { GET_REPORT_BLOCK_TOTAL, GET_REPORT_BLOCK_DISTRIBUTION, GET_REPORT_DRIVER_TOTAL, GET_REPORT_DRIVER_DISTRIBUTION, GET_REPORT_TABLE, GET_REPORT_FILTERS, GET_REPORT_QUESTIONS_DISTRIBUTION } from '../../constants/actionTypes';
import { getTableBackgroundScaleColor, getScale } from '../../utils/report';
import ReportQuestionsDistribution from '../user/projects/reports/ReportQuestionsDistribution';
import ReportDriverDistributionPieChart from '../user/projects/reports/ReportDriverDistributionPieChart';
import { filterQuestionsDistribution } from '../../utils/charts';

window.pdfMake = require('pdfmake/build/pdfmake.min');
const robotoVft = require('pdfmake/build/vfs_fonts');
const geometriaVfs = require('../../assets/fonts/vfs_fonts');

const vfs = {
    Roboto: robotoVft.pdfMake.vfs,
    Geometria: geometriaVfs.pdfMake.vfs
};

const CHARTS = {
    'block': {
        action: 'getReportBlockTotal', actionFn: getReportBlockTotal, actionType: GET_REPORT_BLOCK_TOTAL, component: ReportBlockTotalChartComponent
    },
    'blocks-distribution': {
        action: 'getReportBlockDistribution', actionFn: getReportBlockDistribution, actionType: GET_REPORT_BLOCK_DISTRIBUTION, component: ReportBlockDistributionChartComponent
    },
    'drivers': {
        action: 'getReportDriverTotal', actionFn: getReportDriverTotal, actionType: GET_REPORT_DRIVER_TOTAL, component: ReportDriverTotalChartComponent,
        subAction: 'getDriverImportance', subActionFn: getDriverImportance, subActionType: getDriverImportance.type,
    },
    'drivers-extended': {
        action: 'getReportDriverTotal', actionFn: getReportDriverTotal, actionType: GET_REPORT_DRIVER_TOTAL, component: ReportDriverTotalChartComponent,
        subAction: 'getDriverImportance', subActionFn: getDriverImportance, subActionType: getDriverImportance.type,
    },
    'drivers-distribution': {
        action: 'getReportDriverDistribution', actionFn: getReportDriverDistribution, actionType: GET_REPORT_DRIVER_DISTRIBUTION, component: ReportDriverDistributionChartComponent
    },
    'filters': {
        action: 'getReportFiltersWithFilter', actionFn: getReportFilters, actionType: GET_REPORT_FILTERS, component: ReportFilterChart
    },
    'dynamic': {
        action: 'getReportTable', actionFn: getReportTable, actionType: GET_REPORT_TABLE
    },
    'questions-distribution': {
        action: 'getReportQuestionsDistribution', actionFn: getReportQuestionsDistribution, actionType: GET_REPORT_QUESTIONS_DISTRIBUTION, component: ReportQuestionsDistribution,
    },
    'questions-distribution-table': {
        action: 'getReportQuestionsDistribution', actionFn: getReportQuestionsDistribution, actionType: GET_REPORT_QUESTIONS_DISTRIBUTION
    },
    'questions-distribution-driver-item': {
        action: 'getReportDriverDistribution', actionFn: getReportDriverDistribution, actionType: GET_REPORT_DRIVER_DISTRIBUTION, component: ReportDriverDistributionPieChart,
        subAction: 'getReportQuestionsDistribution', subActionFn: getReportQuestionsDistribution, subActionType: getReportQuestionsDistribution.type,
    }
};

const MIN_PEOPLE_AMOUNT = 5;

class PdfChartComponent extends Component {
    componentDidMount() {
        if (contains(this.props.chartData.type, ['dynamic', 'questions-distribution-table'])) {
            this.props.getRef(this);
        }

        this.props.getComponentRef(this);
    }

    render() {
        const { component: Chart, loaded, ...props } = this.props;

        return loaded && !contains(props.chartData.type, ['dynamic', 'questions-distribution-table']) ? (
            <Chart
                {...props}
                reportFilter={props.chartData.type === 'filters' ? { property: props.chartData.sliceByProperty, value: props.chartData.sliceValues } : { property: props.chartData.property, value: props.chartData.value }}
                showCommon={props.chartData.showCommon || props.showCommon}
                commonColumn={props.chartData.commonColumn || props.commonColumn}
                pdf />
        ) : null;
    }
}

const getReportFiltersSlicesName = data => data.reduce((res, cur, i) => res + `${i ? '_' : ''}${cur.property}_${cur.value}`, '');

const PdfChart = withAsyncActions(props => {
    if (props.chartData.type === 'filters' && !props.chartData.sliceValues) {
        return {};
    }

    const isTotalChart = contains(props.chartData.type, ['block', 'drivers', 'drivers-extended']);
    const values = pathOr([], ['chartData', 'value'], props);

    const actions = isTotalChart && values.length ?
        fromPairs(values.map((value, index) => ([
            `${CHARTS[props.chartData.type].action}${index}`,
            CHARTS[props.chartData.type].actionFn
                .withParams(() => props.slices ? { slices: getReportFiltersSlicesName(props.slices) } : props.chartData.type === 'filters' ?
                    ({ property: props.chartData.sliceByProperty, sliceValues: props.chartData.sliceValues }) :
                    ({ property: props.chartData.property, value })
                )
                .withPayload(({ id, chartData }) => ({
                    id,
                    q: props.slices ? { slices: getReportFiltersSlicesName(props.slices) } : chartData.type === 'filters' ?
                        { property: chartData.sliceByProperty, sliceValues: chartData.sliceValues } :
                        { property: chartData.property, value }
                }))
                .withErrorHandler(({ setError }) => setError(true))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
        ]))) : {};

    return {
        [CHARTS[props.chartData.type].action]: CHARTS[props.chartData.type].actionFn
            .withParams(() => (props.slices ? { slices: getReportFiltersSlicesName(props.slices) } : { property: props.chartData.property, value: isTotalChart ? null : props.chartData.value }))
            .withPayload(({ id, chartData }) => ({
                id,
                q: props.slices ? { slices: props.slices } : { property: chartData.property, value: isTotalChart ? null : props.chartData.value }
            }))
            .withErrorHandler(({ setError }) => setError(true))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
        ...actions,
        ...(CHARTS[props.chartData.type].subAction ? {
            [CHARTS[props.chartData.type].subAction]: CHARTS[props.chartData.type].subActionFn
                .withParams(() => (props.slices ? { slices: getReportFiltersSlicesName(props.slices) } : { property: props.chartData.property, value: props.chartData.propertyValue || head(props.chartData.value || []) }))
                .withPayload(({ id, chartData }) => ({
                    id,
                    q: props.slices ? { slices: props.slices } : props.chartData.value ? { property: chartData.property, value: props.chartData.propertyValue || head(props.chartData.value) } : undefined
                }))
                .withErrorHandler(({ setError }) => setError(true))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
        } : {})
    };
})(
    withSagas([function* (getProps) {
        const props = getProps();
        const values = pathOr([], ['chartData', 'value'], props);
        const loadAmount = (contains(props.chartData.type, ['block', 'drivers', 'drivers-extended']) ? values.length : 0)
            + (CHARTS[props.chartData.type].subAction ? 2 : 1);
        let loaded = 0;

        yield takeEvery([
            toSuccess(CHARTS[props.chartData.type].actionType),
            toSuccess(CHARTS[props.chartData.type].subActionType || '')
        ], function() {
            loaded = loaded + 1;

            if (loaded === loadAmount) {
                props.setLoaded(true);
            }
        });
    }])(PdfChartComponent)
);

const getSegmentPage = (props, item, slices, segmentId) => {
    if (item.type === 'questions-distribution-driver') {
        return toPairs(props.params.getReportQuestionsDistribution.data || {}).map(([title, data]) => ({
            ...item,
            slices,
            subtitle: path(['value'], last(slices)),
            title: path(['property'], last(slices)),
            segmentId,
            charts: [{
                type: 'questions-distribution-driver-item',
                title,
                property: title,
                data
            }]
        }));
    } else {
        return {
            ...item,
            slices,
            segmentId,
            subtitle: path(['value'], last(slices)),
            title: path(['property'], last(slices))
        };
    }
}

const getSegmentData = (props, page, sl, id) => {
    const { value, property, data } = page;
    let pages = [];

    forEach(v => {
        const slices = [...sl, { property, value: v }];

        addIndex(forEach)((p, i) => {
            if (p.type === 'segment') {
                if (!isNil(props.params.page) || !page.hide) {
                    const datas = getSegmentData(props, p, slices, `${id}-${i}`);

                    pages = append(datas, pages);
                }
            } else {
                pages = append(getSegmentPage(props, p, slices, `${id}-${i}`), pages)
            }
        }, data);
    }, value);

    return flatten(pages);
}

const getData = (props, reportData) => {
    let data = [];
    const isSinglePage = !isNil(props.params.page);

    addIndex(forEach)((item, index) => {
        if (isSinglePage || !item.hide) {
            if (item.type === 'segment') {
                const pages = flatten(item.value.map(value => ((item.template ? pathOr([], ['charts'], find(propEq('id', item.template), pathOr([], ['report', 'templates'], props.params.project))) : item.data) || []).map(page =>{
                    return page.type === 'segment' ? ((!isSinglePage && page.hide) ? [] : getSegmentData(props, page, [{ property: item.property, value }], index)) :
                        page.type === 'questions-distribution-driver' ? toPairs(props.params.getReportQuestionsDistribution.data || {}).map(([title, data]) => ({
                            ...page,
                            title: item.propertyReportView || item.property,
                            subtitle: value,
                            property: item.property,
                            value: value,
                            elementIndex: index,
                            segmentId: String(index),
                            charts: [{
                                type: 'questions-distribution-driver-item',
                                title,
                                property: item.property,
                                value
                            }]
                        })) : {
                            ...page,
                            value,
                            property: item.property,
                            title: item.propertyReportView || item.property,
                            elementIndex: index,
                            subtitle: value,
                            segmentId: String(index)
                        }
                    })));

                data = concat(data, pages);
            } else if (item.type === 'questions-distribution-driver') {
                const pages = toPairs(props.params.getReportQuestionsDistribution.data || {}).map(([title, data]) => ({
                    ...item,
                    elementIndex: index,
                    charts: [{
                        type: 'questions-distribution-driver-item',
                        title,
                        property: title,
                        data
                    }]
                }));

                data = concat(data, pages);
            } else {
                data = append({ ...item, elementIndex: index }, data);
            }
        }
    }, reportData || (!isNil(props.params.page) ? [props.params.project.report.data[props.params.page]] : props.params.project.report.data));

    return filter(item => item && (isSinglePage || !item.hide), data);
}

class DownloadPdfReportModal extends Component {
    pdfs = [];
    charts = {};
    filters = {};
    chartComponent = {};
    fileReader = new FileReader();
    state = {
        data: []
    };

    componentDidMount() {
        this.filters = map(items => fromPairs(map(item => ([ item._id, item.count ]), items)), this.props.params.getReportFilters.data);
        this.scale = getScale(this.props.params.project.answerMap);

        if (this.props.slicesFiltersLoaded) {
            this.setData();
        }
    }

    componentDidUpdate(props) {
        if (this.props.slicesFiltersLoaded && !props.slicesFiltersLoaded) {
            this.setData();
            return;
        }

        if (this.props.dataLoaded && !props.dataLoaded) {
            this.downloadSlide();
            return;
        }

        const { current, getFileDownload } = this.props;
        const slide = this.state.data[current];

        if (this.props.slicesFiltersLoaded && this.state.data.length) {
            if (props.current !== this.props.current) {
                this.downloadSlide();
                return;
            }

            if (!props.loadedFile && this.props.loadedFile && slide.type === 'file') {
                const blob = new Blob([getFileDownload.data], { type: 'application/pdf' });
                this.fileReader.readAsDataURL(blob);
                this.fileReader.onload = () => {
                    this.pdfs = append(this.fileReader.result, this.pdfs);
                    this.goNext();
                }

                return;
            }

            if (!props.loadedFirstChart && this.props.loadedFirstChart && contains(slide.type, ['one', 'questions-distribution-driver'])) {
                setTimeout(() => this.generateChartPdf(slide));
                return;
            }

            if ((!props.loadedFirstChart || !props.loadedSecondChart) && this.props.loadedFirstChart && this.props.loadedSecondChart && slide.type === 'two') {
                setTimeout(() => this.generateChartPdf(slide));
                return;
            }

            if ((!props.loadedFirstChart || !props.loadedSecondChart || !props.loadedThirdChart) &&
                this.props.loadedFirstChart && this.props.loadedSecondChart && this.props.loadedThirdChart && slide.type === 'three'
            ) {
                setTimeout(() => this.generateChartPdf(slide));
                return;
            }
        }
    }

    setData = () => {
        const data = filter(item => {
            const value = item.slices ? path(['value'], last(item.slices)) : item.value;
            const property = item.slices ? path(['property'], last(item.slices)) : item.property;
            const filters = dropLast(1, item.slices || []).length ? map(items => fromPairs(map(item => ([ item._id, item.count ]), items)), this.props[`slicesFilters_${getReportFiltersSlicesName(dropLast(1, item.slices))}`].data) : this.filters;

            return !(value && property && pathOr(0, [property, value], filters) < MIN_PEOPLE_AMOUNT);
        }, getData(this.props));

        this.setState({ data });
        this.props.setDataLoaded(true);
    }

    downloadSlide = () => {
        const { current, getFileDownload } = this.props;
        const slide = this.state.data[current];

        switch (slide.type) {
            case 'file':
                getFileDownload.dispatch(slide.file.id);
                break;
            case 'one':
            case 'two':
            case 'three':
            case 'questions-distribution-driver':
                addIndex(forEach)((chart, index) => {
                    if (chart.type === 'filters' && !chart.sliceValues) {
                        index === 2 ? this.props.setLoadedThirdChart(true) : index === 1 ? this.props.setLoadedSecondChart(true) : this.props.setLoadedFirstChart(true);
                    }
                }, slide.charts)
                break;
            case 'segment':
                this.generateChartPdf(slide);
                break;
            default:
                return;
        }
    }

    goNext = () => {
        this.charts = {};

        if (this.props.current + 1 < this.state.data.length) {
            this.props.setCurrent(this.props.current + 1);
        } else {
            this.createPDFFile();
        }
    }

    getFooter = item => {
        if (!item.footer) {
            return null;
        }

        const footerTemplate = Twig.twig({
            data: item.footer.replace(/{{segmentData}}/gi, `{{filters['${item.property}']['${item.value}']}}`)
        });

        return footerTemplate.render({
            filters: this.filters,
            statuses: this.props.params.getRespondentsStatuses.data
        });
    }

    getTableData = item => {
        const MIN_COUNT_HEADER = 5;
        const { report } = this.props.params.project;
        const filters = mergeAll(map(({ _id, count }) => ({ [_id]: count || 0 }), this.props.params.getReportFilters.data[item.property]));
        const data = this.charts[0].props.getReportTable.data;
        let headers = item.columns ?
            filter(i => is(String, i) ? true : i.show, (item.columns.map(i => is(String, i) ? { name: i, originalName: i, show: true } : i))) :
            without(['total'], take(6, toPairs(pathOr({}, [0, 1], toPairs(data))).map(([ name ]) => name)));
        headers = filter(i => filters[i.originalName] ? filters[i.originalName] > MIN_COUNT_HEADER : i, headers);
        headers = filter(h => any(([_, i]) => (i[h.originalName] || 0) > 0, toPairs(data)), item.commonResult ? [{ originalName: 'total' }, ...headers] : headers);
        const fontSize = item.fontSize || 12;
        const rows = toPairs(data).map(([ name ]) => name).map((text, index) => ([
            { text, bold: item.bold, fontSize, fillColor: item.colorized ? null : (index % 2 === 0 ? report.tableEvenColor : report.tableOddColor) },
            ...headers.map(({ originalName }) => ({
                text: data[text][originalName] ? Math.min(this.scale.max, data[text][originalName]).toFixed(item.numberAmount || 1) : 0,
                alignment: 'center',
                bold: item.bold,
                margin: 2,
                fontSize,
                fillColor: item.colorized ? getTableBackgroundScaleColor(data[text][originalName], this.scale) : (index % 2 === 0 ? report.tableEvenColor : report.tableOddColor)
            }))
        ]));

        return {
            layout: {
                hLineColor: '#fff',
                vLineColor: '#fff',
                hLineWidth: () => fontSize < 8 ? 1 : 2,
                vLineWidth: () => fontSize < 8 ? 1 : 2,
                paddingLeft: () => fontSize < 8 ? 2 : 5,
                paddingRight: () => fontSize < 8 ? 2 : 5
            },
            table: {
                headerRows: 1,
                widths: [ 'auto', ...headers.map(() => '*') ],
                body: [
                    [
                        { text: 'Параметр', bold: item.bold, fontSize, fillColor: report.tableHeaderColor, color: report.tableTitlesColor },
                        ...headers.map(({ originalName, name }) => ({ text: originalName === 'total' ? 'КМП по компании' : name, bold: item.bold, alignment: 'center', fontSize, fillColor: report.tableHeaderColor, color: report.tableTitlesColor }))
                    ],
                    ...rows
                ]
            }
        };
    }

    getQuestionsDistributionTableRows = (data, item) => {
        const { report } = this.props.params.project;
        const headers = keys(pathOr({}, [0, 1, 0, 1], data));
        const fontSize = item.fontSize || 12;
        const sum = item.sum || [];
        let rows = unnest(data.map(([ title, items ]) =>  item.driverLeft ?
            items.map(([ text, stat ]) => ([
                false ? { text: '' } : { text: title, fontSize, rowSpan: items.length },
                { text, fontSize },
                ...headers.map(key => ({
                    text: item.absolute ? pathOr(0, [key, 'count'], stat) : `${pathOr(0, [key, 'percent'], stat)}%`,
                    value: item.absolute ? pathOr(0, [key, 'count'], stat) : pathOr(0, [key, 'percent'], stat),
                    driver: key,
                    fontSize,
                    alignment: 'center'
                }))
            ])) : ([
            [{ header: true, text: title, bold: true, margin: [0, 10], fontSize, colSpan: headers.length + 1 + sum.length }, ...headers.map(() => ({ text: '' }))],
            ...items.map(([ text, stat ]) => ([
                { text, fontSize },
                ...headers.map(key => ({
                    text: item.absolute ? pathOr(0, [key, 'count'], stat) : `${pathOr(0, [key, 'percent'], stat)}%`,
                    value: item.absolute ? pathOr(0, [key, 'count'], stat) : pathOr(0, [key, 'percent'], stat),
                    fontSize,
                    driver: key,
                    alignment: 'center'
                }))
            ]))
        ])));

        forEach(s => {
            rows = rows.map(row => {
                const text = (s.groups || []).reduce((res, cur) => res + pathOr(0, ['value'], find(propEq('driver', cur), row)), 0);

                return append(row[0].header ? { text: '' } : {
                    text: item.absolute ? text : `${text.toFixed(2)}%`,
                    fontSize,
                    alignment: 'center',
                    color: s.valueColor
                }, row)});
        }, sum);

        rows = rows.map((row, index) => row.map(item => ({ ...item, fillColor: index % 2 === 0 ? report.tableEvenColor : report.tableOddColor })));

        return rows;
    }

    getQuestionsDistributionTableData = item => {
        const { report } = this.props.params.project;
        const data = filter(([key]) => contains(key, item.drivers || []),
            toPairs(filterQuestionsDistribution(this.charts[0].props.getReportQuestionsDistribution.data)).map(([key, items]) => ([key, toPairs(items)])));
        const headers = keys(pathOr({}, [0, 1, 0, 1], data));
        const fontSize = item.fontSize || 12;
        const sum = item.sum || [];
        const rows = this.getQuestionsDistributionTableRows(data, item);

        return {
            layout: {
                hLineColor: '#fff',
                vLineColor: i => i === ((item.driverLeft ? 2 : 1) + headers.length) && sum.length ? '#000' : '#fff',
                hLineWidth: i => i === 0 ? 0 : 2,
                vLineWidth: () => 2,
                paddingLeft: i => (i < (item.driverLeft ? 2 : 1)) ? 5 : 0,
                paddingRight: i => (i < (item.driverLeft ? 2 : 1)) ? 5 : 0
            },
            table: {
                headerRows: 1,
                widths: [...(item.driverLeft ? [100, '*'] : ['*']), ...headers.map(() => 'auto'), ...sum.map(() => 'auto')],
                body: [
                    drop(item.driverLeft ? 0 : 1, [
                        {
                            text: 'Параметр',
                            fillColor: report.tableHeaderColor,
                            color: report.tableTitlesColor,
                            bold: true,
                            fontSize
                        },
                        {
                            text: 'Утверждение',
                            fillColor: report.tableHeaderColor,
                            color: report.tableTitlesColor,
                            bold: true,
                            fontSize
                        },
                        ...headers.map(text => ({ text, bold: true, alignment: 'center', fontSize, fillColor: report.tableHeaderColor, color: report.tableTitlesColor })),
                        ...sum.map(s => ({ text: s.title, bold: true, alignment: 'center', fontSize, fillColor: s.headerColor || report.tableHeaderColor, color: report.tableTitlesColor }))
                    ]),
                    ...rows
                ]
            }
        };
    }

    getDriverTotalExtendedColumns = item => {
        const { getReportDriverTotal, getReportDriverTotal0, getDriverImportance, title, titleColor, titleSize } = this.chartComponent[0].props;
        const { data } = getReportDriverTotal0 || getReportDriverTotal;
        const items = unnest(toPairs(data || {}).map(([_, items]) => toPairs(items)));
        const blueZone = filter(item => 50 > pathOr(0, [1], find(([title]) => title === item._id, data)), getDriverImportance.data).map(({ _id }) => _id);
        const redZone = filter(item => 50 <= pathOr(0, [1], find(([title]) => title === item._id, data)), getDriverImportance.data).map(({ _id }) => _id);
        const scale = filter(v => !isNil(v), values(this.props.params.project.answerMap || {}));
        const minScale = Math.min(...scale);
        const maxScale = Math.max(...scale);
        const middleScale = (minScale + maxScale) / 2;

        return [
            {
                text: title,
                color: titleColor || '#C20000',
                fontSize: titleSize || 18,
                margin: [0, 0, 0, 15],
                alignment: 'center'
            },
            {
                columnGap: 10,
                columns: [
                    [
                        {
                            svg: this.charts[0].chart.getSVG(),
                            width: 480,
                            alignment: 'center'
                        },
                        {
                            margin: [0, 0, 0, 5],
                            layout: {
                                hLineColor: '#fff',
                                vLineColor: '#fff',
                            },
                            table: {
                                widths: [20, '*'],
                                body: drop(item.property ? 0 : 1, dropLast(item.charts[0].value ? 0 : 1, [
                                    [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#D8E3EB" /></svg>', width: 20 },
                                        { text: 'Общий результат по компании', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ],
                                    [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#CF020D" /></svg>', width: 20 },
                                        { text: 'Важный и оцененный ниже 50% параметр', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ], [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#3B75BB" /></svg>', width: 20 },
                                        { text: 'Важный и оцененный выше 50% параметр', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ], [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#D8E3EB" /></svg>', width: 20 },
                                        { text: 'Значение параметра в среднем по компании', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ]
                                ]))
                            }
                        },
                        {
                            text: redZone.length ? [
                                { text: 'Важные параметры в зоне развития: ' },
                                { text: redZone.reduce((res, cur, index) => `${res}${index > 0 ? ', ' : ''}${cur}`, ''), color: '#CF020F' }
                            ] : ''
                        },
                        {
                            text: blueZone.length ? [
                                { text: 'Важные параметры в зоне благополучия: ' },
                                { text: blueZone.reduce((res, cur, index) => `${res}${index > 0 ? ', ' : ''}${cur}`, ''), color: '#3B75BB' }
                            ] : ''
                        }
                    ],
                    [
                        {
                            layout: {
                                hLineColor: '#fff',
                                vLineColor: '#fff',
                                hLineWidth: () => 2,
                                vLineWidth: () => 2,
                            },
                            table: {
                                headerRows: 1,
                                widths: [200, 60],
                                body: [
                                    [
                                        { text: 'Параметр', color: '#fff', bold: true, fillColor: '#263D4D', fontSize: 10 },
                                        { text: 'КМП', color: '#fff', bold: true, fillColor: '#263D4D', alignment: 'center', fontSize: 10 }
                                    ],
                                    ...items.map(([text, value], index) => ([
                                        { text, fillColor: index % 2 === 0 ? '#CDCED0' : '#E8E8E9', fontSize: 10 },
                                        { text: value, fontSize: 10, alignment: 'center', fillColor: value > (maxScale + middleScale) / 2 ? '#319527' : value <= (maxScale + middleScale) / 2 && value > middleScale ? '#F9FA4D' : value <= middleScale && value > (minScale + middleScale) / 2 ? '#EF6426' : '#ED051A' }
                                    ]))
                                ]
                            }
                        },
                        {
                            layout: {
                                hLineColor: '#fff',
                                vLineColor: '#fff',
                            },
                            table: {
                                widths: [20, '*'],
                                body: [
                                    [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#319527" /></svg>', width: 20 },
                                        { text: 'Оцененный выше 50% параметр (зона благополучия)', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ], [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#F9FA4D" /></svg>', width: 20 },
                                        { text: 'Оцененный от 0% до 50% параметр (зона развития)', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ], [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#EF6426" /></svg>', width: 20 },
                                        { text: 'Оцененный от -50% до 0% параметр (зона риска)', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ], [
                                        { svg: '<svg width="25" height="15"><rect width="25" height="15" fill="#ED051A" /></svg>', width: 20 },
                                        { text: 'Оцененный от -100% до 50% параметр (зона разрушения)', fontSize: 8, margin: [0, 2, 0, 0] }
                                    ]
                                ]
                            }
                        }
                    ]
                ]
            }
        ];
    }

    generateChartPdf = item => {
        const { report } = this.props.params.project;
        const font = item.font || this.props.params.project.report.font || 'Roboto';
        window.pdfMake.vfs = vfs[font];
        window.pdfMake.fonts = {
            [font]: FONTS_DEFINITIONS[font]
        };

        const footer = this.getFooter(item);
        const content = item.type === 'questions-distribution-driver' ? ({
            svg: this.charts[0].chart.getSVG(),
            alignment: 'center'
        }) : item.type === 'one' ? (item.charts[0].type === 'dynamic' ?
            this.getTableData(item.charts[0]) : item.charts[0].type === 'questions-distribution-table' ?
            this.getQuestionsDistributionTableData(item.charts[0]) : item.charts[0].type === 'drivers-extended' ?
            this.getDriverTotalExtendedColumns(item) : ({
                svg: this.charts[0].chart.getSVG(),
                alignment: 'center'
            })
        ) : item.type === 'two' ? ({
            alignment: 'center',
            columnGap: 10,
            columns: [
                {
                    svg: this.charts[0].chart.getSVG(),
                    width: 380,
                    alignment: 'center'
                },
                {
                    svg: this.charts[1].chart.getSVG(),
                    width: 380,
                    alignment: 'center'
                }
            ]
        }) : ({
            alignment: 'center',
            columnGap: 10,
            columns: [
                {
                    svg: this.charts[0].chart.getSVG(),
                    width: 250,
                    alignment: 'center'
                },
                {
                    svg: this.charts[1].chart.getSVG(),
                    width: 250,
                    alignment: 'center'
                },
                {
                    svg: this.charts[2].chart.getSVG(),
                    width: 250,
                    alignment: 'center'
                }
            ]
        });

        const docDefinition = {
            defaultStyle: {
                font
            },
            pageMargins: [ 40, 30, 40, item.footerImage ? 70 : 30 ],
            pageOrientation: 'landscape',
            content: item.title ? [
                {
                    text: (item.subtitle || path(['charts', 0, 'type'], item) === 'dynamic') ? [
                        { text: item.title },
                        { text: ` ${item.subtitle || path(['charts', 0, 'propertyName'], item) || path(['charts', 0, 'property'], item)}`, style: { color: '#FFAA8B' }}
                    ] : item.title,
                    style: {
                        bold: true,
                        fontSize: 32,
                        color: item.pageTitleColor || report.pageTitleColor || '#404040'
                    },
                    margin: report.stripSize ? [0, 0, 0, 2] : [0, 0, 0, 30],
                },
                (report.stripSize ? {
                    svg: `<svg width="100%" height="${report.stripSize}"><rect width="100%" height="${report.stripSize}" fill="${report.stripColor}" /></svg>`,
                    width: 760
                } : {}),
                ...(is(Array, content) ? content : [content])
            ] : content,
            header: report.logo ? {
                image: report.logo,
                fit: [150, 50],
                margin: 15,
                alignment: 'right'
            } : {},
            footer: item.footerImage ?
                { image: item.footerImage, alignment: 'center', fit: [750, 70] } :
                { text: footer, alignment: 'center', style: { color: '#ABABAB' }}
        };

        window.pdfMake.createPdf(docDefinition).getBuffer(data => {
            this.pdfs[this.props.current] = data;
            this.goNext();
        });
    }

    createPDFFile = async () => {
        const mergedPdf = await PDFDocument.create();
        const pdfs = filter(el => !!el, this.pdfs);

        for (const pdfFile of pdfs) {
            const pdf = await PDFDocument.load(pdfFile);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                copiedPages.forEach(page => {
                mergedPdf.addPage(page);
            });
        }

        const pdfUrl = URL.createObjectURL(
            new Blob([await mergedPdf.save()], { type: 'application/pdf' }),
        );

        if (this.props.params.openInNewWindow) {
            window.open(pdfUrl);
        } else {
            this.downloadBtn.href = pdfUrl;
            this.downloadBtn.click();
        }

        this.props.close();
    }

    renderData = () => {
        const { current, params: { project }, loadedFirstChart, loadedSecondChart, setLoadedFirstChart, setLoadedSecondChart, setLoadedThirdChart, params: { getReportFilters, getDriverImportance }, setError } = this.props;
        const slide = this.state.data[current];

        const fn = () => slide.charts.map((data, index) => {
            const Chart = CHARTS[data.type].component;
            const chartData = {
                ...data,
                property: data.property || slide.property,
                propertyValue: data.value,
                value: (data.value ? (data.type === 'drivers-extended' ? [data.value] : data.value) : null)
                    || (slide.value ? (contains(data.type, ['drivers', 'block', 'drivers-extended']) ? [slide.value] : slide.value) : null)
            };

            return <PdfChart
                key={`chart-${current}-${index}`}
                getRef={node => this.charts[index] = node}
                getComponentRef={node => this.chartComponent[index] = node}
                fontFamily={slide.font || project.report.font}
                component={Chart}
                loaded={index === 1 ? loadedSecondChart : loadedFirstChart}
                chartData={chartData}
                title={data.title}
                id={project.id}
                setLoaded={index === 2 ? setLoadedThirdChart : index === 1 ? setLoadedSecondChart : setLoadedFirstChart}
                scale={this.scale}
                settings={pathOr({}, ['settings', 'theme'], project)}
                titleColor={project.report.titleColor}
                titleSize={project.report.titleSize}
                footerImage={!!slide.footerImage}
                setError={setError}
                slices={slide.slices}
                {...(
                    data.type === 'questions-distribution-driver-item' ? {
                        data: data.data,
                    } : data.type === 'filters' ? {
                        data: getReportFilters.data[data.property || slide.property],
                        property: data.property || slide.property,
                        colors: data.colors || project.report.colors,
                        legendPosition: data.legendPosition,
                        columns: data.columns,
                        two: slide.type === 'two',
                        three: slide.type === 'three',
                        absolute: data.absolute,
                        legendFontSize: data.legendFontSize,
                        defaultLegendName: data.defaultLegendName
                    } : data.type === 'drivers-extended' ? {
                        // getDriverImportance, // move to filtered for segment chart
                        vertical: true,
                        grouped: false,
                        showCommon: true,
                        commonColumn: true,
                        extended: true,
                        hideTitle: true
                    } : {
                        getDriverImportance,
                        width: slide.charts.length === 1 ? 800 : null,
                        grouped: data.grouped || false,
                        vertical: data.vertical,
                        drivers: data.drivers,
                        absolute: data.absolute,
                        absoluteMin: data.absoluteMin,
                        percentMin: data.percentMin,
                        percent: data.percent,
                        hideLegend: data.hideLegend
                    }
                )} />;
        });

        return <div style={{ display: 'none' }}>
            { contains(slide.type, ['one', 'two', 'three', 'questions-distribution-driver']) && fn() }
        </div>;
    }

    renderChartName = chart => {
        const title = chart.type === 'filters' ? `Целевые аудитории (${chart.property})` : path(['value'], find(propEq('id', chart.type), REPORT_TYPES));

        return <span> { title } {
            contains(chart.type, ['questions-distribution', 'questions-distribution-table']) && chart.drivers && chart.drivers.length ? <span>({ chart.drivers.reduce((res, cur, index) => `${res}${index > 0 ? ', ' : ''}${cur}`, '') })</span> :
            chart.type !== 'filters' && chart.property && <span> ({ chart.property }{ chart.value ? `, ${chart.value}` : '' })</span>
        }</span>;
    }

    getPageName = page => {
        switch (page.type) {
            case 'file':
                return `файл (${page.file.name})`;
            case 'one':
                return this.renderChartName(page.charts[0]);
            case 'two':
                return `${this.renderChartName(page.charts[0])}, ${this.renderChartBlock(page.charts[1])}`;
            case 'three':
                return `${this.renderChartName(page.charts[0])}, ${this.renderChartBlock(page.charts[1])}, ${this.renderChartBlock(page.charts[2])}`;
            case 'questions-distribution-driver':
                return 'Распределение ответов по вопросам (по драйверам)';
            default:
                return '';
        }
    }

    render() {
        const { modal, current, error, close } = this.props;
        const percent = current * 100 / this.state.data.length;

        return <Modal
            {...modal}
            title='Загрузка отчета'
            footer={null}
            maskClosable={false}
            closeIcon={<Popconfirm
                title='Вы уверены, что хотите закрыть окно загрузки отчета?'
                okText='Да'
                cancelText='Нет'
                onConfirm={close}
                placement='left'>
                <div>
                    <CloseOutlined />
                </div>
            </Popconfirm>}
            onCancel={null}>
            { error && <Alert message={`Ошибка загрузки слайда №${current + 1} - ${this.getPageName(this.state.data[current])} (в настройках элемент №${this.state.data[current].elementIndex + 1})`} type='error' style={{ marginBottom: 10 }} /> }
            <Progress percent={Number(Number.isInteger(percent) ? percent : percent.toFixed(1))} status={error ? 'exception' : 'active'} />
            { this.props.dataLoaded && this.renderData() }
            <a href='pdf' ref={node => this.downloadBtn = node} download style={{ display: 'none' }}>pdf</a>
        </Modal>;
    }
}

export default withStateHandlers(props => ({
    current: 0,
    loadedFirstChart: false,
    loadedSecondChart: false,
    loadedThirdChart: false,
    loadedFile: false,
    error: false,
    slicesFiltersLoaded: !filter(item => !!item.slices, getData(props)).length,
    dataLoaded: false
}), {
    setCurrent: () => current => ({
        current,
        loadedFirstChart: false,
        loadedSecondChart: false,
        loadedFile: false
    }),
    setLoadedFirstChart: () => loadedFirstChart => ({ loadedFirstChart }),
    setLoadedSecondChart: () => loadedSecondChart => ({ loadedSecondChart }),
    setLoadedThirdChart: () => loadedThirdChart => ({ loadedThirdChart }),
    setLoadedFile: () => loadedFile => ({ loadedFile }),
    setError: () => error => ({ error }),
    setSlicesFiltersLoaded: () => slicesFiltersLoaded => ({ slicesFiltersLoaded }),
    setDataLoaded: () => dataLoaded => ({ dataLoaded })
})(
    asyncConnect(props => {
        const slices = uniqBy(prop('name'), filter(item => !!item.slices, getData(props)).map(({ slices }) => ({
            name: `slicesFilters_${getReportFiltersSlicesName(dropLast(1, slices))}`,
            slices: dropLast(1, slices)
        })));

        return {
            getFileDownload: getFileDownload
                .withErrorHandler(({ setError }) => setError(true))
                .withSuccessHandler(({ setLoadedFile }) => setLoadedFile(true))
                .withOptions({ resetOnUnmount: true }),
            ...fromPairs(slices.map(item => ([
                item.name,
                getReportFilters
                    .withParams(() => ({ slices: getReportFiltersSlicesName(item.slices) }))
                    .withPayload(({ params: { project: { id }}}) => ({
                        id,
                        q: { slices: item.slices }
                    }))
                    .withErrorHandler(({ setError }) => setError(true))
                    .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
                ])
            ))
        };
    })(
        withSagas([function* (getProps) {
            const props = getProps();
            const slices = uniq(filter(item => !!item.slices, getData(props)).map(({ slices }) => `slicesFilters_${getReportFiltersSlicesName(dropLast(1, slices))}`));
            const loadAmount = slices.length;
            let loaded = 0;

            yield takeEvery([toSuccess(GET_REPORT_FILTERS)], function() {
                loaded = loaded + 1;

                if (loaded === loadAmount) {
                    props.setSlicesFiltersLoaded(true);
                }
            });
        }])(DownloadPdfReportModal)
    )
);
