import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, pick } from 'ramda';

import { closeModal, hideModal, showModal, removeModal } from '../../actions/modalActions';

import * as modalActions from '../../constants/actionTypes';
import ProjectInfoModal from './ProjectInfoModal';
import ProjectLetterTemplateModal from './ProjectLetterTemplateModal';
import RespondentModal from './RespondentModal';
import SendRespondentLetterModal from './SendRespondentLetterModal';
import CreateProjectModal from './CreateProjectModal';
import GroupModal from './GroupModal';
import GroupVariablesModal from './GroupVariablesModal';
import DriverModal from './DriverModal';
import DriverSettingsModal from './DriverSettingsModal';
import CompanyModal from './CompanyModal';
import SendCompanyLetterModal from './SendCompanyLetterModal';
import QuestionnaireModal from './QuestionnaireModal';
import ProjectLanguagesModal from './ProjectLanguagesModal';
import ProjectPreviewModal from './ProjectPreviewModal';
import PdfChartModal from './PdfChartModal';
import ProjectPdfModal from './ProjectPdfModal';
import DownloadPdfReportModal from './DownloadPdfReportModal';
import ProjectPdfSettingsModal from './ProjectPdfSettingsModal';
import ProjectPageElementsModal from './ProjectPageElementsModal';
import ReportModal from './ReportModal';
import ReportPageModal from './ReportPageModal';
import ReportSettingsModal from './ReportSettingsModal';
import ReportChartModal from './ReportChartModal';
import ImportProjectModal from './ImportProjectModal';
import ImportQuestionsModal from './ImportQuestionsModal';
import ProjectResponseFieldFormModal from './ProjectResponseFieldFormModal';
import DefaultProjectLanguageModal from './DefaultProjectLanguageModal';
import ProjectPdfTemplatesModal from './ProjectPdfTemplatesModal';
import ProjectPdfTemplateModal from './ProjectPdfTemplateModal';
import ProjectPdfTemplatePageModal from './ProjectPdfTemplatePageModal';
import LanguagesSelectorModal from './LanguageSelectorModal';

const modalComponents = {
    [modalActions.OPEN_PROJECT_INFO_MODAL]: ProjectInfoModal,
    [modalActions.OPEN_PROJECT_LETTER_TEMPLATE_MODAL]: ProjectLetterTemplateModal,
    [modalActions.OPEN_RESPONDENT_MODAL]: RespondentModal,
    [modalActions.OPEN_SEND_RESPONDENT_LETTER_MODAL]: SendRespondentLetterModal,
    [modalActions.OPEN_CREATE_PROJECT_MODAL]: CreateProjectModal,
    [modalActions.OPEN_GROUP_MODAL]: GroupModal,
    [modalActions.OPEN_GROUP_VARIABLES_MODAL]: GroupVariablesModal,
    [modalActions.OPEN_DRIVER_MODAL]: DriverModal,
    [modalActions.OPEN_DRIVER_SETTINGS_MODAL]: DriverSettingsModal,
    [modalActions.OPEN_COMPANY_MODAL]: CompanyModal,
    [modalActions.OPEN_SEND_COMPANY_LETTER_MODAL]: SendCompanyLetterModal,
    [modalActions.OPEN_QUESTIONNAIRE_MODAL]: QuestionnaireModal,
    [modalActions.OPEN_PROJECT_LANGUAGES_MODAL]: ProjectLanguagesModal,
    [modalActions.OPEN_PROJECT_PREVIEW_MODAL]: ProjectPreviewModal,
    [modalActions.OPEN_PDF_CHART_MODAL]: PdfChartModal,
    [modalActions.OPEN_PROJECT_PDF_MODAL]: ProjectPdfModal,
    [modalActions.OPEN_DOWNLOAD_PDF_REPORT_MODAL]: DownloadPdfReportModal,
    [modalActions.OPEN_PROJECT_PDF_SETTINGS_MODAL]: ProjectPdfSettingsModal,
    [modalActions.OPEN_PROJECT_PAGE_ELEMENTS_MODAL]: ProjectPageElementsModal,
    [modalActions.OPEN_REPORT_MODAL]: ReportModal,
    [modalActions.OPEN_REPORT_PAGE_MODAL]: ReportPageModal,
    [modalActions.OPEN_REPORT_SETTINGS_MODAL]: ReportSettingsModal,
    [modalActions.OPEN_REPORT_CHART_MODAL]: ReportChartModal,
    [modalActions.OPEN_IMPORT_PROJECT_MODAL]: ImportProjectModal,
    [modalActions.OPEN_IMPORT_QUESTIONS_MODAL]: ImportQuestionsModal,
    [modalActions.OPEN_PROJECT_RESPONSE_FIELD_FORM_MODAL]: ProjectResponseFieldFormModal,
    [modalActions.OPEN_DEFAULT_PROJECT_LANGUAGE_MODAL]: DefaultProjectLanguageModal,
    [modalActions.OPEN_PROJECT_PDF_TEMPLATES_MODAL]: ProjectPdfTemplatesModal,
    [modalActions.OPEN_PROJECT_PDF_TEMPLATE_MODAL]: ProjectPdfTemplateModal,
    [modalActions.OPEN_PROJECT_PDF_TEMPLATE_PAGE_MODAL]: ProjectPdfTemplatePageModal,
    [modalActions.OPEN_LANGUAGES_SELECTOR_MODAL]: LanguagesSelectorModal
};

class Modals extends Component {
    getModalComponent = ({ type, params, visible, close }, index, length) => {
        const actions = map(action => action.bind(null, index), pick(['close', 'hide', 'show', 'remove'], this.props));
        const ModalComponent = modalComponents[type];

        const modalProps = {
            visible: index + 1 === length && visible && !close,
            width: 900,
            onCancel: actions.close,
            afterClose: () => close && actions.remove()
        };

        return <ModalComponent {...actions} key={index} index={index} modal={modalProps} params={params} />;
    }

    render() {
        const { modals } = this.props;

        return (
            <div>
                {modals.map((modal, index) => this.getModalComponent(modal, index, modals.length))}
            </div>
        );
    }
}

const stateToProps = pick(['modals']);

export default connect(stateToProps, {
    close: closeModal,
    hide: hideModal,
    show: showModal,
    remove: removeModal
})(Modals);
