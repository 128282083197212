import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { postCompany, putCompany } from '../../actions/asyncActions';
import CompanyForm from '../forms/CompanyForm';

class CompanyModal extends Component {
    render() {
        const { modal, params, postCompany, putCompany } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать компанию' : 'Добавить компанию'}
            footer={null}>
            <CompanyForm
                formAction={params ? putCompany : postCompany}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCompany: postCompany
        .withSuccessHandler(({ close }) => {
            message.success('Компания успешно добавлена');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить компанию'))
        .withOptions({ resetOnUnmount: true }),
    putCompany: putCompany
        .withSuccessHandler(({ close }) => {
            message.success('Компания успешно изменена');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить компанию'))
        .withOptions({ resetOnUnmount: true })
})(CompanyModal);
