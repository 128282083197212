import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { path, find, propEq, isNil, contains, any, filter, pathOr, toPairs, keys, forEach, merge, values } from 'ramda';
import { Select, Radio } from 'antd';
import qs from 'qs';
import { isMobile } from 'react-device-detect';

import { getPublicReports } from '../actions/asyncActions';
import CenterSpin from './CenterSpin';
import { PUBLIC_REPORT_TYPES } from '../constants/charts';
import { ReportBlockTotalChart } from './user/projects/reports/ReportBlockTotal';
import { ReportBlockDistributionChart } from './user/projects/reports/ReportBlockDistribution';
import { ReportDriverTotalChart } from './user/projects/reports/ReportDriverTotal';
import { ReportDriverDistributionChart } from './user/projects/reports/ReportDriverDistribution';
import { ReportFilterChart } from './user/projects/reports/ReportFilters';
import { ReportTableComponent } from './user/projects/reports/ReportTable';

const Container = styled.div`
    background: #fff;
    min-height: 100%;
    padding: 20px;
`;

const Wrapper = styled.div`
    max-width: 1000px;
    width: 100%;
    justify-content: center;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
    .ant-select {
        margin-top: 8px;
        padding-bottom: 5px;
        border-bottom: 1px dashed;
        border-bottom-width: medium;
        padding-bottom: 5px;
        .ant-select-selection {
            background: none;
            border: none;
            font-size: 30px;
            font-weight: 600;
            width: 100%;
            &:focus {
                box-shadow: none;
                border: none;
            }
        }
        .ant-select-selection--single .ant-select-selection__rendered {
            margin-left: 0;
            margin-right: 40px;
        }
        .ant-select-arrow {
            color: rgba(0, 0, 0, 0.65);
        }
    }
`;

const ResultTable = styled.div`
    width: 100vw;
    overflow-x: auto;
    table {
        border-collapse:separate;
        border-spacing: 3px;
    }
    th {
        background: #E8ECF4;
        font-weight: normal;
        font-size: 12px;
        min-width: 80px;
        vertical-align: bottom;
        padding-top: 10px;
        line-height: 14px;
        &:first-child {
            font-size: 14px;
            font-weight: 600;
        }
    }
    td {
        font-size: 12px;
        vertical-align: center;
        text-align: center;
        &:first-child {
            background: #E8ECF4;
            font-size: 14px;
            font-weight: 600;
            vertical-align: top;
            padding-top: 10px;
            text-align: left;
        }
    }
    @media (max-width: 765px) {
        table {
            width: 100%;
        }
        th {
            font-weight: 600;
            font-size: 14px;
        }
        td {
            &:first-child {
                font-weight: normal;
                font-size: 12px;
                text-align: left;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
`;

const CHARTS = {
    'block_total': ReportBlockTotalChart,
    'block_distribution': ReportBlockDistributionChart,
    'driver_total': ReportDriverTotalChart,
    'driver_distribution': ReportDriverDistributionChart,
    'report_filters': ReportFilterChart,
    'report_table': ReportTableComponent
}

class PublicReport extends Component {
    constructor(props) {
        super(props);

        const page = qs.parse(props.location.search, { ignoreQueryPrefix: true }).page;

        this.state = {
            page: page ? Number(page) : 1,
            chart: null
        };
    }

    onChangePage = page => {
        this.setState({ page, chart: null });
        this.props.history.replace(`/report/${this.props.match.params.id}/?page=${page}`)
    };

    onChangeChart = e => {
        this.setState({ chart: e.target.value });
    }

    getTableData = filter => {
        let data = {};

        forEach(obj => {
            data = merge(data, obj);
        }, toPairs(filter).map(([_, values]) => values));

        return data;
    }

    renderResultTable = dataFilters => {
        const { projectAnswerMap = {} } = this.props.getPublicReports.data;
        const headers = keys(pathOr({}, [0, 1], toPairs(pathOr({}, [0, 'data'], dataFilters))));
        const scale = filter(v => !isNil(v), values(projectAnswerMap));
        const minScale = Math.min(...scale);
        const maxScale = Math.max(...scale);
        const middleScale = (minScale + maxScale) / 2;

        return <ResultTable>
            { isMobile ?
                <table>
                    <thead>
                        <tr>
                            <th>Блок</th>
                            { dataFilters.map((col, index) =>
                                <th key={`th-${index}`}>{ col.filter.value || 'КМП по компании' }</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                    { headers.map((key, index) =>
                        <tr key={`tbody-tr-${index}`}>
                            <td>{ key }</td>
                            { dataFilters.map((row, i) => {
                                const data = this.getTableData(row.data);
                                const value = data[key] || 0;

                                return <td
                                    style={{ backgroundColor: value > (maxScale + middleScale) / 2 ? '#319527' : value <= (maxScale + middleScale) / 2 && value > middleScale ? '#F9FA4D' : value <= middleScale && value > (minScale + middleScale) / 2 ? '#EF6426' : '#ED051A' }}
                                    key={`td-${index}-${i}`}>
                                    { value }
                                </td>
                            })}
                        </tr>
                    )}
                    </tbody>
                </table> :
                <table>
                    <thead>
                        <tr>
                            <th>Блок</th>
                            { headers.map((title, index) => <th key={`th-${index}`}>{ title }</th>) }
                        </tr>
                    </thead>
                    <tbody>
                        { dataFilters.map((row, index) => {
                            const data = this.getTableData(row.data);

                            return <tr key={`tbody-tr-${index}`}>
                                <td>{ row.filter.value || 'КМП по компании' }</td>
                                { headers.map((key, i) => {
                                    const value = data[key] || 0;

                                    return <td
                                        style={{ backgroundColor: value > (maxScale + middleScale) / 2 ? '#319527' : value <= (maxScale + middleScale) / 2 && value > middleScale ? '#F9FA4D' : value <= middleScale && value > (minScale + middleScale) / 2 ? '#EF6426' : '#ED051A' }}
                                        key={`td-${index}-${i}`}>
                                        { value }
                                    </td>;
                                })}
                            </tr>;
                        })}
                    </tbody>
                </table>
            }
        </ResultTable>;
    }

    renderPage = (page, index) => {
        const { getPublicReports: { data }} = this.props;
        const selectedChart = page.charts.length ? (this.state.chart || 0) : undefined;
        const fontFamily = path(['settings', 'font'], page) || path(['settings', 'font'], data);

        return this.state.page - 1 === index &&
            <Page key={`page-${index}`} fontFamily={fontFamily}>
                <Wrapper>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        { data.pages.length > 1 ?
                            <Select
                                placeholder='График'
                                value={this.state.page}
                                onChange={this.onChangePage}>
                                { data.pages.map((item, index) =>
                                    <Select.Option key={`page-select-${index}`} value={index + 1}>
                                        { path(['settings', 'title'], item) }
                                    </Select.Option>
                                )}
                            </Select> :
                            <div style={{ fontSize: 30, fontWeight: 600 }}>
                                { path(['pages', 0, 'settings', 'title'], data) }
                            </div>
                        }
                        { path(['settings', 'logo'], data) &&
                            <img height={80} alt='logo' src={data.settings.logo} />
                        }
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: 15 }}>
                        <Radio.Group
                            buttonStyle='solid'
                            value={selectedChart}
                            onChange={this.onChangeChart}>
                            { page.charts.map((item, index) =>
                                <Radio.Button key={`chart-select-${index}`} value={index}>
                                    { path(['value'], find(propEq('id', path(['dataFilters', 0, 'type'], item)), PUBLIC_REPORT_TYPES)) }
                                </Radio.Button>
                            )}
                        </Radio.Group>
                    </div>
                </Wrapper>
                { path(['settings', 'title'], data) && <h1 style={{ textAlign: 'center' }}>{ data.settings.title }</h1> }
                <div style={{ marginBottom: 10, width: '100%' }}>
                    { isNil(selectedChart) ?
                        <div>График не выбран</div> :
                        <div>
                            { page.charts.map((item, index) => {
                                const type = find(({ id }) => any(propEq('type', id), item.dataFilters), PUBLIC_REPORT_TYPES).id;
                                const Chart = CHARTS[type];
                                const value = path(['dataFilters', 0, 'filter', 'value'], item);
                                const datas = filter(i => i.type === type && path(['filter', 'value'], i), item.dataFilters);
                                const settings = item.settings || {};
                                const withProperty = find(i => i.type === type && path(['filter', 'property'], i), item.dataFilters);

                                return index === selectedChart &&
                                    <div key={`chart-${index}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Wrapper>
                                            <Chart
                                                staticData={type === 'report_filters' ?
                                                    path(['data', withProperty.filter.property], withProperty) :
                                                    datas.length > 1 ? datas.map(({ data }) => data) : path(['dataFilters', 0, 'data'], item)
                                                }
                                                showCommon={settings.showCommon}
                                                commonColumn={settings.commonColumn}
                                                title={settings.title}
                                                commonData={path(['data'], find(i => i.type === type && !path(['filter', 'value'], i) && !path(['filter', 'property'], i), item.dataFilters))}
                                                reportFilter={{
                                                    property: path(['dataFilters', 0, 'filter', 'property'], item),
                                                    value: contains(type, ['block_total', 'driver_total']) ? datas.map(i => path(['filter', 'value'], i)) : value
                                                }}
                                                fontFamily={fontFamily}
                                                importance={pathOr([], ['data'], find(propEq('type', 'driver_importance'), item.dataFilters))}
                                                legendPosition={settings.legendPosition}
                                                colors={settings.colors}
                                                grouped={settings.grouped || false}
                                                vertical={settings.vertical}
                                                settings={pathOr({}, ['projectSettings', 'theme'], data)}
                                                numberAmount={settings.numberAmount}
                                                publicReport
                                                hideScale />
                                        </Wrapper>
                                        { settings.table && this.renderResultTable(filter(item => item.type === type, item.dataFilters)) }
                                        <Wrapper>{ settings.footer && <p style={{ textAlign: 'center' }}>{ settings.footer }</p> }</Wrapper>
                                    </div>;
                                }
                            )}
                        </div>
                    }
                </div>
                <Wrapper>
                    { path(['settings', 'footer'], page) && <p style={{ textAlign: 'center' }}>{ page.settings.footer }</p> }
                    { path(['settings', 'footer'], data) && <p style={{ textAlign: 'center' }}>{ data.settings.footer }</p> }
                </Wrapper>
            </Page>;
    }

    renderPages = () => {
        const { getPublicReports: { data }} = this.props;
        const pages = data.pages || [];

        return pages.map(this.renderPage);
    }

    render() {
        const { getPublicReports: { meta }} = this.props;

        return <Container>
            { meta.pending && !meta.lastSucceedAt ?
                <CenterSpin /> :
                this.renderPages()
            }
        </Container>;
    }
}

export default withAsyncActions({
    getPublicReports: getPublicReports
        .withPayload(({ match: { params: { id, key }}}) => ({ id, key }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(PublicReport);
