import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { postQuestionnaire, putQuestionnaire } from '../../actions/asyncActions';
import QuestionnaireForm from '../forms/QuestionnaireForm';

class QuestionnaireModal extends Component {
    render() {
        const { modal, params, postQuestionnaire, putQuestionnaire } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать опросник' : 'Добавить опросник'}
            footer={null}>
            <QuestionnaireForm
                formAction={params ? putQuestionnaire : postQuestionnaire}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postQuestionnaire: postQuestionnaire
        .withSuccessHandler(({ close }) => {
            message.success('Опросник успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить опросник'))
        .withOptions({ resetOnUnmount: true }),
    putQuestionnaire: putQuestionnaire
        .withSuccessHandler(({ close }) => {
            message.success('Опросник успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить опросник'))
        .withOptions({ resetOnUnmount: true })
})(QuestionnaireModal);
