import React, { Component } from 'react';
import { filter, toPairs, contains, is, indexOf } from 'ramda';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import CenterSpin from '../../../CenterSpin';
import withReportFilters from '../../../hocs/withReportFilters';
import { withAsyncActions } from 'react-async-client';
import { getReportFilters } from '../../../../actions/asyncActions';
import { TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';
import withChartPngDownload from '../../../hocs/withChartPngDownload';

const legendPosition = (two, three, itemStyle) => ({
    bottom: {
        verticalAlign: 'bottom'
    },
    right: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemWidth: 230,
        itemStyle: {
            fontWeight: 'normal',
            textOverflow: undefined,
            fontSize: three ? 40 : two ? 20 : 12,
            ...(itemStyle || {})
        }
    },
    left: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle',
        itemWidth: 230,
        itemStyle: {
            fontWeight: 'normal',
            textOverflow: undefined,
            fontSize: three ? 40 : two ? 20 : 12,
            ...(itemStyle || {})
        }
    },
    top: {
        verticalAlign: 'top'
    }
});

export class ReportFilterChart extends Component {
    getData = () => {
        const { reportFilter, getReportFiltersWithFilter, data, property, staticData, defaultLegendName } = this.props;

        return (staticData || (reportFilter.value ? getReportFiltersWithFilter.data[property] : data) || []).map(item => ({
            ...item,
            _id: item._id || defaultLegendName || 'Не указано'
        }));
    }

    getOptions = () => {
        const { colors, pdf, fontFamily, two, three, titleColor, titleSize, columns, absolute, legendFontSize } = this.props;
        const sideLegends = contains(this.props.legendPosition, ['right', 'left']);
        const data = this.getData();

        return {
            chart: {
                type: 'pie',
                height: sideLegends ? (three ? 1600 : two ? 900 : 420) : three ? 1600 : two ? 1000 : data.length > 10 ? (pdf ? 420 : 600) : (pdf ? 420 : 450),
                width: sideLegends ? 800 : pdf ? 900 : undefined,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                }
            },
            title: {
                text: is(Function, this.props.title) ? this.props.title(this.props) : this.props.title,
                margin: pdf ? 30 : 15,
                floating: true,
                style: pdf ? {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1),
                    height: 100
                } : {}
            },
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: pdf ? (sideLegends ? (three ? 350 : two ? 350 : 280) : three ? 500 : two ? 550 : (data.length > 16 ? 200 : 250)) : 300,
                    dataLabels: {
                        enabled: true,
                        format: absolute ? '{point.y}' : '{point.percentage:.1f}%',
                        style: {
                            fontWeight: 'normal',
                            fontSize: three ? 40 : two ? 18 : undefined,
                            textOverflow: 'none'
                        },
                        distance: 20
                    },
                    showInLegend: true
                }
            },
            legend: {
                symbolHeight: pdf ? (three ? 27 : two ? 11 : 8) : 11,
                squareSymbol: true,
                symbolPadding: three ? 20 : two ? 10 : 5,
                symbolRadius: 0,
                maxHeight: sideLegends ? undefined : (three ? (sideLegends ? 700 : 300) : two ? 149 : 133),
                navigation: {
                    style: {
                        display: pdf ? 'none' : 'block'
                    },
                    arrowSize: pdf ? .1 : 12
                },
                itemMarginBottom: 7,
                layout: pdf ? 'vertical' : 'horizontal',
                itemStyle: {
                    fontWeight: 'normal',
                    fontSize: legendFontSize || (three ? 40 : two ? 25 : 12)
                },
                ...legendPosition(two, three, { fontSize: legendFontSize })[this.props.legendPosition || 'bottom']
            },
            exporting: {
                chartOptions: {
                    chart: {
                        height: data.length > 8 ? 500 + (data.length * 20) : 450
                    },
                    legend: {
                        maxHeight: undefined,
                        navigation: {
                            enabled: false
                        }
                    }
                }
            },
            series: [{
                colorByPoint: true,
                data: data.map(({ _id, count }, index) => ({
                    name: _id,
                    legendIndex: columns && columns.length ? indexOf(_id, columns) : undefined,
                    y: count,
                    color: colors && colors.length ? colors[index % colors.length] : null
                }))
            }]
        };
    }

    render() {
        return <HighchartsReact
            highcharts={Highcharts}
            options={this.getOptions()}
            ref={this.props.getRef} />;
    }
}

const ReportFilterChartComponent = withReportFilters(
    withAsyncActions(props => ({
        getReportFiltersWithFilter: getReportFilters
            .withParams(({ reportFilter }) => reportFilter)
            .withPayload(({ id, reportFilter }) => ({
                id,
                q: {
                    sliceByProperty: reportFilter.property,
                    sliceValues: reportFilter.value
                }
            }))
            .withOptions({ resetOnUnmount: true, dispatchOnMount: !!props.reportFilter.value })
    }))(withChartPngDownload(ReportFilterChart)), { multiple: true }
);

export default class ReportFilters extends Component {
    renderChart = ([ property, data ]) => {
        return <ReportFilterChartComponent
            id={this.props.id}
            key={property}
            property={property}
            data={data}
            getReportFilters={this.props.getReportFilters}
            title={props => `${props.property}${props.reportFilter.value ? ` (${props.reportFilter.value})` : ''}`} />;
    }

    render() {
        const { meta, data } = this.props.getReportFilters;
        const charts = filter(([ _, items ]) => items.length <= 30, toPairs(data));

        return meta.pending && !meta.lastSucceedAt ?
            <CenterSpin /> :
            charts.map(this.renderChart);
    }
};
