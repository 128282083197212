import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { prop } from 'ramda';
import { Field } from 'react-final-form';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import { getCompanies, getQuestionnaires } from '../../actions/asyncActions';
import Editor from './formComponents/Editor';

class CreateProjectForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            { !this.props.item.company &&
                <Field
                    name='company'
                    component={Select}
                    label='Компания'
                    action={getCompanies}
                    namePath='name' />
            }
            <Field
                name='welcomeText'
                component={Editor}
                label='Текст приветствия' />
            <Field
                name='questionnaire'
                component={Select}
                label='Опросник'
                action={getQuestionnaires}
                namePath='name'
                allowClear />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    company: yup.string().required()
});

export default withFormWrapper(CreateProjectForm, {
    validationSchema,
    mapPropsToValues: prop('item')
});
