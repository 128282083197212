import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { toPairs, keys, pathOr, find, path, filter } from 'ramda';

import { GroupedCategoriesHighcharts, getQuestionsItemDisribution } from '../../../../utils/charts';
import CenterSpin from '../../../CenterSpin';
import { TITLE_SIZE_COEFFICIENT } from '../../../../constants/charts';

export default class ReportDriverDistributionPieChart extends Component {
    getOptions = () => {
        const { getReportQuestionsDistribution: { data }, fontFamily, settings, getReportDriverDistribution, title, titleColor, titleSize, two, three, footerImage } = this.props;
        const items = toPairs(getQuestionsItemDisribution(data[title]));
        const scale = keys(pathOr({}, [0, 1], items));
        const pieData = toPairs(pathOr({}, [1, title], find(([_, item]) => item[title], toPairs(getReportDriverDistribution.data))));

        return {
            chart: {
                type: 'bar',
                width: 800,
                height: footerImage ? 420 : 440,
                marginLeft: 600,
                style: {
                    fontFamily: fontFamily || "\"Lucida Grande\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif"
                }
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                pie: {
                    startAngle: -45,
                    dataLabels: {
                        distance: 10,
                        style: {
                            textOverflow: 'none'
                        }
                    }
                }
            },
            title: {
                text: title,
                style: {
                    color: titleColor || '#C20000',
                    fontSize: (titleSize || 18) * (three ? TITLE_SIZE_COEFFICIENT.three : two ? TITLE_SIZE_COEFFICIENT.two : 1)
                }
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: items.map(([ category ]) => category),
                labels: {
                    style: {
                        width: 300,
                        textAlign: 'center',
                        fontSize: 10
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    step: 2
                },
                title: {
                    text: null
                }
            },
            legend: {
                symbolHeight: 11,
                symbolWidth: 11,
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: 'normal',
                    fontSize: 10
                }
            },
            tooltip: {
                pointFormat: '<b>{series.name}: {point.y}</b>'
            },
            series: [
                ...scale.map(name => ({
                    type: 'bar',
                    name,
                    data: items.map(([ _, stat ]) => pathOr(0, [name, 'percent'], stat)),
                    color: (settings.scaleColor || {})[name]
                })),
                {
                    type: 'pie',
                    width: 250,
                    data: filter(([_, stat]) => !!path(['percent'], stat), pieData).map(([name, stat]) => ({
                        name,
                        y: stat.percent,
                        color: (settings.scaleColor || {})[name]
                    })),
                    center: [-470, 120],
                    size: 150,
                    showInLegend: false,
                    dataLabels: {
                        format: '<span>{point.y}%</span>',
                        style: {
                            color: '#636363',
                            border: 0,
                            fontWeight: 'normal'
                        }
                    }
                }
            ]
        };
    }

    render() {
        const { getReportDriverDistribution, staticData } = this.props;

        return !staticData && getReportDriverDistribution.meta.pending && !getReportDriverDistribution.meta.lastSucceedAt ?
            <CenterSpin /> :
            <HighchartsReact
                ref={this.props.getRef}
                highcharts={GroupedCategoriesHighcharts}
                options={this.getOptions()} />;
    }
}
