import React, { Component } from 'react';
import { equals, assocPath, forEachObjIndexed, path, toLower, has, is, pathOr } from 'ramda';
import { Form, FormSpy } from 'react-final-form';
import { Form as FormComponent, Alert } from 'antd';
import arrayMutators from 'final-form-arrays';
import { withAsyncHandlers } from 'react-async-client';
import { withState } from 'recompose';

import { getRequiredFields } from '../../utils/getRequiredFields';
import RequiredFieldsContext from '../contexts/RequiredFieldsContext';
import FormSubmittingContext from '../contexts/FormSubmittingContext';
import FormServerErrorsContext from '../contexts/FormServerErrorsContext';
import ERRORS, { ERRORS_LABELS, DEFAULT_ERROR } from '../../constants/errors';
import InitialValuesContext from '../contexts/InitialValuesContext';

const getServerError = (props, action) => {
    const fields = path(['meta', 'error', 'data', 'errors'], props.formAction);
    const values = path(['requestAction', 'payload'], action);
    const fieldErrors = {};

    if (fields) {
        forEachObjIndexed((errors, field) => {
            errors.forEach(error => {
                const template = ERRORS[toLower(error.messageTemplate || error.message_template)];

                let message = error.message;

                if (template) {
                    message = template
                        .replace('$property', ERRORS_LABELS[field] || field)
                        .replace('$value', values[field]);

                    if (error.constraints) {
                        error.constraints.forEach((value, index) => {
                            message = message.replace(`$constraint${index + 1}`, value);
                        });
                    }
                }

                fieldErrors[field] = message;
            });
        }, fields);
    }

    return fieldErrors;
}

export default (WrappedComponent, formOptions = {}) => {
    const enableReinitialize = has('enableReinitialize', formOptions) ? formOptions.enableReinitialize : true;

    const FormWithHandlers = withAsyncHandlers(({ formAction }) => {
        return (formAction && formAction.dispatch && ({
            formAction: {
                successHandler: props => {
                    props.onSubmitSuccess && props.onSubmitSuccess(props);
                },
                errorHandler: (props, action) => {
                    props.setServerErrors(getServerError(props, action));
                    props.onSubmitFail && props.onSubmitFail(props);
                },
            }
        })) || {};
    })(WrappedComponent);

    class FormWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {
                initialValues: this.getInitialValues(props)
            };
        }

        componentDidUpdate(prev) {
            const initialValues = this.getInitialValues();
            const reinitialize = is(Function, enableReinitialize) ? enableReinitialize(prev, this.props) : enableReinitialize;

            if (!equals(initialValues, this.getInitialValues(prev)) && reinitialize) {
                this.setState({ initialValues });
            }
        }

        getInitialValues = (props = this.props) => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : {};
        }

        isSubmitting = () => {
            const { formAction } = this.props;

            return formAction && formAction.dispatch && formAction.meta.pending;
        }

        onSubmit = values => {
            const { formAction, setServerErrors } = this.props;
            const mapBeforeSubmit = this.props.mapBeforeSubmit || formOptions.mapBeforeSubmit;
            const dispatch = formAction.dispatch || formAction;

            setServerErrors({});

            if (!this.isSubmitting()) {
                dispatch(mapBeforeSubmit ? mapBeforeSubmit(values, this.props) : values, this.props);
            }
        }

        handleSubmit = (e, props) => {
            const { afterSubmit } = formOptions;

            props.handleSubmit(e);

            if (afterSubmit && !props.invalid) {
                afterSubmit(props);
            }
        }

        validate = values => {
            const schema = this.getValidationSchema();

            if (!schema) {
                return {};
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return e.inner.reduce((errors, error) => {
                    const path = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p)) ? p : Number(p));
                    return assocPath(path, error.message, errors);
                }, {});
            }
        }

        getValidationSchema = () => {
            const schema = formOptions.validationSchema;

            return schema ? (typeof schema === 'function' ? schema(this.props) : schema) : null;
        }

        getError = () => {
            const error = path(['meta', 'error', 'data', 'message'], this.props.formAction);
            const networkError = path(['meta', 'error', 'origin', 'message'], this.props.formAction);

            return error || networkError;
        }

        renderError(error) {
            const status = path(['meta', 'error', 'status'], this.props.formAction);
            const message = status < 500 ? (ERRORS[error] || this.props.defaultError) : (ERRORS[error] || DEFAULT_ERROR);

            return message && <Alert style={{ marginBottom: 15 }} message={message} type='error' className='form-alert' />;
        }

        onChangeSubmitFailed = () => {
            setTimeout(() => {
                const field = document.querySelector('.has-error');

                if (field) {
                    field.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }

        render() {
            const { serverErrors } = this.props;
            const error = this.getError();
            const isSubmitting = this.isSubmitting();

            return (
                <InitialValuesContext.Provider value={this.state.initialValues}>
                    <FormServerErrorsContext.Provider value={serverErrors}>
                        <RequiredFieldsContext.Provider value={getRequiredFields(this.getValidationSchema())}>
                            <Form
                                subscription={{ submitting: true, invalid: true, submitFailed: true, error: true, ...pathOr({}, ['subscriptions'], formOptions) }}
                                onSubmit={this.onSubmit}
                                validate={this.validate}
                                mutators={arrayMutators}
                                initialValues={this.state.initialValues}
                                render={props =>
                                    <FormComponent
                                        onFinish={e => this.handleSubmit(e, props)}
                                        noValidate>
                                        { error && this.renderError(error) }
                                        <FormSpy
                                            subscription={{ submitFailed: true }}
                                            onChange={this.onChangeSubmitFailed} />
                                        <FormSubmittingContext.Provider value={isSubmitting}>
                                            <FormWithHandlers
                                                {...this.props}
                                                {...props}
                                                isSubmitting={isSubmitting} />
                                        </FormSubmittingContext.Provider>
                                    </FormComponent>
                                }
                            />
                        </RequiredFieldsContext.Provider>
                    </FormServerErrorsContext.Provider>
                </InitialValuesContext.Provider>
            );
        }
    }

    return withState('serverErrors', 'setServerErrors', {})(FormWrapper);
}
