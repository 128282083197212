
  import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const USER = construct('/sessions/current');
export const LOGIN = construct('/login_check');
export const RECOVERY = construct('/password_recovery');
export const PROJECTS = construct('/projects(/:id)');
export const COMPANIES = construct('/companies(/:id)');
export const RESPONDENTS_STATUSES = construct('/respondents/statuses');
export const RESPONDENTS = construct('/respondents(/:id)');
export const RESPONDENTS_IMPORT = construct('/respondents/import/project/:id');
export const MESSAGES = construct('/messages');
export const QUESTIONNAIRES = construct('/questionnaires(/:id)');
export const MESSAGES_COMPANY = construct('/messages/company');

export const REPORT_BLOCK_TOTAL = construct('/respondents/project/:id/report/block/total');
export const REPORT_DRIVER_TOTAL = construct('/respondents/project/:id/report/driver/total');
export const REPORT_BLOCK_DISTRIBUTION = construct('/respondents/project/:id/report/block/distribution');
export const REPORT_DRIVER_DISTRIBUTION = construct('/respondents/project/:id/report/driver/distribution');
export const REPORT_FILTERS = construct('/respondents/project/:id/report/filters');
export const REPORT_TABLE = construct('/respondents/project/:id/report/table');
export const DRIVER_IMPORTANCE = construct('/respondents/project/:id/driver/importance');
export const REPORT_QUESTIONS_DISTRIBUTION = construct('/respondents/project/:id/report/question/distribution');

export const FILE = construct('/files(/:id)');
export const FILE_DOWNLOAD = construct('/files/:id/download');

export const REPORTS = construct('/reports(/:id)');
export const PUBLIC_REPORTS = construct('/public/reports/:id(/:key)');
export const REFRESH_TOKEN = construct('/refresh_token');
