import React, { Component } from 'react';
import { Button } from 'antd';
import { pathOr, path, find, propEq } from 'ramda';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ContainerOutlined, InfoCircleOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

import { openProjectInfoModal, openProjectLetterTemplateModal, openProjectPreviewModal } from '../../../actions/modalActions';
import { PROJECT_THEMES } from '../../../constants/project';

export const Title = styled.h3`
    margin: 0 15px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Block = styled.div`
    padding: 15px 15px 30px;
`;

const TextBlock = styled.span`
    white-space: pre-wrap;
    p {
        margin-bottom: 0;
    }
`;

class ProjectInfo extends Component {
    render() {
        const { project, openProjectInfoModal, openProjectLetterTemplateModal, openProjectPreviewModal } = this.props;
        const theme = pathOr({}, ['settings', 'theme'], project);

        return <div style={{ marginTop: 10 }}>
            <Title>
                <span><InfoCircleOutlined /> Информация</span>
                <Button shape='circle' icon={<EditOutlined />} onClick={() => openProjectInfoModal(project)} />
            </Title>
            <Block>
                <div><strong>Название: </strong>{ project.name }</div>
                <div><strong>Заголовок: </strong><TextBlock dangerouslySetInnerHTML={{ __html: project.title || '-' }} /></div>
                <div>
                    <strong>Текст приветствия: </strong>
                    <TextBlock dangerouslySetInnerHTML={{ __html: project.welcomeText || '-' }} />
                </div>
                <div>
                    <strong>Шкала оценки:</strong>
                    <ul>
                        { project.scale && !!project.scale.length ?
                            project.scale.map((scale, index) =>
                                <li key={`scale-${index}`}>{scale}: {(project.answerMap || {})[scale] || '-'}</li>
                            ) : '-'
                        }
                    </ul>
                </div>
                { theme.name &&
                    <div>
                        <strong>Тема: </strong>
                        { path(['value'], find(propEq('id', theme.name), PROJECT_THEMES)) } <Button type='link' icon={<EyeOutlined />} onClick={() => openProjectPreviewModal({
                            main: true,
                            title: project.name,
                            project: project.id,
                        })} />
                    </div>
                }
                { theme.logo &&
                    <div><strong>Лого: </strong><img src={theme.logo} alt='logo' width={200} /></div>
                }
            </Block>
            <Title>
                <span><ContainerOutlined /> Шаблон письма</span>
                <Button shape='circle' icon={<EditOutlined />} onClick={() => openProjectLetterTemplateModal(project)} />
            </Title>
            <Block>
                <div><strong>Заголовок письма: </strong>{ pathOr('-', ['messageTemplate', 'subject'], project) }</div>
                <div>
                    <strong>Текст письма: </strong>
                    <TextBlock dangerouslySetInnerHTML={{ __html: pathOr('-', ['messageTemplate', 'content'], project) }} />
                </div>
            </Block>
        </div>
    }
}

export default connect(null, {
    openProjectInfoModal,
    openProjectLetterTemplateModal,
    openProjectPreviewModal
})(ProjectInfo);
