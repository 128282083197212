import { KJUR, b64utoutf8 } from 'jsrsasign';
import moment from 'moment';

import { addDefaultHeader, removeDefaultHeader } from './http';

export function handleTokenChange(payload) {
    if (payload) {
        localStorage.setItem('token', payload.token);
        localStorage.setItem('refresh_token', payload.refresh_token);
        localStorage.setItem('expiredDate', expiredDate(payload.token));
        addDefaultHeader('Authorization', `Bearer ${payload.token}`);
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expiredDate');
        removeDefaultHeader('Authorization');
    }
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getRefreshToken() {
    return localStorage.getItem('refresh_token');
}

export function isTokenExpired() {
    const expiredDate = localStorage.getItem('expiredDate');

    return expiredDate ? moment().isAfter(moment(new Date(expiredDate))) : false;
}

export function getJWTToken() {
    return `Bearer ${getToken()}`;
}

export function expiredDate(token) {
    const payload = b64utoutf8(token.split('.')[1]);
    const tokenInfo = KJUR.jws.JWS.readSafeJSONString(payload);

    return moment.unix(tokenInfo.exp);
}
