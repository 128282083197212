import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';

import Input from './formComponents/Input';
import VariablesInput from './formComponents/VariablesInput';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { PROJECT_LETTER_TEMPLATE_VARIABLES } from '../../constants/project';
import { parseToPatch } from '../../utils/forms';

class ProjectLetterTemplateForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='messageTemplate.subject'
                component={Input}
                label='Заголовок письма'
                getTranslationPath={lang => `messageTemplate.translations.${lang}.subject`}
                translationsWithoutRewrite
                translations />
            <Field
                name='messageTemplate.content'
                component={VariablesInput}
                label='Текст письма'
                variables={PROJECT_LETTER_TEMPLATE_VARIABLES}
                getTranslationPath={lang => `messageTemplate.translations.${lang}.content`}
                translationsWithoutRewrite
                translations />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(ProjectLetterTemplateForm, {
    mapPropsToValues: prop('item'),
    mapBeforeSubmit: (values, props) => parseToPatch(values, props.item, [
        'messageTemplate.subject',
        'messageTemplate.content',
        'messageTemplate.translations'
    ])
});
