import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import { patchProject } from '../../actions/asyncActions';
import ProjectLanguagesForm from '../forms/ProjectLanguagesForm';

class ProjectLanguagesModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Редактировать языки проекта'
            footer={null}>
            <ProjectLanguagesForm
                formAction={patchProject}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(({ close }) => {
            message.success('Языки проект успешно изменены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить языки проекта'))
        .withOptions({ resetOnUnmount: true })
})(ProjectLanguagesModal);
