import React, { Component } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { toPairs, path, keys, pathOr } from 'ramda';
import moment from 'moment';
import { takeEvery } from 'redux-saga/effects';

import { getRespondent, getProject, deleteRespondent } from '../../../actions/asyncActions';
import { openRespondentModal } from '../../../actions/modalActions';
import CenterSpin from '../../CenterSpin';
import { RESPONDENT_STATUS_NAMES } from '../../../constants/project';
import { PUT_RESPONDENT } from '../../../constants/actionTypes';
import { Actions } from './ProjectRespondents';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';

class ProjectRespondentButtonsComponent extends Component {
    getGroups = () => {
        return keys(this.props.getProject.data.groups || {}).map(value => ({ id: value, value }));
    }

    delete = id => {
        this.props.deleteRespondent.dispatch(id);
    }

    render() {
        const { getRespondent: { data, meta }, getProject } = this.props;

        return meta.lastSucceedAt &&
            <Actions
                single
                item={data}
                edit={() => this.props.openRespondentModal({
                    item: data,
                    groups: this.getGroups(),
                    languages: toPairs(getProject.data.languages).map(([id, value]) => ({ id, value }))
                })}
                delete={this.delete} />;
    }
}

export const ProjectRespondentButtons = withRouter(
    asyncConnect({
        getRespondent,
        getProject,
        deleteRespondent: deleteRespondent
            .withSuccessHandler(({ history, getProject }) => {
                message.success('Респондент успешно удален');
                history.replace(`/projects/${getProject.data.id}/respondents`)
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондента'))
            .withOptions({ resetOnUnmount: true })
    }, null, { openRespondentModal })(ProjectRespondentButtonsComponent)
);

class ProjectRespondent extends Component {
    render() {
        const { getRespondent: { data, meta }} = this.props;
        const additionalData = toPairs(data.additionalData || {});
        const startedAt = path(['response', 'startedAt'], data);
        const finishedAt = path(['response', 'finishedAt'], data);

        return meta.pending && !meta.lastSucceedAt ?
            <CenterSpin /> :
            <div style={{ padding: 15 }}>
                <div><strong>E-mail:</strong> { data.email }</div>
                { data.group && <div><strong>Группа:</strong> { data.group }</div> }
                <div><strong>Статус: </strong>{ RESPONDENT_STATUS_NAMES[data.status] }</div>
                { data.language && <div><strong>Язык прохождения: </strong>{ pathOr(data.language, ['_embedded', 'project', 'languages', data.language], data) }</div> }
                { startedAt && <div><strong>Дата начала прохождения: </strong>{ moment(startedAt).format('DD.MM.YYYY') }</div> }
                { finishedAt && <div><strong>Дата завершения прохождения: </strong>{ moment(finishedAt).format('DD.MM.YYYY') }</div> }
                { !!additionalData.length && <h2 style={{ marginTop: 10 }}>Дополнительная информация</h2> }
                { additionalData.map(([ title, text ], index) =>
                    <div key={`additional-data-${index}`}><strong>{ title }: </strong>{ text }</div>
                )}
            </div>;
    }
}

export default asyncConnect({
    getRespondent: getRespondent
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_RESPONDENT)], () => {
                getProps().getRespondent.refresh();
            })
        })
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ProjectRespondent);
