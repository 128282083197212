import React, { Component } from 'react';

import Modal from './Modal';
import { ProjectPdfTemplatePageForm } from '../forms/ProjectPdfForm';

export default class ProjectPdfTemplatePageModal extends Component {
    onSubmit = values => {
        this.props.params.onSubmit(values);
        this.props.close();
    }

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={params.item ? 'Редактировать страницу' : 'Добавить страницу'}
            footer={null}>
            <ProjectPdfTemplatePageForm
                formAction={this.onSubmit}
                item={params.item}
                filters={params.filters}
                statuses={params.statuses}
                drivers={params.drivers}
                project={params.project}
                segment />
        </Modal>;
    }
}
