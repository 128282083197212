import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Upload, Alert, Button } from 'antd';
import { path } from 'ramda';
import { InboxOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import { getCompanies } from '../../actions/asyncActions';
import Input from './formComponents/Input';
import { withState } from 'recompose';

class ImportProjectForm extends Component {
    state = {
        file: false,
        error: false
    };

    onChangeFile = file => {
        const fr = new FileReader();

        fr.readAsText(file);
        fr.onload = () => {
            const text = fr.result;

            try {
                const data = JSON.parse(text);
                const name = path(['name'], data);

                if (name) {
                    this.props.setInitialValues({
                        ...data,
                        company: this.props.form.getState().values.company
                    });
                    this.setState({ file: true, error: false });
                } else {
                    this.setState({ error: 'Отсутствует название проекта' });
                }
            } catch (e) {
               this.setState({ error: 'Не удалось загрузить файл' });
            }
        }

        return false;
    }

    delete = () => {
        this.setState({ file: false });
        this.props.setInitialValues({
            company: this.props.form.getState().values.company
        });
    }

    render() {
        const { data } = this.props;

        return <Fragment>
            { !data.company &&
                <Field
                    name='company'
                    component={Select}
                    label='Компания'
                    action={getCompanies}
                    namePath='name' />
            }
            { !!this.state.error &&
                <Alert type='error' message={this.state.error} style={{ marginBottom: 15 }} />
            }
            { this.state.file ?
                <div style={{ marginBottom: 15 }}>
                    <Field
                        name='name'
                        component={Input}
                        label='Название' />
                    <Button danger onClick={this.delete}>Удалить проект</Button>
                </div> :
                <div style={{ marginBottom: 15 }}>
                    <Upload.Dragger
                        beforeUpload={this.onChangeFile}
                        multiple={false}
                        fileList={[]}
                        accept='application/json'>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Для загрузки нажмите или перетащите файл в эту область</p>
                        <p className='ant-upload-hint'>
                            JSON-файл
                        </p>
                    </Upload.Dragger>
                </div>
            }
            <SubmitButton disabled={!this.state.file}>
                Импортировать
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    company: yup.string().required(),
    name: yup.string().required()
});

export default withState('initialValues', 'setInitialValues', ({ data }) => data)(
    withFormWrapper(ImportProjectForm, {
        validationSchema,
        mapPropsToValues: ({ initialValues }) => initialValues
    })
);
