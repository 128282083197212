export default {
    'User not found': 'Пользователь с таким email не найден',
    'User is disabled': 'Профиль пользователя недоступен',
    'User disabled': 'Пользователь недоступен',
    'Bad credentials.': 'Неверный логин или пароль',
    'User already exists': 'Пользователь с таким email уже существует',
    'Unauthorized': 'Не удалось авторизоваться',

    'Not Found': 'Страница не найдена',
    'variables "{{ variables }}" is missing': 'Не удалось найти переменные'
};

export const DEFAULT_LOGIN_ERROR = 'Не удалось авторизоваться';
export const DEFAULT_ERROR = 'Ошибка соединения с сервером';
export const UNABLE_TO_SHOW = 'Не удалось отобразить страницу';
export const USER_UNAVAIBLE = 'Сессия пользователя недействительна';

export const ERRORS_LABELS = {
    name: 'таким названием'
};
