import React, { Component } from 'react';

import ProjectDrivers from '../projects/ProjectDrivers';
import { patchQuestionnaire, putQuestionnaire } from '../../../actions/asyncActions';

export default class QuestionnaireDrivers extends Component {
    render() {
        return <ProjectDrivers
            project={this.props.data}
            patchAction={patchQuestionnaire}
            putAction={putQuestionnaire}
            getQuestionnaire={this.props.getQuestionnaire}
            getAction='getQuestionnaire'
            hideVariablesField />;
    }
}
