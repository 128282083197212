import { createAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';

export const closeModal = createAction(actions.CLOSE_MODAL);
export const hideModal = createAction(actions.HIDE_MODAL);
export const showModal = createAction(actions.SHOW_MODAL);
export const removeModal = createAction(actions.REMOVE_MODAL);

export const openProjectInfoModal = createAction(actions.OPEN_PROJECT_INFO_MODAL);
export const openProjectLetterTemplateModal = createAction(actions.OPEN_PROJECT_LETTER_TEMPLATE_MODAL);
export const openRespondentModal = createAction(actions.OPEN_RESPONDENT_MODAL);
export const openSendRespondentLetterModal = createAction(actions.OPEN_SEND_RESPONDENT_LETTER_MODAL);
export const openCreateProjectModal = createAction(actions.OPEN_CREATE_PROJECT_MODAL);
export const openGroupModal = createAction(actions.OPEN_GROUP_MODAL);
export const openGroupVariablesModal = createAction(actions.OPEN_GROUP_VARIABLES_MODAL);
export const openDriverModal = createAction(actions.OPEN_DRIVER_MODAL);
export const openDriverSettingsModal = createAction(actions.OPEN_DRIVER_SETTINGS_MODAL);
export const openCompanyModal = createAction(actions.OPEN_COMPANY_MODAL);
export const openSendCompanyLetterModal = createAction(actions.OPEN_SEND_COMPANY_LETTER_MODAL);
export const openQuestionnaireModal = createAction(actions.OPEN_QUESTIONNAIRE_MODAL);
export const openProjectLanguagesModal = createAction(actions.OPEN_PROJECT_LANGUAGES_MODAL);
export const openProjectPreviewModal = createAction(actions.OPEN_PROJECT_PREVIEW_MODAL);
export const openPdfChartModal = createAction(actions.OPEN_PDF_CHART_MODAL);
export const openProjectPdfModal = createAction(actions.OPEN_PROJECT_PDF_MODAL);
export const openDownloadPdfReportModal = createAction(actions.OPEN_DOWNLOAD_PDF_REPORT_MODAL);
export const openProjectPdfSettingsModal = createAction(actions.OPEN_PROJECT_PDF_SETTINGS_MODAL);
export const openProjectPageElementsModal = createAction(actions.OPEN_PROJECT_PAGE_ELEMENTS_MODAL);
export const openReportModal = createAction(actions.OPEN_REPORT_MODAL);
export const openReportPageModal = createAction(actions.OPEN_REPORT_PAGE_MODAL);
export const openReportSettingsModal = createAction(actions.OPEN_REPORT_SETTINGS_MODAL);
export const openReportChartModal = createAction(actions.OPEN_REPORT_CHART_MODAL);
export const openImportProjectModal = createAction(actions.OPEN_IMPORT_PROJECT_MODAL);
export const openImportQuestionsModal = createAction(actions.OPEN_IMPORT_QUESTIONS_MODAL);
export const openProjectResponseFieldFormModal = createAction(actions.OPEN_PROJECT_RESPONSE_FIELD_FORM_MODAL);
export const openDefaultProjectLanguageModal = createAction(actions.OPEN_DEFAULT_PROJECT_LANGUAGE_MODAL);
export const openProjectPdfTemplatesModal = createAction(actions.OPEN_PROJECT_PDF_TEMPLATES_MODAL);
export const openProjectPdfTemplateModal = createAction(actions.OPEN_PROJECT_PDF_TEMPLATE_MODAL);
export const openProjectPdfTemplatePageModal = createAction(actions.OPEN_PROJECT_PDF_TEMPLATE_PAGE_MODAL);
export const openLanguagesSelectorModal = createAction(actions.OPEN_LANGUAGES_SELECTOR_MODAL);
