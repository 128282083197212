import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Button } from 'antd';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { remove, map } from 'ramda';
import { MenuOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import Input from './formComponents/Input';
import { DRIVER_TYPE } from '../../constants/project';
import { reorder } from '../../utils/dnd';
import ListenerField from './ListenerField';
import LanguagesSelector from './formComponents/LanguagesSelector';

const FieldItem = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
        margin-right: 10px;
    }
`;

const DragHandler = styled.div`
    padding: 0 10px;
`;

class DriverForm extends Component {
    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const { form } = this.props;
        const questions = reorder(
            form.getState().values.questions,
            result.source.index,
            result.destination.index
        );

        form.change('questions', questions);
    }

    removeQuestionFromTranslations = (index, translations) => {
        this.props.form.change('translations', map(value => ({
            ...value,
            questions: remove(index, 1, value.questions || [])
        }), translations));
    }

    renderQuestionsFields = ({ fields }) => {
        return <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='questions'>
                    { provided =>
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            { fields.map((name, index) =>
                                <Draggable key={name} draggableId={name} index={index}>
                                    { provided =>
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <FieldItem>
                                                <DragHandler {...provided.dragHandleProps}>
                                                    <MenuOutlined />
                                                </DragHandler>
                                                <Field
                                                    name={name}
                                                    component={Input}
                                                    hideErrorMsg
                                                    validate={value => !value ? 'required' : undefined} />
                                                <Button.Group style={{ width: 73 }}>
                                                    <LanguagesSelector name={name} />
                                                    <ListenerField listenFieldName='translations'>
                                                        { ({ translations }) =>
                                                            <Button danger icon={<DeleteOutlined />} onClick={() => {
                                                                fields.remove(index);
                                                                this.removeQuestionFromTranslations(index, translations);
                                                            }} />
                                                        }
                                                    </ListenerField>
                                                </Button.Group>
                                            </FieldItem>
                                        </div>
                                    }
                                </Draggable>
                            )}
                            {provided.placeholder}
                        </div>
                    }
                </Droppable>
            </DragDropContext>
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>Добавить вопрос</Button>
            <Field name='translations' component={() => null} />
        </div>;
    }

    render() {
        return <Fragment>
            <Field
                name='type'
                component={Select}
                label='Тип'
                options={DRIVER_TYPE} />
            <Field
                name='block'
                component={Input}
                label='Название блока'
                translations />
            <Field
                name='name'
                component={Input}
                label='Название драйвера'
                translations />
            <Form.Item label='Вопросы' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='questions'>
                    { this.renderQuestionsFields }
                </FieldArray>
            </Form.Item>
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required(),
    block: yup.string().required()
});

export default withFormWrapper(DriverForm, {
    mapPropsToValues: ({ item }) => item || ({
        type: 'single'
    }),
    validationSchema,
    mapBeforeSubmit: (values, props) => ({
        id: props.project,
        data: props.item ? [{
            op: 'replace',
            path: `/drivers/${props.index}`,
            value: values
        }] : [{
            op: 'add',
            path: '/drivers/-',
            value: values
        }]
    })
});
