import React, { Component } from 'react';
import { message } from 'antd';

import Modal from './Modal';
import { withAsyncActions } from 'react-async-client/lib/withAsyncActions';
import { postProject } from '../../actions/asyncActions';
import CreateProjectForm from '../forms/CreateProjectForm';

class CreateProjectModal extends Component {
    render() {
        const { modal, params, postProject } = this.props;

        return <Modal
            {...modal}
            title='Добавить проект'
            footer={null}>
            <CreateProjectForm
                item={params}
                formAction={postProject} />
        </Modal>;
    }
}

export default withAsyncActions({
    postProject: postProject
        .withSuccessHandler(({ close }) => {
            message.success('Проект успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить проект'))
        .withOptions({ resetOnUnmount: true })
})(CreateProjectModal);
