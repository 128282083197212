import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, List } from 'antd';
import { keys } from 'ramda';
import { connect } from 'react-redux';
import { CodeOutlined, TeamOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import SpanAsLink from '../../table/SpanAsLink';
import { openGroupModal, openGroupVariablesModal } from '../../../../actions/modalActions';

const Title = styled.h3`
    margin: 0 15px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    & > div {
        width: 50%;
    }
`;

const Block = styled.div`
    padding: 0 15px 10px;
`;

class ProjectGroups extends Component {
    editVariables = () => {
        this.props.openGroupVariablesModal(this.props.project);
    }

    render() {
        const { project: { variables, groups, id }, openGroupModal } = this.props;

        return <Wrapper>
            <div>
                <Title>
                    <span><CodeOutlined /> Переменные</span>
                    <Button shape='circle' icon={<EditOutlined />} onClick={this.editVariables} />
                </Title>
                <Block>
                    <List
                        dataSource={variables}
                        renderItem={item => <List.Item>{ item }</List.Item>} />
                </Block>
            </div>
            <div>
                <Title>
                    <span><TeamOutlined /> Группы</span>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => openGroupModal({ id, variables })}>Добавить</Button>
                </Title>
                <Block>
                    <List
                        dataSource={keys(groups || {})}
                        renderItem={item =>
                            <List.Item>
                                <SpanAsLink onClick={() => openGroupModal({ id, item: groups[item], group: item })}>{ item }</SpanAsLink>
                            </List.Item>
                        } />
                </Block>
            </div>
        </Wrapper>;
    }
}

export default connect(null, { openGroupModal, openGroupVariablesModal })(ProjectGroups);
