import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import ReportSettingsForm from '../forms/ReportSettingsForm';
import { putReport } from '../../actions/asyncActions';

class ReportSettingsModal extends Component {
    render() {
        const { modal, params, putReport } = this.props;

        return <Modal
            {...modal}
            title='Настройки отчета'
            footer={null}>
            <ReportSettingsForm
                formAction={putReport}
                data={params.data}
                project={params.project} />
        </Modal>;
    }
}

export default withAsyncActions({
    putReport: putReport
        .withSuccessHandler(({ close }) => {
            message.success('Настройки отчета успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить настройки отчета'))
        .withOptions({ dispatchOnUnmount: true })
})(ReportSettingsModal);
