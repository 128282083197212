import React from 'react';

const QuestionnaireInfo = ({ data }) =>
    <div style={{ padding: 15 }}>
        <div>
            <strong>Название: </strong>
            { data.name }
        </div>
        <div>
            <strong>Шкала: </strong>
            { data.scale && data.scale.length ?
                data.scale.map((item, index) => <div key={`scale-item-${index}`}>{ item }</div>) : '-'
            }
        </div>
    </div>;

export default QuestionnaireInfo;
