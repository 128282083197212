import React, { Component, Fragment } from 'react';
import { toSuccess, asyncConnect } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { Button, Tabs } from 'antd';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';

import { getQuestionnaire } from '../../../actions/asyncActions';
import { PUT_QUESTIONNAIRE, PATCH_QUESTIONNAIRE } from '../../../constants/actionTypes';
import DetailToolbar from '../DetailToolbar';
import { openQuestionnaireModal } from '../../../actions/modalActions';
import QuestionnaireInfo from './QuestionnaireInfo';
import QuestionnaireDrivers from './QuestionnaireDrivers';
import CenterSpin from '../../CenterSpin';
import Route from '../../Route';

const Wrapper = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-tabs-bar {
        margin: 0;
    }
`;

const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const TABS = [
    { name: 'Информация', key: 'info', component: QuestionnaireInfo },
    { name: 'Вопросы', key: 'drivers', component: QuestionnaireDrivers }
];

class Questionnaire extends Component {
    onChangeTab = key => {
        const { history, match: { params: { id }}} = this.props;

        history.replace(`/questionnaires/${id}/${key}`);
    }

    render() {
        const { getQuestionnaire: { data, meta }, match: { params: { id, type }}, openQuestionnaireModal } = this.props;

        return <Fragment>
             <DetailToolbar title={
                <ToolbarTitle>
                    <h1>{ data.name }</h1>
                    { type === 'info' &&
                        <Button type='primary' icon={<EditOutlined />} disabled={meta.pending} onClick={() => openQuestionnaireModal(data)}>
                            Редактировать
                        </Button>
                    }
                </ToolbarTitle>
            } />
            { meta.pending && !meta.lastSucceedAt ?
                <CenterSpin /> :
                meta.lastSucceedAt ?
                    <Wrapper>
                        <Tabs onChange={this.onChangeTab} animated={false} activeKey={type}>
                            { TABS.map(tab =>
                                <Tabs.TabPane key={tab.key} tab={tab.name}>
                                    <Route path={`/questionnaires/${id}/${tab.key}`} render={props =>
                                        <tab.component {...props} data={data} getQuestionnaire={this.props.getQuestionnaire} />
                                    } />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Wrapper> :
                    null
            }
        </Fragment>
    }
}

export default asyncConnect({
    getQuestionnaire: getQuestionnaire
        .withPayload(({ match: { params: { id }}}) => id)
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_QUESTIONNAIRE), toSuccess(PATCH_QUESTIONNAIRE)], () => {
                getProps().getQuestionnaire.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, null, { openQuestionnaireModal })(Questionnaire);
