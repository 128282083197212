import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { toPairs, pathOr } from 'ramda';
import styled from 'styled-components';

import { getReportTable } from '../../../../actions/asyncActions';
import withReportFilters from '../../../hocs/withReportFilters';
import CenterSpin from '../../../CenterSpin';
import { getTableBackgroundScaleColor } from '../../../../utils/report';

const Wrapper = styled.div`
    overflow-x: auto;
    padding: 15px;
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 3;
    }
`;

export class ReportTableComponent extends Component {
    getHeaders = () => {
        return toPairs(pathOr({}, [0, 1], toPairs(this.props.staticData || this.props.getReportTable.data))).map(([ name ]) => name);
    }

    getRows = () => {
        return toPairs(this.props.staticData || this.props.getReportTable.data).map(([ name ]) => name);
    }

    renderTable = () => {
        const { getReportTable, staticData, title, numberAmount, scale } = this.props;
        const headers = this.getHeaders();
        const rows = this.getRows();
        const data = staticData || getReportTable.data;

        return <Wrapper>
            { title && <h3 style={{ textAlign: 'center' }}>{ title }</h3> }
            <table>
                <thead>
                    <tr>
                        <td style={{ minWidth: 300 }}></td>
                        { headers.map((title, index) =>
                            <td key={`header-title-${index}`} style={{ minWidth: 100, textAlign: 'center', verticalAlign: 'bottom' }}>
                                <strong>{ title }</strong>
                            </td>
                        )}
                    </tr>
                </thead>
                <tbody>
                    { rows.map((row, index) =>
                        <tr key={`table-row-${index}`}>
                            <td style={{ minWidth: 300 }}>
                                <strong>
                                    { row }
                                </strong>
                            </td>
                            { headers.map((name, i) =>
                                <td
                                    key={`table-data-${index}-${i}`}
                                    style={{ minWidth: 100, background: getTableBackgroundScaleColor(data[row][name], scale), textAlign: 'center' }}>
                                    <strong>{ Math.min(1, data[row][name]).toFixed(numberAmount || 1) }</strong>
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </Wrapper>;
    }

    render() {
        const { getReportTable, staticData } = this.props;

        return !staticData && getReportTable.meta.pending && !getReportTable.meta.lastSucceedAt ?
            <CenterSpin /> :
            this.renderTable();
    }
}

const ReportTable = withAsyncActions({
    getReportTable: getReportTable
        .withPayload(({ id, reportFilter }) => ({
            id,
            q: reportFilter
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
})(ReportTableComponent);

class ReportTableWrapper extends Component {
    render() {
        const { reportFilter } = this.props;

        return reportFilter.property ?
            <ReportTable {...this.props} /> :
            <div style={{ margin: 15, textAlign: 'center' }}>Выберите сегмент</div>;
    }
}

export default withReportFilters(ReportTableWrapper, { hideValue: true });
