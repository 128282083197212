import { reduce, minBy, maxBy, values, filter, isNil, path, find } from 'ramda';

const colors = [
    '#5fbf78',
    '#6fc479',
    '#8ecc7a',
    '#b9d87b',
    '#cfdf7c',
    '#fceb7d',
    '#fed178',
    '#fdb374',
    '#fb7e6b',
    '#fa6868'
].map((color, index) => ({ color, from: index === 9 ? 0 : 100 - (6 * (index + 1)), to: 100 - (6 * index) }));

const getTableBackgroundColor = amount => {
    return amount <= 0 ? '#5fbf78' : path(['color'], find(item => amount > item.from && amount <= item.to, colors));
};

export const getTableBackgroundScaleColor = (amount, scale) => {
    const amountScale = scale ? Math.min(100, (amount - scale.min) * 100 / (scale.max - scale.min)) : amount;

    return getTableBackgroundColor(amountScale);
}

export const getScale = answerMap => {
    const data = filter(n => !isNil(n) && n !== '', values(answerMap || {})).map(n => Number(n));

    if (!data.length) {
        return null;
    }

    const min = reduce(minBy(n => n), Infinity, data);
    const max = reduce(maxBy(n => n), 0, data);

    return min !== max ? { min, max } : null;
}
