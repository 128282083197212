import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, message, Popconfirm, Row, Col, Dropdown, Menu } from 'antd';
import { withState } from 'recompose';
import { pathOr, take, reduce, omit } from 'ramda';
import { withAsyncHandlers, asyncConnect } from 'react-async-client';
import styled from 'styled-components';
import { MenuOutlined, EllipsisOutlined, PlusOutlined, ImportOutlined, ExportOutlined } from '@ant-design/icons';

import { reorder } from '../../../utils/dnd';
import { patchProject } from '../../../actions/asyncActions';
import { openDriverModal, openDriverSettingsModal, openProjectPreviewModal, openImportQuestionsModal } from '../../../actions/modalActions';

const Wrapper = styled.div`
    padding: 15px;
`;

const Toolbar = styled.div`
    text-align: right;
    margin-bottom: 15px;
`;

const Item = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background: #fff;
    display: flex;
    ol {
        padding-left: 15px;
        margin-bottom: 0;
    }
`;

const ItemContent = styled.div`
    padding: 10px 15px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .ant-btn-group {
        min-width: 63px;
    }
`;

const Handler = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
`;

class DriverActions extends Component {
    state = {
        visible: false
    };

    handleMenuClick = e => {
        if (e.key !== 'delete') {
            this.setState({ visible: false });
        }
    };

    deleteQuestion = index => {
        this.setState({ visible: false });
        this.props.patchProject.dispatch({
            id: this.props.project.id,
            data: [{
                op: 'remove',
                path: `/drivers/${index}`
            }]
        });
    }

    getItemMenu = () => {
        const { project, openDriverModal, openDriverSettingsModal, patchAction, hideVariablesField, openProjectPreviewModal, item, index } = this.props;

        return <Menu onClick={this.handleMenuClick}>
            <Menu.Item
                key='preview'
                onClick={() => openProjectPreviewModal({
                    title: item.name,
                    project: project.id,
                    index
                })}>
                Просмотр
            </Menu.Item>
            <Menu.Item
                key='settings'
                onClick={() => openDriverSettingsModal({
                    project: project.id,
                    question: item,
                    index,
                    variables: (project.variables || []).map(id => ({ id, name: id })),
                    hideVariablesField,
                    patchAction
                })}>
                Настройки
            </Menu.Item>
            <Menu.Item
                key='edit'
                onClick={() => openDriverModal({ project: project.id, question: item, index, patchAction })}>
                Редактировать
            </Menu.Item>
            <Menu.Item key='delete' style={{ padding: 0 }}>
                <Popconfirm
                    title='Вы уверены, что хотите удалить вопрос?'
                    okText='Да'
                    cancelText='Нет'
                    placement='left'
                    onConfirm={() => this.deleteQuestion(index)}>
                    <div style={{ padding: '5px 12px' }}>Удалить</div>
                </Popconfirm>
            </Menu.Item>
        </Menu>;
    }

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    render() {
        return <Dropdown
            overlay={this.getItemMenu()}
            trigger={['click']}
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.visible}>
            <Button icon={<EllipsisOutlined />} style={{ marginLeft: 15 }} />
        </Dropdown>;
    }
}

class ProjectDrivers extends Component {
    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.props.items,
            result.source.index,
            result.destination.index
        );

        this.props.setItems(items);
        this.props.patchProject.dispatch({
            id: this.props.project.id,
            data: [{
                op: 'replace',
                path: '/drivers',
                value: items
            }]
        });
    }

    getNum = (row, index) => {
        const { items } = this.props;
        const prevNums = reduce((res, cur) => res + ((cur.questions || []).length || 1), 0, take(row, items));

        return prevNums + index + 1;
    }

    export = () => {
        const questions = this.props.items.map(item => omit(['id'], item));
        const file = new Blob([JSON.stringify(questions, null, 2)], { type: 'text/json' });

        this.link.href = URL.createObjectURL(file);
        this.link.download = 'questions.json';
        this.link.click();
    }

    render() {
        const { project, openDriverModal, patchAction, openImportQuestionsModal } = this.props;

        return <Wrapper>
            <Toolbar>
                <Button.Group>
                    <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => openDriverModal({
                            project: project.id,
                            patchAction
                        })}>
                        Добавить
                    </Button>
                    <Button
                        icon={<ImportOutlined />}
                        onClick={() => openImportQuestionsModal({
                            project: project.id,
                            patchAction
                        })}>
                        Импорт
                    </Button>
                    <Button
                        icon={<ExportOutlined />}
                        onClick={this.export}
                        disabled={!this.props.items.length}>
                        Экспорт
                    </Button>
                </Button.Group>
                <a href='link' ref={node => this.link = node} download style={{ display: 'none' }}>link</a>
            </Toolbar>
            <Row gutter={8} style={{ paddingLeft: 54, paddingRight: 70, marginBottom: 10 }}>
                <Col span={5}><strong>Блок</strong></Col>
                <Col span={5}><strong>Драйвер</strong></Col>
                <Col span={14}><strong>№ Вопрос</strong></Col>
            </Row>
            { this.props.items.length ?
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId='droppable'>
                        { provided =>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                { this.props.items.map((item, index) =>
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        { provided =>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{ ...provided.draggableProps.style, marginBottom: 15 }}>
                                                <Item>
                                                    <Handler {...provided.dragHandleProps}>
                                                        <MenuOutlined />
                                                    </Handler>
                                                    <ItemContent>
                                                        <Row gutter={8} type='flex' align='middle' style={{ width: '100%' }}>
                                                            <Col span={5}>{ item.block }</Col>
                                                            <Col span={5}>{ item.name }</Col>
                                                            <Col span={14}>
                                                                { item.questions.length ?
                                                                    item.questions.map((question, i) =>
                                                                        <div key={`question-${i}`}>{ this.getNum(index, i) }. { question }</div>
                                                                    ) : <span>{ this.getNum(index, 0) }.</span>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <DriverActions
                                                            {...this.props}
                                                            item={item}
                                                            index={index} />
                                                    </ItemContent>
                                                </Item>
                                            </div>
                                        }
                                    </Draggable>
                                )}
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                </DragDropContext> :
                <div style={{ textAlign: 'center' }}>Нет добавленных вопросов</div>
            }
        </Wrapper>;
    }
}

export default withState('items', 'setItems', pathOr([], ['project', 'drivers']))(
    withAsyncHandlers(props => ({
        [props.getAction || 'getProject']: {
            successHandler: ({ project, setItems }) => setItems(pathOr([], ['drivers'], project)),
            errorHandler: ({ project, setItems }) => setItems(pathOr([], ['drivers'], project))
        }
    }))(
        asyncConnect(props => ({
            patchProject: (props.patchAction || patchProject)
                .withParams(() => ({ type: 'delete' }))
                .withSuccessHandler(() => message.success('Вопрос успешно удален'))
                .withErrorHandler(() => message.success('Не удалось удалить вопрос'))
                .withOptions({ resetOnUnmount: true })
        }), null, { openDriverModal, openDriverSettingsModal, openProjectPreviewModal, openImportQuestionsModal })(ProjectDrivers)
    )
);
