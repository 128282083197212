import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styled, { css } from 'styled-components';
import { path } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import LanguagesSelector from './LanguagesSelector';

const Container = styled.div`
    display: flex;
    .ant-btn-icon-only {
        margin-left: 10px;
    }
`;

const Wrapper = styled.div`
    ${({ inline }) => inline && css`
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 0 10px;
        min-height: 32px;
        width: ${({ width }) => width ? `${width}px` : '100%'};
        .mce-edit-focus {
            outline: none;
        }
        p {
            margin-bottom: 0;
        }
        .mce-content-body {
            line-height: 1.5;
            padding-top: 5px;
        }
    `}
    ${({ disabled }) => disabled && css`
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        cursor: not-allowed;
        opacity: 1;
    `}
`;

export class EditorComponent extends Component {
    render() {
        const { input, onChange, languages, onSelectLanguage, currentLanguage, inline, translations, disabled, width } = this.props;
        const value = this.props.value || path(['value'], input);

        return <Container>
            <Wrapper inline={inline} width={width} disabled={disabled}>
                <Editor
                value={value}
                onEditorChange={onChange}
                inline={inline}
                init={{
                    plugins: 'link image code lists',
                    language: 'ru',
                    menubar: false,
                    forced_root_block: false,
                    language_url: '/translations/ru.js',
                    toolbar: inline ? 'undo redo | bold italic underline | image code | removeformat' : 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code blockquote | removeformat | language',
                    images_upload_handler: (info, success) => {
                        const fr = new FileReader();

                        fr.readAsDataURL(info.blob());
                        fr.onload = () => success(fr.result);
                    },
                    setup: editor => {
                        if (languages && languages.length) {
                            editor.ui.registry.addMenuButton('language', {
                                icon: 'translate',
                                classes: 'asdas',
                                fetch: callback => {
                                    const items = languages.map(([ id, text ]) => ({
                                        type: 'menuitem',
                                        text: currentLanguage === id ? `✔ ${text}` : text,
                                        onAction: () => onSelectLanguage && onSelectLanguage(id)
                                    }));

                                    callback(items);
                                }
                            })
                        }

                        if (disabled) {
                            editor.mode.set('readonly');
                        }

                        inline && editor.on('keydown', function (e) {
                            if (e.keyCode === 13) {
                                e.preventDefault();
                            }
                        });
                    }
                }} />
            </Wrapper>
            { translations && <LanguagesSelector name={input.name} editable /> }
        </Container>;
    }
}

export default withFieldWrapper(EditorComponent);
