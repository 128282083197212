import React, { Component, Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button, Form, Popconfirm } from 'antd';
import { PlusOutlined, MenuOutlined, PieChartOutlined, BarChartOutlined, TableOutlined, EditOutlined, DeleteOutlined, SnippetsOutlined, FileImageOutlined } from '@ant-design/icons';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import uniqid from 'uniqid';
import styled from 'styled-components';
import { contains, path, propEq, find, assocPath, findIndex, pathOr, drop, flatten } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import { openProjectPdfTemplatePageModal, openProjectPdfTemplateModal } from '../../actions/modalActions';
import { ChartBlock, StyledCard } from '../user/projects/ProjectPdfGenerator';
import { REPORT_TYPES } from '../../constants/charts';
import { patchProject } from '../../actions/asyncActions';
import { reorder } from '../../utils/dnd';

const DragHandler = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
`;

const ChartItem = styled.div`
    display: flex;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
`;

const ChartItemContent = styled.div`
    width: 100%;
    padding: 15px;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

class ProjectPdfTemplatesForm extends Component {
    onDragEnd = (result, fields) => {
        if (!result.destination) {
            return;
        }

        if (result.type === 'templates-main') {
            fields.move(result.source.index, result.destination.index);
        } else {
            const indexes = drop(1, result.type.split('-')).map(i => Number(i));
            const data = reorder(
                path(flatten(indexes.map(i => ([i, 'data']))), fields.value),
                result.source.index,
                result.destination.index
            );
            this.props.form.change('charts', assocPath(flatten(indexes.map(i => ([i, 'data']))), data, fields.value));
        }
    }

    renderChartBlock = chart => {
        const title = chart.type === 'filters' ? `Целевые аудитории (${chart.property})` : path(['value'], find(propEq('id', chart.type), REPORT_TYPES));

        return <ChartBlock reverseIcon={contains('distribution', chart.type || '')}>
            { contains(chart.type, ['dynamic', 'questions-distribution-table']) ? <TableOutlined /> : chart.type === 'filters' ? <PieChartOutlined /> : <BarChartOutlined /> }
            <span> { title } {
                contains(chart.type, ['questions-distribution', 'questions-distribution-table']) && chart.drivers && chart.drivers.length ? <span>({ chart.drivers.reduce((res, cur, index) => `${res}${index > 0 ? ', ' : ''}${cur}`, '') })</span> :
                chart.type !== 'filters' && chart.property && <span> ({ chart.property }{ chart.value ? `, ${chart.value}` : '' })</span>
            }</span>
        </ChartBlock>;
    }

    mapBeforeSubmitTemplate = (value, index) => {
        const { project } = this.props;

        return {
            id: project.id,
            data: [{
                op: 'replace',
                path: `/report/templates/${index}`,
                value
            }]
        };
    }

    renderTemplateLink = page => {
        const { project, openProjectPdfTemplateModal, filters, statuses, drivers } = this.props;
        const index = findIndex(propEq('id', page.template), pathOr([], ['report', 'templates'], project));
        const template = pathOr([], ['report', 'templates'], project)[index];

        return <span
            className='span-link'
            onClick={() => openProjectPdfTemplateModal({
                item: template,
                filters,
                statuses,
                drivers,
                project,
                submitAction: patchProject,
                mapBeforeSubmit: value => this.mapBeforeSubmitTemplate(value, index)
            })}>
            <FileImageOutlined /> { path(['name'], template) }
        </span>;
    }

    renderSegmentItem = ({ fields }, index) => {
        const { filters, statuses, drivers, project, openProjectPdfTemplatePageModal } = this.props;

        return <div>
            <Droppable droppableId={`segment-${index}`} type={`segment-${index}`}>
                { (provided, snapshot) =>
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        { (fields.value || []).map((item, i) =>
                            <Draggable key={`item-${index}-${i}`} draggableId={`item-${index}-${i}`} index={i} type={`item-${index}-${i}`}>
                                { provided =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{ ...provided.draggableProps.style, marginBottom: 20 }}>
                                        <StyledCard isDraggingOver={snapshot.isDraggingOver}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <DragHandler {...provided.dragHandleProps}>
                                                    <MenuOutlined />
                                                </DragHandler>
                                                <div style={{ width: '100%', padding: 20, paddingLeft: 0 }}>
                                                    { this.renderPageItem(item, `${index}-${i}`, `${fields.name}[${i}].data`) }
                                                </div>
                                                <Button.Group style={{ marginRight: 15 }}>
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={() => openProjectPdfTemplatePageModal({
                                                            filters,
                                                            statuses,
                                                            drivers,
                                                            project,
                                                            item,
                                                            segment: true,
                                                            onSubmit: item => fields.update(i, item)
                                                        })} />
                                                    <Popconfirm
                                                        title='Вы уверены, что хотите удалить страницу?'
                                                        okText='Да'
                                                        cancelText='Нет'
                                                        onConfirm={() => fields.remove(i)}>
                                                        <Button
                                                            danger
                                                            icon={<DeleteOutlined />} />
                                                    </Popconfirm>
                                                </Button.Group>
                                            </div>
                                        </StyledCard>
                                    </div>
                                }
                            </Draggable>
                        )}
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
            <Button
                icon={<PlusOutlined />}
                onClick={() => openProjectPdfTemplatePageModal({
                    project,
                    add: true,
                    segment: true,
                    filters,
                    statuses,
                    drivers,
                    onSubmit: item => fields.push(item)
                })}>
                Добавить
            </Button>
        </div>;
    }

    renderPageItem = (page, index, name) => {
        switch (page.type) {
            case 'one':
                return this.renderChartBlock(page.charts[0]);
            case 'two':
                return <div>
                    { this.renderChartBlock(page.charts[0]) }
                    { this.renderChartBlock(page.charts[1]) }
                </div>;
            case 'three':
                return <div>
                    { this.renderChartBlock(page.charts[0]) }
                    { this.renderChartBlock(page.charts[1]) }
                    { this.renderChartBlock(page.charts[2]) }
                </div>;
            case 'questions-distribution-driver':
                return <ChartBlock reverseIcon>
                    <PieChartOutlined />
                    <BarChartOutlined style={{ marginRight: 5 }} />
                    <span>Распределение ответов по вопросам (по драйверам)</span>
                </ChartBlock>;
            case 'segment':
                return <div>
                        <SnippetsOutlined /> { page.property } ({ page.value.map((item, i) => <span key={`segment-value-${index}-${i}`}>{ item }{ i < page.value.length - 1 ? ', ' : '' }</span>) })
                    { page.template ?
                        this.renderTemplateLink(page) :
                        <FieldArray name={name}>
                            { formProps => this.renderSegmentItem(formProps, index) }
                        </FieldArray>
                    }
                </div>
            default:
                return null;
        }
    }

    renderTemplates = ({ fields }) => {
        const { openProjectPdfTemplatePageModal, filters, statuses, drivers, project } = this.props;

        return <div>
            <DragDropContext onDragEnd={result => this.onDragEnd(result, fields)}>
                <Droppable droppableId='templates-droppable' type='templates-main'>
                    { provided =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            { (fields.value || []).map((item, index) =>
                                <Draggable key={`template-${index}`} draggableId={`template-${index}`} index={index} type='templates-main'>
                                    { provided =>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={{ ...provided.draggableProps.style, marginBottom: 20 }}>
                                            <ChartItem>
                                                <DragHandler {...provided.dragHandleProps}>
                                                    <MenuOutlined />
                                                </DragHandler>
                                                <ChartItemContent>
                                                    { this.renderPageItem(item, index, `${fields.name}[${index}].data`) }
                                                    <Button.Group style={{ marginLeft: 15 }}>
                                                        <Button
                                                            icon={<EditOutlined />}
                                                            onClick={() => openProjectPdfTemplatePageModal({
                                                                filters,
                                                                statuses,
                                                                drivers,
                                                                project,
                                                                item,
                                                                onSubmit: item => fields.update(index, item)
                                                            })} />
                                                        <Popconfirm
                                                            title='Вы уверены, что хотите удалить страницу?'
                                                            okText='Да'
                                                            cancelText='Нет'
                                                            onConfirm={() => fields.remove(index)}>
                                                            <Button
                                                                danger
                                                                icon={<DeleteOutlined />} />
                                                        </Popconfirm>
                                                    </Button.Group>
                                                </ChartItemContent>
                                            </ChartItem>
                                        </div>
                                    }
                                </Draggable>
                            )}
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </DragDropContext>
            <Button
                style={{ marginBottom: 15 }}
                icon={<PlusOutlined />}
                onClick={() => openProjectPdfTemplatePageModal({
                    filters,
                    statuses,
                    drivers,
                    project,
                    onSubmit: item => fields.push(item)
                })}>
                Добавить страницу
            </Button>
        </div>;
    }

    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Form.Item label='Страницы' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='charts'>
                    { this.renderTemplates }
                </FieldArray>
            </Form.Item>
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default connect(null, { openProjectPdfTemplatePageModal, openProjectPdfTemplateModal })(
    withFormWrapper(ProjectPdfTemplatesForm, {
        mapPropsToValues: ({ item }) => item,
        validationSchema,
        mapBeforeSubmit: (values, { mapBeforeSubmit }) => {
            const value = {
                ...values,
                id: values.id || uniqid()
            };

            return mapBeforeSubmit ? mapBeforeSubmit(value) : value;
        }
    })
);
