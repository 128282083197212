import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';
import { toPairs, filter, contains, keys, find, propEq, pathOr, all, is } from 'ramda';
import { FieldArray } from 'react-final-form-arrays';
import { Button, Form } from 'antd';
import styled from 'styled-components';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import { REPORT_TYPES, SEGMENT_REPORT_TYPES, LEGEND_POSITIONS } from '../../constants/charts';
import ListenerField from './ListenerField';
import { getReportFilters } from '../../actions/asyncActions';
import ColorPicker, { ColorPickerField } from './formComponents/ColorPicker';
import Switch from './formComponents/Switch';
import Input from './formComponents/Input';
import NumberInput from './formComponents/NumberInput';
import DynamicsDndList from './formComponents/DynamicsDndList';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import FilterDndList from './formComponents/FilterDndList';

export const ColorField = styled.div`
    display: inline-flex;
    margin-right: 15px;
    .ant-btn {
        height: 34px;
        border-radius: 0 4px 4px 0;
        margin-top: -1px;
    }
`;

class PdfChartForm extends Component {
    onChangeProperty = () => {
        this.props.form.change('value', null);
    }

    renderColorFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <ColorField key={`color-${index}`}>
                    <Field
                        name={name}
                        component={ColorPicker} />
                    <Button icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </ColorField>
            )}
            <div>
                <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>Добавить цвет</Button>
            </div>
        </div>;
    }

    onChangeType = type => {
        if (type !== 'filters') {
            this.props.form.change('colors', null);
        }

        this.props.form.batch(() => {
            !this.props.segment && type && this.props.form.change('title', find(propEq('id', type), REPORT_TYPES).value);
            this.props.form.change('value', null);
        });
    }

    onChangeSliceByProperty = () => {
        this.props.form.change('sliceValues', null);
    }

    renderQuestionsDistributionSumFields = ({ fields }) => {
        const { drivers } = this.props;
        const groups = keys(pathOr({}, [1, 1], toPairs(pathOr({}, [0, 1],toPairs(drivers))))).map(value => ({ value, id: value }));

        return <div>
            { fields.map((name, index) =>
                <div key={`sum-field-${index}`}>
                    <Field
                        name={`${name}.title`}
                        component={Input}
                        label='Заголовок' />
                    <Field
                        name={`${name}.groups`}
                        component={Select}
                        label='Значения'
                        options={groups}
                        isMulti />
                    <Field
                        name={`${name}.headerColor`}
                        component={ColorPickerField}
                        label='Цвет фона заголовка' />
                    <Field
                        name={`${name}.valueColor`}
                        component={ColorPickerField}
                        label='Цвет значения' />
                    <Button icon={<DeleteOutlined />} danger onClick={() => fields.remove(index)} style={{ marginBottom: 15 }}>
                        Удалить группу
                    </Button>
                </div>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push({})} style={{ marginBottom: 15 }}>
                Добавить группу
            </Button>
        </div>
    }

    render() {
        const { getReportFilters: { data, meta }, segment, type, drivers, project, form } = this.props;

        return <Fragment>
            <Field
                name='type'
                component={Select}
                label='Тип'
                options={segment ? SEGMENT_REPORT_TYPES : (type === 'one' ? REPORT_TYPES : filter(({ id }) => !contains(id, 'dynamic', 'questions-distribution-table', 'drivers-extended'), REPORT_TYPES))}
                onChange={this.onChangeType} />
            <Field name='title' component={() => null} />
            <ListenerField listenFieldName='type'>
                { ({ type }) => type &&
                    <Fragment>
                        <Field
                            name='title'
                            component={Input}
                            label='Заголовок' />
                        { !segment && !contains(type, ['questions-distribution', 'questions-distribution-table']) &&
                            <Fragment>
                                <Field
                                    name='property'
                                    component={Select}
                                    label='Сегмент'
                                    loading={meta.pending}
                                    options={toPairs(data).map(([ value ]) => ({ id: value, value }))}
                                    onChange={this.onChangeProperty}
                                    allowClear={!contains(type, ['filters', 'dynamic'])} />
                                { type === 'dynamic' &&
                                    <Field
                                        name='propertyName'
                                        component={Input}
                                        label='Название сегмента в заголовке' />
                                }
                                <ListenerField listenFieldName={'property'}>
                                    { ({ property }) => property && !contains(type, ['filters', 'dynamic']) &&
                                        <Field
                                            name='value'
                                            component={Select}
                                            label='Значение'
                                            loading={meta.pending}
                                            options={data[property]}
                                            namePath='_id'
                                            valuePath='_id'
                                            isMulti={contains(type, ['block', 'drivers'])}
                                            allowClear />
                                    }
                                </ListenerField>
                            </Fragment>
                        }
                        { type === 'filters' &&
                            <Fragment>
                                 <Field
                                    name='sliceByProperty'
                                    component={Select}
                                    label='Фильтр по сегменту'
                                    loading={meta.pending}
                                    options={toPairs(data).map(([ value ]) => ({ id: value, value }))}
                                    onChange={this.onChangeSliceByProperty}
                                    allowClear />
                                <ListenerField listenFieldName='sliceByProperty'>
                                    { ({ sliceByProperty }) => sliceByProperty &&
                                        <Field
                                            name='sliceValues'
                                            component={Select}
                                            label='Фильтр по значению'
                                            loading={meta.pending}
                                            options={data[sliceByProperty]}
                                            namePath='_id'
                                            valuePath='_id'
                                            isMulti
                                            allowClear />
                                    }
                                </ListenerField>
                            </Fragment>
                        }
                        { type === 'drivers' &&
                            <Field
                                name='vertical'
                                component={Switch}
                                label='Отображать по вертикали' />
                        }
                        { contains(type, ['drivers', 'drivers-distribution']) &&
                            <Field
                                name='grouped'
                                component={Switch}
                                label='Группировать по блокам' />
                        }
                        { contains(type, ['questions-distribution', 'questions-distribution-table']) &&
                            <Field
                                name='drivers'
                                component={Select}
                                label='Драйверы'
                                options={keys(drivers).map(value => ({ value, id: value }))}
                                isMulti />
                        }
                        { type === 'questions-distribution-table' &&
                            <Fragment>
                                <Field
                                    name='fontSize'
                                    component={NumberInput}
                                    label='Размер шрифта' />
                                <Field
                                    name='absolute'
                                    component={Switch}
                                    label='Выводить абсолютные значения' />
                                <Field
                                    name='driverLeft'
                                    component={Switch}
                                    label='Вывести драйвер слева' />
                                <h3>Суммирования</h3>
                                <FieldArray name='sum'>
                                    { this.renderQuestionsDistributionSumFields }
                                </FieldArray>
                            </Fragment>
                        }
                        { type === 'questions-distribution' &&
                            <Fragment>
                                <Field
                                    name='hideLegend'
                                    component={Switch}
                                    label='Скрыть легенду' />
                                <Field
                                    name='absolute'
                                    component={Switch}
                                    label='Выводить абсолютное значение' />
                                <ListenerField listenFieldName='absolute'>
                                    { ({ absolute }) => absolute &&
                                        <Field
                                            name='absoluteMin'
                                            component={NumberInput}
                                            label='Не выводить значения меньше' />
                                    }
                                </ListenerField>
                                <Field
                                    name='percent'
                                    component={Switch}
                                    label='Выводить процент' />
                                <ListenerField listenFieldName='percent'>
                                    { ({ percent }) => percent &&
                                        <Field
                                            name='percentMin'
                                            component={NumberInput}
                                            label='Не выводить значения меньше' />
                                    }
                                </ListenerField>
                            </Fragment>
                        }
                        { type === 'dynamic' &&
                            <Fragment>
                                <Field
                                    name='fontSize'
                                    component={NumberInput}
                                    label='Размер шрифта' />
                                <Field
                                    name='bold'
                                    component={Switch}
                                    label='Жирное начертание' />
                                <Field
                                    name='commonResult'
                                    component={Switch}
                                    label='Показывать общий результат' />
                                <Field
                                    name='numberAmount'
                                    component={NumberInput}
                                    label='Количество знаков после запятой' />
                                <Field
                                    name='colorized'
                                    component={Switch}
                                    label='Раскрашивать таблицу' />
                                <ListenerField listenFieldName='property'>
                                    { (({ property }) => property &&
                                        <Form.Item label='Колонки' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                                            <FieldArray name='columns'>
                                                { props => <DynamicsDndList {...props} form={form} property={property} project={project} /> }
                                            </FieldArray>
                                        </Form.Item>
                                    )}
                                </ListenerField>
                            </Fragment>
                        }
                    </Fragment>
                }
            </ListenerField>
            <ListenerField listenFieldName={['type', 'value']}>
                { ({ type, value }) => contains(type, ['block', 'drivers']) && !!(value || []).length ? (
                        <Fragment>
                            <Field
                                name='showCommon'
                                component={Switch}
                                label='Показывать общий результат' />
                            <ListenerField listenFieldName='showCommon'>
                                { ({ showCommon }) =>
                                    showCommon &&
                                        <Field
                                            name='commonColumn'
                                            component={Switch}
                                            label='Показывать общий результат колонкой' />
                                }
                            </ListenerField>
                        </Fragment>
                    ) : type === 'drivers' && segment && (
                        <Field
                            name='showCommon'
                            component={Switch}
                            label='Показывать общий результат' />
                    )
                }
            </ListenerField>
            <ListenerField listenFieldName='type'>
                { ({ type }) => type === 'filters' &&
                    <Fragment>
                        <Field
                            name='defaultLegendName'
                            component={Input}
                            label='Значение по умолчанию для легенды' />
                        <Field
                            name='legendFontSize'
                            component={Input}
                            label='Размер шрифта в легенде' />
                        <Field
                            name='legendPosition'
                            component={Select}
                            label='Позиционирование значений'
                            options={LEGEND_POSITIONS} />
                        <Field
                            name='absolute'
                            component={Switch}
                            label='Выводить абсолютное значение' />
                        <Form.Item label='Палитра' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                            <FieldArray name='colors'>
                                { this.renderColorFields }
                            </FieldArray>
                        </Form.Item>
                        <ListenerField listenFieldName='property'>
                            { (({ property }) => property &&
                                <Field
                                    name='columns'
                                    component={FilterDndList}
                                    label='Колонки'
                                    property={property}
                                    project={project} />
                            )}
                        </ListenerField>
                    </Fragment>
                }
            </ListenerField>
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = props => props.segment ? yup.object() : yup.object().shape({
    type: yup.string().required(),
    property: yup.string().when('type', (type, schema) => contains(type, ['filters', 'dynamic']) ? schema.required() : schema).nullable(),
    sliceByProperty: yup.string().nullable(),
    sliceValues: yup.array().nullable().when('sliceByProperty', (sliceByProperty, schema) => sliceByProperty ? schema.required().min(1) : schema),
    sum: yup.array().nullable().when('type', (type, schema) => type === 'questions-distribution-table' ? schema.of(
        yup.object().shape({
            title: yup.string().nullable().required()
        })
    ) : schema)
});

export default withAsyncActions({
    getReportFilters: getReportFilters
        .withPayload(({ project }) => ({ id: project }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(PdfChartForm, {
        validationSchema,
        mapPropsToValues: ({ chart }) => ({
            ...chart,
            ...(chart.type === 'dynamic' && all(c => is(String, c), chart.columns || []) ? { columns: chart.columns.map(name => ({ name, originalName: name, show: true })) } : {})
        }),
        mapBeforeSubmit: values => {
            const colors = filter(color => !!color, values.colors || []);

            return {
                ...values,
                colors: colors.length ? colors : null
            };
        }
    })
);
