import Highcharts from 'highcharts';
import { sortBy, reverse, prop, map, filter, toPairs, any, path } from 'ramda';
import GroupedCategories from 'highcharts-grouped-categories';
import HighchartsExporting from 'highcharts/modules/exporting';

HighchartsExporting(Highcharts);

export const GroupedCategoriesHighcharts = GroupedCategories(Highcharts);

export const getSortedData = (data, sort) => {
    if (sort) {
        const sorted = sortBy(prop(1), data);

        return sort === 'asc' ? sorted : reverse(sorted);
    } else {
        return data;
    }
}

export const getQuestionsItemDisribution = item => filter(obj => any(([_, value]) => !!path(['percent'], value), toPairs(obj)), item || []);
export const filterQuestionsDistribution = data => map(getQuestionsItemDisribution, data);
