import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'antd';
import { pathOr, is, path } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import CssEditor from './formComponents/CssEditor';
import ColorPicker from './formComponents/ColorPicker';
import { parseToPatch } from '../../utils/forms';

class ProjectExtendedForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='settings.theme.css'
                component={CssEditor}
                label='Стили' />
            <h3>Палитра шкалы</h3>
            { this.props.data.scale.map((name, index) =>
                <Row key={`color-${index}`}>
                    <Col span={8} style={{ paddingTop: 5 }}>
                        { name }:
                    </Col>
                    <Col span={16}>
                        <Field
                            key={`color-${index}`}
                            name={`settings.theme.scaleColor.${name}`}
                            component={ColorPicker}
                            label={name} />
                    </Col>
                </Row>
            )}
            <SubmitButton style={{ marginTop: 15 }}>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(ProjectExtendedForm, {
    mapPropsToValues: ({ data }) => ({
        ...data,
        settings: {
            ...data.settings,
            theme: {
                ...pathOr({}, ['settings', 'theme'], data),
                scaleColor: is(Array, path(['settings', 'theme', 'scaleColor'], data)) ? {} : pathOr({}, ['settings', 'theme', 'scaleColor'], data)
            }
        }
    }),
    mapBeforeSubmit: (values, props) =>
        parseToPatch(values, props.item, path(['settings', 'theme'], props.item) ? [
            'settings.theme.css',
            'settings.theme.scaleColor'
        ] : ['settings.theme'])
});
