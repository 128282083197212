import { takeEvery } from 'redux-saga/effects';

import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';
import createAsyncAction from '../utils/createAsyncAction';

export const getUser = createAsyncAction(actions.GET_USER, handlers.getUserHandler);
export const postLogin = createAsyncAction(actions.POST_LOGIN, handlers.postLoginHandler);
export const postRecovery = createAsyncAction(actions.POST_RECOVERY, handlers.postRecoveryHandler);

export const getProjects = createAsyncAction(actions.GET_PROJECTS, handlers.getProjectsHandler);
export const getProject = createAsyncAction(actions.GET_PROJECT, handlers.getProjectHandler);
export const postProject = createAsyncAction(actions.POST_PROJECT, handlers.postProjectHandler);
export const putProject = createAsyncAction(actions.PUT_PROJECT, handlers.putProjectHandler);
export const patchProject = createAsyncAction(actions.PATCH_PROJECT, handlers.patchProjectHandler);
export const importProject = createAsyncAction(actions.IMPORT_PROJECT, handlers.importProjectHandler);
export const deleteProject = createAsyncAction(actions.DELETE_PROJECT, handlers.deleteProjectHandler);

export const getCompanies = createAsyncAction(actions.GET_COMPANIES, handlers.getCompaniesHandler);
export const postCompany = createAsyncAction(actions.POST_COMPANY, handlers.postCompanyHandler);
export const putCompany = createAsyncAction(actions.PUT_COMPANY, handlers.putCompanyHandler);
export const getCompany = createAsyncAction(actions.GET_COMPANY, handlers.getCompanyHandler);

export const getRespondentsStatuses = createAsyncAction(actions.GET_RESPONDENTS_STATUSES, handlers.getRespondentsStatusesHandler);

export const getRespondents = createAsyncAction(actions.GET_RESPONDENTS, handlers.getRespondentsHandler);
export const postRespondent = createAsyncAction(actions.POST_RESPONDENT, handlers.postRespondentHandler);
export const putRespondent = createAsyncAction(actions.PUT_RESPONDENT, handlers.putRespondentHandler);
export const deleteRespondents = createAsyncAction(actions.DELETE_RESPONDENTS, handlers.deleteRespondentsHandler);
export const deleteRespondent = createAsyncAction(actions.DELETE_RESPONDENT, handlers.deleteRespondentHandler);
export const getRespondent = createAsyncAction(actions.GET_RESPONDENT, handlers.getRespondentHandler);

export const postRespondentImport = createAsyncAction(actions.POST_RESPONDENTS_IMPORT, handlers.postRespondentImportHandler);

export const postMessages = createAsyncAction(actions.POST_MESSAGES, handlers.postMessagesHandler);

export const getQuestionnaires = createAsyncAction(actions.GET_QUESTIONNAIRES, handlers.getQuestionnairesHandler);
export const getQuestionnaire = createAsyncAction(actions.GET_QUESTIONNAIRE, handlers.getQuestionnaireHandler);
export const postQuestionnaire = createAsyncAction(actions.POST_QUESTIONNAIRE, handlers.postQuestionnaireHandler);
export const putQuestionnaire = createAsyncAction(actions.PUT_QUESTIONNAIRE, handlers.putQuestionnaireHandler);
export const deleteQuestionnaires = createAsyncAction(actions.DELETE_QUESTIONNAIRES, handlers.deleteQuestionnairesHandler);
export const patchQuestionnaire = createAsyncAction(actions.PATCH_QUESTIONNAIRE, handlers.patchQuestionnaireHandler);

export const postMessagesCompany = createAsyncAction(actions.POST_MESSAGES_COMPANY, handlers.postMessagesCompanyHandler);

export const getReportBlockTotal = createAsyncAction(actions.GET_REPORT_BLOCK_TOTAL, handlers.getReportBlockTotalHandler, {}, takeEvery);
export const getReportDriverTotal = createAsyncAction(actions.GET_REPORT_DRIVER_TOTAL, handlers.getReportDriverTotalHandler, {}, takeEvery);
export const getReportBlockDistribution = createAsyncAction(actions.GET_REPORT_BLOCK_DISTRIBUTION, handlers.getReportBlockDistributionHandler, {}, takeEvery);
export const getReportDriverDistribution = createAsyncAction(actions.GET_REPORT_DRIVER_DISTRIBUTION, handlers.getReportDriverDistributionHandler, {}, takeEvery);
export const getReportFilters = createAsyncAction(actions.GET_REPORT_FILTERS, handlers.getReportFiltersHandler, {}, takeEvery);
export const getDriverImportance = createAsyncAction(actions.GET_DRIVER_IMPORTANCE, handlers.getDriverImportanceHandler);
export const getReportTable = createAsyncAction(actions.GET_REPORT_TABLE, handlers.getReportTableHandler);
export const getReportQuestionsDistribution = createAsyncAction(actions.GET_REPORT_QUESTIONS_DISTRIBUTION, handlers.getReportQuestionsDistributionHandler);

export const deleteFile = createAsyncAction(actions.DELETE_FILE, handlers.deleteFileHandler, {}, takeEvery);
export const getFileDownload = createAsyncAction(actions.GET_FILE_DOWNLOAD, handlers.getFileDownloadHandler);

export const getReports = createAsyncAction(actions.GET_REPORTS, handlers.getReportsHandler);
export const getReport = createAsyncAction(actions.GET_REPORT, handlers.getReportHandler);
export const postReport = createAsyncAction(actions.POST_REPORT, handlers.postReportHandler);
export const putReport = createAsyncAction(actions.PUT_REPORT, handlers.putReportHandler);
export const patchReport = createAsyncAction(actions.PATCH_REPORT, handlers.patchReportHandler);
export const deleteReport = createAsyncAction(actions.DELETE_REPORT, handlers.deleteReportHandler);

export const getPublicReports = createAsyncAction(actions.GET_PUBLIC_REPORTS, handlers.getPublicReportsHandler);
