import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { postMessages } from '../../actions/asyncActions';
import { message } from 'antd';
import SendRespondentLetterForm from '../forms/SendRespondentLetterForm';

class SendRespondentLetterModal extends Component {
    render() {
        const { modal, params: { item }, postMessages } = this.props;

        return <Modal
            {...modal}
            title='Отправка писем'
            footer={null}>
            <SendRespondentLetterForm
                item={item}
                formAction={postMessages} />
        </Modal>;
    }
}

export default withAsyncActions({
    postMessages: postMessages
        .withSuccessHandler(({ close, params: { onSuccess }}) => {
            message.success('Письмо успешно отправлено');
            onSuccess && onSuccess();
            close();
        })
        .withErrorHandler(() => message.error('Не удалось отправить письмо'))
        .withOptions({ resetOnUnmount: true })
})(SendRespondentLetterModal);
