import React, { Component } from 'react';
import { ConfigProvider } from 'antd';
import { SagaProvider } from 'react-async-client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { Switch } from 'react-router-dom';
import ruRU from 'antd/lib/locale-provider/ru_RU';

import { sagaMiddleware, history } from '../store/middlewares';
import SecureLayout from './layout/SecureLayout';
import { pushRollbarError } from '../utils/rollbar';
import PublicReport from './PublicReport';
import Route from './Route';

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
    }
    html, body, #root, .ant-layout {
        height: 100%;
        font-family: Arial, sans-serif;
    }
    .variable-text {
        color: rgb(24, 144, 255);
    }
    .ant-form-item {
        margin-bottom: 15px;
    }
    .tox-notifications-container,
    .tox-statusbar__branding {
        display: none;
    }
    .tox-statusbar {
        display: none !important;
    }
    .highcharts-exporting-group {
        display: none;
    }
    .tox-tinymce-inline {
        z-index: 2000;
    }
    .ant-input-affix-wrapper {
        padding: 0;
        position: relative;
        .ant-input-prefix {
            position: absolute;
            top: 50%;
            z-index: 2;
            display: flex;
            align-items: center;
            color: rgba(0,0,0,.65);
            line-height: 0;
            transform: translateY(-50%);
            left: 9px;
        }
        input.ant-input {
            padding: 4px 11px 4px 30px;
        }
    }
    .ant-input-search.ant-input-affix-wrapper {
        border: none;
        .ant-input-suffix {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
        .ant-input-search-icon:before {
            display: none;
        }
        input {
            padding: 4px 30px 4px 11px;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
        }
    }

    .ant-form {
        width: 100%;
    }

    .ant-tabs-nav-wrap {
        padding-left: 15px;
        padding-right: 15px;
    }

    .ant-tabs-top > .ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-collapse-borderless {
        background: #fff;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .tox.tox-silver-sink {
        z-index: 2100;
    }

    .span-link {
        color: #1890ff;
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
            color: #40a9ff;
        }
    }
`;

export default class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ error: true });
        pushRollbarError(error, errorInfo);
    }

    render() {
        const { store } = this.props;

        return this.state.error ?
            <div>Не удалось запустить приложение</div> :
            <ConfigProvider locale={ruRU}>
                <GlobalStyles />
                <SagaProvider sagaMiddleware={sagaMiddleware}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path='/report/:id/:key?' component={PublicReport} />
                                <SecureLayout />
                            </Switch>
                        </ConnectedRouter>
                    </Provider>
                </SagaProvider>
            </ConfigProvider>;
    }
}
