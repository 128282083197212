import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { withAsyncActions } from 'react-async-client';
import { FieldArray } from 'react-final-form-arrays';
import { Button, message } from 'antd';
import styled from 'styled-components';
import { toPairs, fromPairs } from 'ramda';
import * as yup from 'yup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PlusOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';

import Input from './formComponents/Input';
import ImageUpload from './formComponents/ImageUpload';
import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import { PDF_FONTS } from '../../constants/charts';
import FooterVariablesField from './formComponents/FooterVariablesField';
import { getRespondentsStatuses, getReportFilters } from '../../actions/asyncActions';
import ListenerField from './ListenerField';

const VariableField = styled.div`
    display: flex;
    .ant-form-item {
        width: 100%;
    }
    .ant-btn {
        margin-left: 10px;
        width: 60px;
    }
`;

const LinkField = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
    .ant-form-item {
        width: 100%;
        margin-bottom: 0;
    }
    .ant-btn {
        margin-left: 10px;
    }
`;

class ReportSettingsForm extends Component {
    renderVarsFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <VariableField key={name}>
                    <Field
                        name={`${name}.key`}
                        component={Input}
                        hideErrorMsg />
                    <span style={{ padding: '5px 10px' }}>:</span>
                    <Field
                        name={`${name}.variable`}
                        component={Input}
                        hideErrorMsg />
                    <Button danger icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </VariableField>
            )}
            <Button
                icon={<PlusOutlined />}
                onClick={() => fields.push({})}
                style={{ marginBottom: 15 }}>
                Добавить Переменную
            </Button>
        </div>
    }

    getReportLink = key => `${window.location.origin}/report/${this.props.data.id}/${key}`;

    onCopyLink = () => message.success('Ссылка успешно скопирована в буфер обмена');

    renderVariableFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <div key={name}>
                    <LinkField>
                        <Field
                            name={`${name}.key`}
                            component={Input}
                            label='Ссылка' />
                        <ListenerField listenFieldName={`variables.${index}.key`}>
                            { ({ variables }) =>
                                variables[index].key ?
                                    <CopyToClipboard text={this.getReportLink(variables[index].key)} onCopy={this.onCopyLink}>
                                        <Button icon={<LinkOutlined />} />
                                    </CopyToClipboard> : null
                            }
                        </ListenerField>
                    </LinkField>
                    <h4>Переменные</h4>
                    <FieldArray name={`${name}.vars`}>
                        { this.renderVarsFields }
                    </FieldArray>
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        style={{ marginBottom: 15 }}
                        onClick={() => fields.remove(index)}>
                        Удалить ссылку
                    </Button>
                </div>
            )}
            <Button
                icon={<PlusOutlined />}
                onClick={() => fields.push()}
                style={{ marginBottom: 15 }}>
                Добавить ссылку
            </Button>
        </div>;
    }

    render() {
        const { getRespondentsStatuses, getReportFilters, serverErrors } = this.props;
        return <Fragment>
            <Field
                name='settings.title'
                component={Input}
                label='Заголовок отчета' />
            <Field
                name='settings.logo'
                component={ImageUpload}
                label='Логотип' />
            <Field
                name='settings.font'
                component={Select}
                label='Шрифт'
                options={PDF_FONTS} />
            <FooterVariablesField
                {...this.props}
                name='settings.footer'
                statuses={getRespondentsStatuses.data}
                filters={getReportFilters.data} />
            <h3>Ссылки</h3>
            <FieldArray name='variables'>
                { this.renderVariableFields }
            </FieldArray>
            { serverErrors.variables && <div style={{ color: 'rgb(245,34,45)', marginBottom: 15 }}>{ serverErrors.variables }</div> }
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    variables: yup.array().of(
        yup.object().shape({
            key: yup.string().required(),
            vars: yup.array().of(
                yup.object().shape({
                    key: yup.string().required(),
                    variable: yup.string().required()
                })
            )
        })
    )
});

export default withAsyncActions({
    getReportFilters: getReportFilters
        .withPayload(({ project }) => ({ id: project }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getRespondentsStatuses: getRespondentsStatuses
        .withPayload(({ project }) => ({
            q: { project }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
})(
    withFormWrapper(ReportSettingsForm, {
        validationSchema,
        mapPropsToValues: ({ data }) => ({
            ...data,
            variables: toPairs(data.variables).map(([ key, vars ]) => ({ key, vars: toPairs(vars || {}).map(([ vKey, variable ]) => ({ key: vKey, variable })) }))
        }),
        mapBeforeSubmit: values => ({
            ...values,
            variables: fromPairs((values.variables || []).map(({ key, vars }) => ([ key, fromPairs((vars || []).map(item => ([ item.key, item.variable ]))) ])))
        })
    })
);
