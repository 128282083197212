import React from 'react';

import BaseFilter from './BaseFilter';
import { getRespondents } from '../../../actions/asyncActions';
import withFilterForm from '../../hocs/withFilterForm';

export default withFilterForm(() =>
    <BaseFilter
        action={getRespondents}
        searchFieldName='name'
        searchFieldPlaceholder='Поиск'
        span={24}>
    </BaseFilter>
);
