import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getCompanies } from '../../../actions/asyncActions';
import CompanyFilter from '../../forms/filters/CompanyFilter';
import { openCompanyModal } from '../../../actions/modalActions';
import { POST_COMPANY } from '../../../constants/actionTypes';

class Companies extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (name, { id }) => <Link to={`/companies/${id}/info`}>{ name }</Link>
            },
            {
                dataIndex: 'id',
                key: 'respondents',
                render: id => <Link to={`/companies/${id}/respondents`}>Респонденты</Link>
            }
        ];
    }

    renderButtons = () => {
        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => this.props.openCompanyModal()}>
            Добавить
        </Button>;
    }

    render() {
        return <TableList
            action={getCompanies}
            columns={this.getColumns()}
            filterForm={CompanyFilter}
            buttons={this.renderButtons()}
            refreshActions={[POST_COMPANY]} />
    }
}

export default connect(null, { openCompanyModal })(Companies);
