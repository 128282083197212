import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import ImportProjectForm from '../forms/ImportProjectForm';
import { importProject } from '../../actions/asyncActions';

class ImportProjectModal extends Component {
    render() {
        const { modal, params, importProject } = this.props;

        return <Modal
            {...modal}
            title='Импортировать проект'
            footer={null}>
            <ImportProjectForm
                data={params || {}}
                formAction={importProject} />
        </Modal>;
    }
}

export default withAsyncActions({
    importProject: importProject
        .withSuccessHandler(({ close }) => {
            message.success('Проект был успешно импортирован');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось импортировать проект'))
        .withOptions({ resetOnUnmount: true })
})(ImportProjectModal);
