import React, { Component } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Spin, Button, message, Popconfirm } from 'antd';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { takeEvery } from 'redux-saga/effects';
import { path, find, propEq, contains } from 'ramda';
import { TableOutlined, PieChartOutlined, BarChartOutlined, PlusOutlined, DeleteOutlined, EditOutlined, LinkOutlined, SettingOutlined } from '@ant-design/icons';

import { getReport, patchReport } from '../../../actions/asyncActions';
import { openReportPageModal, openReportSettingsModal, openReportChartModal } from '../../../actions/modalActions';
import { PATCH_REPORT, PUT_REPORT } from '../../../constants/actionTypes';
import { ChartBlock } from './ProjectPdfGenerator';
import { PUBLIC_REPORT_TYPES } from '../../../constants/charts';

const Wrapper = styled.div`
    padding: 15px;
`;

const Toolbar = styled.div`
    text-align: right;
`;

const Empty = styled.div`
    padding: 15px;
    text-align: center;
`;

const ActionButtons = styled.div`
    position: absolute;
    top: -16px;
    right: -16px;
    display: ${({ visible }) => visible ? 'block' : 'none'};
    button {
        margin-left: 5px;
    }
`;

const Page = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    background: #fff;
    margin-top: 20px;
    padding: 15px;
    position: relative;
    &:hover {
        ${ActionButtons} {
            display: inline-block;
        }
    }
`;

const PageNumber = styled.div`
    background: #1890ff;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-right: 5px;
`;

class ProjectPublicReport extends Component {
    state = {
        visiblePopConfirm: null
    };

    onPopConfirmVisibleChange = (visible, id) => {
        this.setState({ visiblePopConfirm: visible ? id : null });
    }

    renderPages = () => {
        const { getReport: { data }, match: { params: { project }}, openReportPageModal, openReportChartModal } = this.props;

        return data.pages.map((page, index) =>
            <Page key={`page-${index}`} className={`page-${index}`}>
                <ActionButtons visible={this.state.visiblePopConfirm === `page-${index}`}>
                    <Button
                        icon={<EditOutlined />}
                        shape='circle'
                        onClick={() => openReportPageModal({ data, project, index })} />
                    <Popconfirm
                        okText='Да'
                        cancelText='Нет'
                        title='Вы уверены, что хотите удалить страницу?'
                        onConfirm={() => this.deletePage(index)}
                        placement='left'
                        getPopupContainer={() => document.querySelector(`.page-${index}`)}
                        onVisibleChange={visible => this.onPopConfirmVisibleChange(visible, `page-${index}`)}>
                        <Button icon={<DeleteOutlined />} danger shape='circle' />
                    </Popconfirm>
                </ActionButtons>
                <h3 style={{ marginBottom: 10 }}><PageNumber>{ index + 1 }</PageNumber> { path(['settings', 'title'], page) }</h3>
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                    { page.charts.map((item, i) => {
                        const chart = path(['dataFilters', 0], item);
                        const filter = chart.filter || {};
                        const title = chart.type === 'report_filters' ? `Целевые аудитории (${filter.property})` : path(['value'], find(propEq('id', chart.type), PUBLIC_REPORT_TYPES));

                        return <div key={`page-${index}-chart-${i}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 5 }}>
                            <ChartBlock reverseIcon={contains('distribution', chart.type || '')}>
                                { chart.type === 'report_table' ? <TableOutlined /> : chart.type === 'report_filters' ? <PieChartOutlined /> : <BarChartOutlined /> }
                                <span> { title } </span>
                            </ChartBlock>
                            <Button.Group style={{ width: 80, textAlign: 'right' }}>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => openReportChartModal({ data, project, pageIndex: index, chartIndex: i })} />
                                <Popconfirm
                                    title='Вы уверены, что хотите удалить график?'
                                    okText='Да'
                                    cancelText='Нет'
                                    placement='left'
                                    onConfirm={() => this.deleteChart(index, i)}>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        danger />
                                </Popconfirm>
                            </Button.Group>
                        </div>
                    })}
                </div>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => openReportChartModal({ data, project, pageIndex: index, add: true })}>
                    Добавить график
                </Button>
            </Page>
        );
    }

    deletePage = index => {
        const { getReport: { data }, deletePage } = this.props;

        deletePage.dispatch({
            id: data.id,
            data: [{
                op: 'remove',
                path: `/pages/${index}`
            }]
        });
    }

    deleteChart = (pageIndex, index) => {
        const { getReport: { data }, deleteChart } = this.props;

        deleteChart.dispatch({
            id: data.id,
            data: [{
                op: 'remove',
                path: `/pages/${pageIndex}/charts/${index}`
            }]
        });
    }

    getReportLink = () => `${window.location.origin}/report/${this.props.match.params.id}`;

    onCopyLink = () => message.success('Ссылка успешно скопирована в буфер обмена');

    render() {
        const { getReport: { meta, data }, openReportPageModal, match, openReportSettingsModal } = this.props;

        return meta.pending && !meta.lastSucceedAt ?
            <Spin /> :
            <Wrapper>
                <Toolbar>
                    <Button.Group>
                        <CopyToClipboard text={this.getReportLink()} onCopy={this.onCopyLink}>
                            <Button icon={<LinkOutlined />}>
                                Ссылка
                            </Button>
                        </CopyToClipboard>
                        <Button
                            icon={<SettingOutlined />}
                            onClick={() => openReportSettingsModal({ data, project: match.params.project })}>
                            Настройки
                        </Button>
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => openReportPageModal({ data, project: match.params.project, add: true })}>
                            Добавить страницу
                        </Button>
                    </Button.Group>
                </Toolbar>
                { (data.pages || []).length ?
                    this.renderPages() :
                    <Empty>Нет данных</Empty>
                }
            </Wrapper>;
    }
}

export default asyncConnect({
    deletePage: patchReport
        .withParams(() => ({ type: 'deletePage' }))
        .withSuccessHandler(() => message.success('Страница успешно удалена'))
        .withErrorHandler(() => message.error('Не удалось удалить старницу'))
        .withOptions({ resetOnUnmount: true }),
    deleteChart: patchReport
        .withParams(() => ({ type: 'deleteChart' }))
        .withSuccessHandler(() => message.success('График успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить график'))
        .withOptions({ resetOnUnmount: true }),
    getReport: getReport
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_REPORT), toSuccess(PATCH_REPORT)], () => {
                getProps().getReport.refresh();
            })
        })
        .withPayload(({ match: { params: { id }}}) => id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openReportPageModal, openReportSettingsModal, openReportChartModal })(ProjectPublicReport);
