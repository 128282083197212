import React, { Component } from 'react';

import Modal from './Modal';
import PdfChartForm from '../forms/PdfChartForm';

export default class PdfChartModal extends Component {
    onSubmit = values => {
        this.props.params.onSubmit(values);
        this.props.close();
    }

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Настройки отчета'
            footer={null}>
            <PdfChartForm
                project={params.project}
                chart={params.chart}
                segment={params.segment}
                type={params.type}
                drivers={params.drivers}
                formAction={this.onSubmit} />
        </Modal>;
    }
}
