import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { List, Input, Button, Tooltip, message, Popconfirm } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { omit } from 'ramda';
import { QuestionCircleOutlined, ExportOutlined, DeleteOutlined, PlusOutlined, ImportOutlined } from '@ant-design/icons';

import { getProjects, getCompanies, deleteProject } from '../../../actions/asyncActions';
import TableList from '../table/TableList';
import { getUrlParams, extendSearchPath, getFilters } from '../../../utils/urlParams';
import ProjectsFilter from '../../forms/filters/ProjectsFilter';
import { openCreateProjectModal, openImportProjectModal } from '../../../actions/modalActions';
import { POST_PROJECT, IMPORT_PROJECT, DELETE_PROJECT } from '../../../constants/actionTypes';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    .ant-list {
        width: 30%;
        margin-right: 15px;
        overflow: hidden;
        & + div {
            width: 70%;
        }
        .ant-list-header {
            background: #fafafa;
            padding: 19px;
        }
    }
`;

const CompanyItem = styled(List.Item)`
    cursor: pointer;
    background: ${({ selected }) => selected ? '#e6f7ff' : '#fff'};
    transition: all .3s;
    &:hover {
        background: #e6f7ff;
    }
`;

class Projects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companySearch: getUrlParams(props.location).companySearch || ''
        };
    }

    exportProject = item => {
        const data = omit(['company', 'id', '_embedded'], item);
        const file = new Blob([JSON.stringify(data, null, 2)], { type: 'text/json' });

        this.link.href = URL.createObjectURL(file);
        this.link.download = `${item.name}.json`;
        this.link.click();
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (name, { id }) => <Link to={`/projects/${id}/info`}>{ name }</Link>
            },
            {
                title: 'Кол-во вопросов',
                dataIndex: 'drivers',
                key: 'amount',
                render: drivers => {
                    const questionsAmount = (drivers || []).reduce((res, cur) => res + (cur.questions || []).length, 0);

                    return <span><QuestionCircleOutlined /> { questionsAmount }</span>;
                }
            },
            {
                key: 'actions',
                align: 'right',
                render: item =>
                    <Button.Group>
                        <Tooltip title='Экспорт'>
                            <Button
                                icon={<ExportOutlined />}
                                onClick={() => this.exportProject(item)} />
                        </Tooltip>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить проект?'
                            okText='Да'
                            cancelText='Нет'
                            placement='left'
                            onConfirm={() => this.props.deleteProject.dispatch(item.id)}>
                            <Button
                                icon={<DeleteOutlined />}
                                danger />
                        </Popconfirm>
                    </Button.Group>
            }
        ];
    }

    selectCompany = company => {
        const { history, location } = this.props;

        history.push(extendSearchPath(location, { filter: JSON.stringify({ ...getFilters(location), company })}));
    }

    renderCompanyItem = item => {
        const selectedCompany = (getFilters(this.props.location) || {}).company;

        return <CompanyItem
            selected={item.id === selectedCompany}
            onClick={() => this.selectCompany(item.id)}>
            { item.name }
        </CompanyItem>;
    }

    onSearchCompany = companySearch => {
        this.props.history.push(extendSearchPath(this.props.location, { companySearch }));
    }

    onChangeCompanySearch = e => {
        this.setState({ companySearch: e.target.value });
    }

    getTableButtons = () => {
        const { openCreateProjectModal, openImportProjectModal } = this.props;
        const { company } = getFilters(this.props.location) || {};

        return <Button.Group>
            <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => openCreateProjectModal({
                    company
                })}>
                Добавить
            </Button>
            <Button
                icon={<ImportOutlined />}
                onClick={() => openImportProjectModal({
                    company
                })}>
                Импорт
            </Button>
        </Button.Group>;
    }

    render() {
        const { getCompanies } = this.props;

        return <Wrapper>
            <List
                style={{ background: '#fff' }}
                dataSource={getCompanies.data.items}
                renderItem={this.renderCompanyItem}
                header={
                    <Input.Search
                        placeholder='Поиск'
                        value={this.state.companySearch}
                        onSearch={this.onSearchCompany}
                        onChange={this.onChangeCompanySearch} />
                }
                bordered />
            <TableList
                action={getProjects}
                columns={this.getColumns()}
                filterForm={ProjectsFilter}
                buttons={this.getTableButtons()}
                refreshActions={[POST_PROJECT, IMPORT_PROJECT, DELETE_PROJECT]}
                defaultSort={{
                    sort_by: 'name',
                    sort_order: 'asc'
                }} />
            <a href='export' ref={node => this.link = node} download style={{ display: 'none' }}>export</a>
        </Wrapper>;
    }
}

export default asyncConnect({
    getCompanies: getCompanies
        .withPayload(({ location }) => ({
            sort_by: {
                name: 'asc'
            },
            q: {
                name: getUrlParams(location).companySearch
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    deleteProject: deleteProject
        .withSuccessHandler(() => message.success('Проект успешно удален'))
        .withErrorHandler(() => message.error('Не удалось удалить проект'))
        .withOptions({ resetOnUnmount: true })
}, null, { openCreateProjectModal, openImportProjectModal })(Projects);
