import React from 'react';
import { filter, contains } from 'ramda';
import { FilePdfOutlined, BarChartOutlined, PieChartOutlined, TableOutlined, SnippetsOutlined } from '@ant-design/icons';

export const DISTRIBUTION_SERIES = [
    { name: 'нет информации', color: '#A7A7A7' },
    { name: 'согласен', color: '#379866' },
    { name: 'скорее согласен', color: '#48C180' },
    { name: 'нечто среднее', color: '#60CFFB' },
    { name: 'скорее не согласен', color: '#F2A266' },
    { name: 'не согласен', color: '#ED0314' },
];

export const PDF_GENERATOR_TYPES = [
    { id: 'file', value: 'PDF-файл', icon: <FilePdfOutlined /> },
    { id: 'one', value: 'Страница с одним отчетом', icon: <BarChartOutlined /> },
    { id: 'two', value: 'Страница с двумя отчетами', icon: <div><PieChartOutlined /> <PieChartOutlined /></div> },
    { id: 'three', value: 'Страница с тремя отчетами', icon: <div><PieChartOutlined /> <PieChartOutlined /> <PieChartOutlined /></div> },
    { id: 'questions-distribution-driver', value: 'Распределение ответов по вопросам (по драйверам)', icon: <TableOutlined /> },
    { id: 'segment', value: 'Сегмент', icon: <SnippetsOutlined /> }
];

export const PDF_GENERATOR_SEGMENT_TYPES = filter(({ id }) => contains(id, ['one', 'two', 'three', 'questions-distribution-driver', 'segment']), PDF_GENERATOR_TYPES);

export const REPORT_TYPES = [
    { id: 'block', value: 'Результаты по блокам (КМП)', },
    { id: 'blocks-distribution', value: 'Распределение ответов по блокам' },
    { id: 'drivers', value: 'Результаты по драйверам (КМП)' },
    { id: 'drivers-extended', value: 'Результаты по драйверам (расширенный)' },
    { id: 'drivers-distribution', value: 'Распределение ответов по драйверам' },
    { id: 'questions-distribution', value: 'Распределение ответов по вопросам' },
    { id: 'questions-distribution-table', value: 'Распределение ответов по вопросам (таблица)' },
    { id: 'filters', value: 'Целевые аудитории' },
    { id: 'dynamic', value: 'Динамика' }
];

export const PUBLIC_REPORT_TYPES = [
    { id: 'block_total', value: 'Результаты по блокам (КМП)', },
    { id: 'block_distribution', value: 'Распределение ответов по блокам' },
    { id: 'driver_total', value: 'Результаты по драйверам (КМП)' },
    { id: 'driver_distribution', value: 'Распределение ответов по драйверам' },
    { id: 'report_filters', value: 'Целевые аудитории' },
    { id: 'report_table', value: 'Динамика' }
];

export const SEGMENT_REPORT_TYPES = filter(i => !contains(i.id, ['filters', 'dynamic']), REPORT_TYPES);

export const PDF_FONTS = [
    { id: 'Roboto', value: 'Roboto' },
    { id: 'Geometria', value: 'Geometria' }
];

export const FONTS_DEFINITIONS = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    },
    Geometria: {
        normal: 'Geometria.ttf',
        bold: 'Geometria-Bold.ttf',
        italics: 'Geometria-Italic.ttf',
        bolditalics: 'Geometria-BoldItalic.ttf'
    }
}

export const LEGEND_POSITIONS = [
    { id: 'bottom', value: 'Снизу' },
    { id: 'top', value: 'Сверху' },
    { id: 'left', value: 'Слева' },
    { id: 'right', value: 'Справа' },
];

export const DRIVER_TOTAL_COLORS = [
    { color: '#C00000', kmp: value => value < 0, top5: true },
    { color: '#F3C005', kmp: value => value < 0.5, top5: true },
    { color: '#287F00', kmp: value => value >= 0.5, top5: true },
    { color: '#A6A6A6', top5: false }
];

export const TITLE_SIZE_COEFFICIENT = {
    two: 2.2,
    three: 3.4
};
