import React, { Component } from 'react';
import { Radio as AntdRadio } from 'antd';

export default class Radio extends Component {
    onChange = e => {
        const { input, onChange } = this.props;

        input.onChange(e.target.checked);
        onChange && onChange(e.target.checked);
    }

    render() {
        return <AntdRadio
            checked={!!this.props.input.value}
            onChange={this.onChange} />;
    }
}
