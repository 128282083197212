import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { toPairs, pathEq, path, assocPath, clone, forEach, pathOr, addIndex, is } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import ListenerField from './ListenerField';

const FIELDS = [
    { name: 'translations.pageElements.chooseLanguage', translationName: 'chooseLanguage' },
    { name: 'translations.pageElements.chooseText', translationName: 'chooseText' },
    { name: 'translations.pageElements.continueButton', translationName: 'continueButton' },
    { name: 'translations.pageElements.finishText', translationName: 'finishText' },
    { name: 'translations.pageElements.finishTitle', translationName: 'finishTitle' },
    { name: 'translations.pageElements.startButton', translationName: 'startButton' },
    { name: 'translations.pageElements.submitButton', translationName: 'submitButton' },
    { name: 'scale' },
    { name: 'title' },
    { name: 'welcomeText' },
    { name: 'messageTemplate.subject' },
    { name: 'sections.[].title', array: true },
    { name: 'sections.[].options', array: true }
];

const DRIVER_FIELDS = [ 'block', 'name', 'questions', 'scale', 'title' ];

const mapBeforeSubmit = (values, { item }) => {
    let data = clone(values);
    const language = path(['translations', 'settings', 'defaultLanguage'], values);
    const prevLanguage = pathOr(item.defaultLanguage, ['translations', 'settings', 'defaultLanguage'], item);

    forEach(field => {
        if (field.array) {
            const namePath = field.name.split('.');
            const arrayName = namePath[0];

            addIndex(forEach)((_, index) => {
                const itemPath = namePath.map(i => i === '[]' ? index : i);
                let translation = path(['translations', language, ...itemPath], values);
                translation = is(Array, translation) ? translation.map((v, i) => !v ? path([...itemPath, i], values) : v) : translation;
                data = assocPath(['translations', prevLanguage, ...itemPath], path(itemPath, values), data);

                if (translation) {
                    data = assocPath(itemPath, translation, data);
                }
            }, values[arrayName]);
        } else {
            let translation = path(['translations', language, ...(field.translationName || field.name).split('.')], values);
            translation = is(Array, translation) ? translation.map((v, i) => !v ? path([...field.name.split('.'), i], values) : v) : translation;
            data = assocPath(['translations', prevLanguage, ...(field.translationName || field.name).split('.')], path(field.name.split('.'), values), data);

            if (translation) {
                data = assocPath(field.name.split('.'), translation, data);
            }
        }
    }, FIELDS);

    addIndex(forEach)((item, index) => {
        forEach(field => {
            let translation = path(['translations', language, field], item);
            translation = is(Array, translation) ? translation.map((v, i) => !v ? path([field, i], item) : v) : translation;
            data = assocPath(['drivers', index, 'translations', prevLanguage, field], item[field], data);

            if (translation) {
                data = assocPath(['drivers', index, field], translation, data);
            }
        }, DRIVER_FIELDS);
    }, values.drivers);

    return {
        ...data,
        defaultLanguage: path(['translations', 'settings', 'defaultLanguage'], data)
    };
}

class DefaultProjectLanguageForm extends Component {
    render() {
        const { item } = this.props;

        return <Fragment>
            <Field
                name='translations.settings.defaultLanguage'
                component={Select}
                label='Язык'
                options={toPairs(item.languages).map(([ id, value ]) => ({ id, value }))} />
            <ListenerField listenFieldName='translations.settings.defaultLanguage'>
                { ({ translations }) =>
                    <SubmitButton disabled={pathEq(['settings', 'defaultLanguage'], path(['translations', 'settings', 'defaultLanguage'], item), translations)}>
                        Сохранить
                    </SubmitButton>
                }
            </ListenerField>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    translations: yup.object().shape({
        settings: yup.object().shape({
            defaultLanguage: yup.string().nullable().required()
        })
    })
});

export default withFormWrapper(DefaultProjectLanguageForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    mapBeforeSubmit
});
