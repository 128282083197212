export const GET_USER = 'GET_USER';
export const POST_LOGIN = 'POST_LOGIN';
export const POST_RECOVERY = 'POST_RECOVERY';

export const LOGOUT = 'LOGOUT';

export const POST_REFRESH_TOKEN = 'POST_REFRESH_TOKEN';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

export const OPEN_PROJECT_INFO_MODAL = 'OPEN_PROJECT_INFO_MODAL';
export const OPEN_PROJECT_LETTER_TEMPLATE_MODAL = 'OPEN_PROJECT_LETTER_TEMPLATE_MODAL';
export const OPEN_RESPONDENT_MODAL = 'OPEN_RESPONDENT_MODAL';
export const OPEN_SEND_RESPONDENT_LETTER_MODAL = 'OPEN_SEND_RESPONDENT_LETTER_MODAL';
export const OPEN_CREATE_PROJECT_MODAL = 'OPEN_CREATE_PROJECT_MODAL';
export const OPEN_GROUP_MODAL = 'OPEN_GROUP_MODAL';
export const OPEN_GROUP_VARIABLES_MODAL = 'OPEN_GROUP_VARIABLES_MODAL';
export const OPEN_DRIVER_MODAL = 'OPEN_DRIVER_MODAL';
export const OPEN_DRIVER_SETTINGS_MODAL = 'OPEN_DRIVER_SETTINGS_MODAL';
export const OPEN_COMPANY_MODAL = 'OPEN_COMPANY_MODAL';
export const OPEN_SEND_COMPANY_LETTER_MODAL = 'OPEN_SEND_COMPANY_LETTER_MODAL';
export const OPEN_QUESTIONNAIRE_MODAL = 'OPEN_QUESTIONNAIRE_MODAL';
export const OPEN_PROJECT_LANGUAGES_MODAL = 'OPEN_PROJECT_LANGUAGES_MODAL';
export const OPEN_PROJECT_PREVIEW_MODAL = 'OPEN_PROJECT_PREVIEW_MODAL';
export const OPEN_PDF_CHART_MODAL = 'OPEN_PDF_CHART_MODAL';
export const OPEN_PROJECT_PDF_MODAL = 'OPEN_PROJECT_PDF_MODAL';
export const OPEN_DOWNLOAD_PDF_REPORT_MODAL = 'OPEN_DOWNLOAD_PDF_REPORT_MODAL';
export const OPEN_PROJECT_PDF_SETTINGS_MODAL = 'OPEN_PROJECT_PDF_SETTINGS_MODAL';
export const OPEN_PROJECT_PAGE_ELEMENTS_MODAL = 'OPEN_PROJECT_PAGE_ELEMENTS_MODAL';
export const OPEN_REPORT_MODAL = 'OPEN_REPORT_MODAL';
export const OPEN_REPORT_PAGE_MODAL = 'OPEN_REPORT_PAGE_MODAL';
export const OPEN_REPORT_SETTINGS_MODAL = 'OPEN_REPORT_SETTINGS_MODAL';
export const OPEN_REPORT_CHART_MODAL = 'OPEN_REPORT_CHART_MODAL';
export const OPEN_IMPORT_PROJECT_MODAL = 'OPEN_IMPORT_PROJECT_MODAL';
export const OPEN_IMPORT_QUESTIONS_MODAL = 'OPEN_IMPORT_QUESTIONS_MODAL';
export const OPEN_PROJECT_RESPONSE_FIELD_FORM_MODAL = 'OPEN_PROJECT_RESPONSE_FIELD_FORM_MODAL';
export const OPEN_DEFAULT_PROJECT_LANGUAGE_MODAL = 'OPEN_DEFAULT_PROJECT_LANGUAGE_MODAL';
export const OPEN_PROJECT_PDF_TEMPLATES_MODAL = 'OPEN_PROJECT_PDF_TEMPLATES_MODAL';
export const OPEN_PROJECT_PDF_TEMPLATE_MODAL = 'OPEN_PROJECT_PDF_TEMPLATE_MODAL';
export const OPEN_PROJECT_PDF_TEMPLATE_PAGE_MODAL = 'OPEN_PROJECT_PDF_TEMPLATE_PAGE_MODAL';
export const OPEN_LANGUAGES_SELECTOR_MODAL = 'OPEN_LANGUAGES_SELECTOR_MODAL';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const POST_PROJECT = 'POST_PROJECT';
export const PUT_PROJECT = 'PUT_PROJECT';
export const PATCH_PROJECT = 'PATCH_PROJECT';
export const IMPORT_PROJECT = 'IMPORT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const GET_COMPANIES = 'GET_COMPANIES';
export const POST_COMPANY = 'POST_COMPANY';
export const PUT_COMPANY = 'PUT_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';

export const GET_RESPONDENTS_STATUSES = 'GET_RESPONDENTS_STATUSES';

export const GET_RESPONDENTS = 'GET_RESPONDENTS';
export const POST_RESPONDENT = 'POST_RESPONDENT';
export const PUT_RESPONDENT = 'PUT_RESPONDENT';
export const DELETE_RESPONDENTS = 'DELETE_RESPONDENTS';
export const DELETE_RESPONDENT = 'DELETE_RESPONDENT';
export const GET_RESPONDENT = 'GET_RESPONDENT';

export const POST_RESPONDENTS_IMPORT = 'POST_RESPONDENTS_IMPORT';

export const POST_MESSAGES = 'POST_MESSAGES';

export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const POST_QUESTIONNAIRE = 'POST_QUESTIONNAIRE';
export const PUT_QUESTIONNAIRE = 'PUT_QUESTIONNAIRE';
export const DELETE_QUESTIONNAIRES = 'DELETE_QUESTIONNAIRES';
export const PATCH_QUESTIONNAIRE = 'PATCH_QUESTIONNAIRE';

export const POST_MESSAGES_COMPANY = 'POST_MESSAGES_COMPANY';

export const GET_REPORT_BLOCK_TOTAL = 'GET_REPORT_BLOCK_TOTAL';
export const GET_REPORT_DRIVER_TOTAL = 'GET_REPORT_DRIVER_TOTAL';
export const GET_REPORT_BLOCK_DISTRIBUTION = 'GET_REPORT_BLOCK_DISTRIBUTION';
export const GET_REPORT_DRIVER_DISTRIBUTION = 'GET_REPORT_DRIVER_DISTRIBUTION';
export const GET_REPORT_FILTERS = 'GET_REPORT_FILTERS';
export const GET_DRIVER_IMPORTANCE = 'GET_DRIVER_IMPORTANCE';
export const GET_REPORT_TABLE = 'GET_REPORT_TABLE';
export const GET_REPORT_QUESTIONS_DISTRIBUTION = 'GET_REPORT_QUESTIONS_DISTRIBUTION';

export const DELETE_FILE = 'DELETE_FILE';
export const GET_FILE_DOWNLOAD = 'GET_FILE_DOWNLOAD';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORT = 'GET_REPORT';
export const POST_REPORT = 'POST_REPORT';
export const PUT_REPORT = 'PUT_REPORT';
export const PATCH_REPORT = 'PATCH_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';

export const GET_PUBLIC_REPORTS = 'GET_PUBLIC_REPORTS';
