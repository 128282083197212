import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pathOr } from 'ramda';

import RespondentsStatuses from './reports/RespondentsStatuses';
import ReportBlockTotal from './reports/ReportBlockTotal';
import ReportBlockDistribution from './reports/ReportBlockDistribution';
import ReportDriverTotal from './reports/ReportDriverTotal';
import ReportDriverDistribution from './reports/ReportDriverDistribution';
import ReportFilters from './reports/ReportFilters';
import { getReportFilters } from '../../../actions/asyncActions';
import Route from '../../Route';
import ReportTable from './reports/ReportTable';
import { getScale } from '../../../utils/report';

const TABS = [
    { name: 'Статус по респондентам', key: '', component: RespondentsStatuses },
    { name: 'Результаты по блокам', key: 'blocks', component: ReportBlockTotal, title: 'Результаты по блокам (КМП)' },
    { name: 'Распределение ответов по блокам', key: 'blocks-distribution', component: ReportBlockDistribution, title: 'Распределение ответов по блокам' },
    { name: 'Результаты по драйверам', key: 'drivers', component: ReportDriverTotal, title: 'Результаты по драйверам (КМП)' },
    { name: 'Распределение ответов по драйверам', key: 'drivers-distribution', component: ReportDriverDistribution, title: 'Распределение ответов по драйверам' },
    { name: 'Целевые аудитории', key: 'filters', component: ReportFilters },
    { name: 'Динамика', key: 'dynamics', component: ReportTable }
];

class ProjectStatistic extends Component {
    onChangeTab = key => {
        const { history, project: { id }} = this.props;

        history.replace(`/projects/${id}/statistic${key === 'respondents' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { tab }}, project: { id, answerMap, settings }, getReportFilters } = this.props;
        const scale = getScale(answerMap);

        return <Tabs onChange={this.onChangeTab} animated={false} activeKey={tab || 'respondents'}>
            { TABS.map(tab =>
                <Tabs.TabPane key={tab.key || 'respondents'} tab={tab.name}>
                    <Route path={`/projects/${id}/statistic/${tab.key}`} render={props =>
                        <tab.component {...props} id={id} getReportFilters={getReportFilters} scale={scale} settings={pathOr({}, ['theme'], settings)} title={tab.title} />
                    } />
                </Tabs.TabPane>
            )}
        </Tabs>;
    }
}

export default withAsyncActions({
    getReportFilters: getReportFilters
        .withParams(() => ({ type: 'filter' }))
        .withPayload(({ project: { id }}) => ({ id }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(ProjectStatistic);
