import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';

class LoginForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='username'
                component={Input}
                icon={<MailOutlined />}
                placeholder='E-mail' />
            <Field
                name='password'
                component={Input}
                icon={<LockOutlined />}
                placeholder='Пароль'
                htmlType='password' />
            <SubmitButton>Войти</SubmitButton>
            <div style={{ marginTop: 15 }}>
                <Link to='/recovery'>Забыли пароль?</Link>
            </div>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required()
});

export default withFormWrapper(LoginForm, {
    validationSchema
});
