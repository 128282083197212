import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { toPairs, pathOr, without } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import { MenuOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { reorder } from '../../../utils/dnd';
import { getReportTable } from '../../../actions/asyncActions';
import ListenerField from '../ListenerField';

const Item = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    background: #fff;
`;

class FilterDndList extends Component {
    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.getValues(),
            result.source.index,
            result.destination.index
        );

        this.props.onChange(items);
    }

    getValues = () => this.props.input.value || this.props.options || [];

    render() {
        const values = this.getValues();

        return <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
                { provided =>
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        { values.map((item, index) =>
                            <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                                { provided =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{ ...provided.draggableProps.style, marginBottom: 10 }}>
                                        <Item>
                                            <div style={{ padding: '0 10px' }} {...provided.dragHandleProps}>
                                                <MenuOutlined />
                                            </div>
                                            <ListenerField listenFieldName='defaultLegendName'>
                                                { ({ defaultLegendName }) => item || defaultLegendName || 'Не указано' }
                                            </ListenerField>
                                        </Item>
                                    </div>
                                }
                            </Draggable>
                        )}
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
        </DragDropContext>;
    }
}

export default withFieldWrapper(
    withAsyncActions(({ input: { value }}) => ({
        getReportTable: getReportTable
            .withPayload(({ project, property }) => ({
                id: project,
                q: { property }
            }))
            .withSuccessHandler(({ getReportTable: { data }, onChange }) => onChange(without(['total'], toPairs(pathOr({}, [0, 1], toPairs(data))).map(([item]) => item))))
            .withOptions({ dispatchOnMount: !value, resetOnUnmount: true, dispatchOnUpdate: true })
    }))(FilterDndList)
);
