import React from 'react';
import { Button } from 'antd';

import FormSubmittingContext from '../../contexts/FormSubmittingContext';

const SubmitButton = (props) => (
    <FormSubmittingContext.Consumer>
        { isSubmitting =>
            <Button
                loading={isSubmitting ? { delay: 500 } : false}
                type='primary'
                htmlType='submit'
                {...props}>
                {props.children}
            </Button>
        }
    </FormSubmittingContext.Consumer>
);

export default SubmitButton;
