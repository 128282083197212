import React, { Component } from 'react';
import { Tabs } from 'antd';
import { Route } from 'react-router-dom';

import ProjectLanguages from './settings/ProjectLanguages';
import ProjectForm from './settings/ProjectForm';
import ProjectGroups from './settings/ProjectGroups';
import ProjectExtended from './settings/ProjectExtended';

const TABS = [
    { name: 'Языки', key: '', component: ProjectLanguages },
    { name: 'Форма', key: 'form', component: ProjectForm },
    { name: 'Группы', key: 'groups', component: ProjectGroups },
    { name: 'Расширенные', key: 'extended', component: ProjectExtended }
]

class ProjectSettings extends Component {
    onChangeTab = key => {
        const { history, project: { id }} = this.props;

        history.replace(`/projects/${id}/settings${key === 'languages' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { tab }}, project } = this.props;

        return <Tabs onChange={this.onChangeTab} animated={false} activeKey={tab || 'languages'}>
            { TABS.map(tab =>
                <Tabs.TabPane key={tab.key || 'languages'} tab={tab.name}>
                    <Route path={`/projects/${project.id}/settings/${tab.key}`} render={props =>
                        <tab.component {...props} project={project} />
                    } />
                </Tabs.TabPane>
            )}
        </Tabs>;
    }
}

export default ProjectSettings;
