import React, { Component } from 'react';

import Modal from './Modal';
import { withAsyncActions } from 'react-async-client';
import { patchProject } from '../../actions/asyncActions';
import { message } from 'antd';
import GroupForm from '../forms/GroupForm';

class GroupModal extends Component {
    render() {
        const { modal, params: { variables, item, id, group }, patchProject } = this.props;

        return <Modal
            {...modal}
            title={item ? 'Редактировать группу' : 'Добавить группу'}
            footer={null}>
            <GroupForm
                item={item}
                formAction={patchProject}
                variables={variables}
                group={group}
                id={id} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(({ close }) => {
            message.success('Группа успешно сохранена');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить группу'))
        .withOptions({ resetOnUnmount: true })
})(GroupModal);
