import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { patchProject } from '../../actions/asyncActions';
import ProjectResponseFieldForm from '../forms/ProjectResponseFieldForm';

class ProjectResponseFieldFormModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Добавить поле'
            footer={null}>
            <ProjectResponseFieldForm
                formAction={patchProject}
                data={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withParams(() => ({ type: 'edit' }))
        .withSuccessHandler(({ close }) => {
            message.success('Форма успешно сохранена');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить форму'))
        .withOptions({ resetOnUnmount: true })
})(ProjectResponseFieldFormModal);
