import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import RecoveryForm from '../forms/RecoveryForm';
import { postRecovery } from '../../actions/asyncActions';

class Recovery extends Component {
    render() {
        return <RecoveryForm
            formAction={this.props.postRecovery} />
    }
}

export default withAsyncActions({
    postRecovery: postRecovery
        .withSuccessHandler(({ history }) => {
            message.success('Письмо для восстановления пароля успешно отправлено');
            history.pushState('/');
        })
        .withErrorHandler(() => message.error('Не удалось отправить письмо для восстановления пароля'))
        .withOptions({ resetOnUnmount: true })
})(Recovery);
