import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withAsyncActions } from 'react-async-client';
import { toPairs } from 'ramda';

import { RESPONDENT_STATUS_COLORS, RESPONDENT_STATUS_NAMES } from '../../../../constants/project';
import { getRespondentsStatuses } from '../../../../actions/asyncActions';
import CenterSpin from '../../../CenterSpin';
import withChartPngDownload from '../../../hocs/withChartPngDownload';

class ProjectStatistic extends Component {
    getOptions = () => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Статус по респондентам (количество человек)'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            textOverflow: 'none'
                        }
                    },
                    showInLegend: true
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            series: [{
                colorByPoint: true,
                data: toPairs(this.props.getRespondentsStatuses.data).map(([status, count]) => ({
                    y: count,
                    color: RESPONDENT_STATUS_COLORS[status],
                    name: RESPONDENT_STATUS_NAMES[status]
                }))
            }]
        };
    }

    render() {
        const { getRespondentsStatuses: { meta }, getRef } = this.props;

        return meta.pending && !meta.lastSucceedAt ?
            <CenterSpin /> : meta.lastSucceedAt ?
            <div style={{ marginTop: 10 }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.getOptions()}
                    ref={getRef} />
            </div> : null;
    }
}

export default withAsyncActions({
    getRespondentsStatuses: getRespondentsStatuses
        .withPayload(({ id }) => ({
            q: {
                project: id
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(withChartPngDownload(ProjectStatistic));
