import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import styled from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import LanguagesSelector from './LanguagesSelector';

const Wrapper = styled.div`
    display: flex;
    .ant-btn-icon-only {
        margin-left: 10px;
    }
`;

class InputComponent extends Component {
    static propTypes = {
        htmlType: PropTypes.string,
        icon: PropTypes.object,
        placeholder: PropTypes.string,
        input: PropTypes.object,
        onChange: PropTypes.func,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        addonAfter: PropTypes.element,
        getRef: PropTypes.func,
        addonBefore: PropTypes.string
    };

    static defaultProps = {
        type: 'text'
    };

    onChange = e => this.props.onChange(e.target.value);

    render() {
        const {
            icon,
            htmlType,
            placeholder,
            disabled,
            input: { value, name },
            className,
            addonAfter,
            getRef,
            addonBefore,
            translations,
            translationName,
            getTranslationPath,
            translationsWithoutRewrite
        } = this.props;

        return <Wrapper>
            <Input
                value={value}
                disabled={disabled}
                className={className}
                onChange={this.onChange}
                prefix={icon}
                type={htmlType}
                placeholder={placeholder}
                addonAfter={addonAfter}
                addonBefore={addonBefore}
                ref={getRef} />
            { translations && <LanguagesSelector
                name={translationName || name}
                defaultValue={value}
                translationsWithoutRewrite={translationsWithoutRewrite}
                getTranslationPath={getTranslationPath} />
            }
        </Wrapper>;
    }
}

export default withFieldWrapper(InputComponent);
