import React, { Component } from 'react';
import styled from 'styled-components';
import { path, forEach, any } from 'ramda';

const Wrapper = styled.div`
    .filter-button {
        border: 1px solid #1890ff;
        width: 14px;
        height: 14px;
        margin-left: 5px;
        border-radius: 3px;
        padding: 0;
        background: #1890ff;
        vertical-align: bottom;
        cursor: pointer;
        &:before {
            content: '';
            display: inline-block;
            background-size: 8px;
            position: relative;
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29uZmluZGVyX2Fycm93LXVwXzMzMjUwNTc8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJpY29uZmluZGVyX2Fycm93LXVwXzMzMjUwNTciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyIj4gICAgICAgICAgICA8cGF0aCBkPSJNNywxNCBMNywwIiBpZD0iUGF0aCI+PC9wYXRoPiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSIwIDcgNyAwIDE0IDciPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=');
        }
    }
`;

const ShowAllButton = styled.button`
    margin-bottom: 15px;
    color: #1890ff;
    background: none;
    border: none;
    cursor: pointer;
    transition: .3s all;
    &:disabled {
        color: #ccc;
        cursor: not-allowed;
    }
    &:focus {
        outline: none;
    }
    &:hover {
        color: #40a9ff;
    }
`;

export default (WrappedComponent, showAlways) =>
    class extends Component {
        state = {
            showAll: true
        };

        filterListener = event => {
            event.stopPropagation();

            const columnName = event.target.getAttribute('data-name');

            this.chart && forEach(serie => {
                if (serie.name === 'Вся компания') {
                    return;
                }
                serie.setVisible(serie.name === columnName);
            }, this.chart.series);
        }

        config = {
            legend: {
                useHTML: true,
                labelFormatter: function() {
                    if (this.name === 'Вся компания') {
                        return this.name;
                    }
                    return this.name + '<button class="filter-button" data-name="' + this.name + '"></button>';
                }
            },
            chartEvents: {
                redraw: () => {
                    if (!this.chart) {
                        return;
                    }

                    if (any(serie => !serie.visible, this.chart.series)) {
                        this.state.showAll && this.setState({ showAll: false });
                    } else {
                        !this.state.showAll && this.setState({ showAll: true });
                    }
                },
                render: () => {
                    const filterButtons = document.querySelectorAll('.filter-button');

                    forEach(button => {
                        button.removeEventListener('click', this.filterListener);
                        button.addEventListener('click', this.filterListener);
                    }, filterButtons);
                }
            }
        };

        showAll = () => {
            if (this.chart) {
                forEach(serie => {
                    if (!serie.visible) {
                        serie.setVisible(true);
                    }
                }, this.chart.series);
            }
        }

        render() {
            const { pdf, reportFilter } = this.props;

            return <Wrapper>
                <WrappedComponent getRef={node => this.chart = path(['chart'], node)} config={this.config} {...this.props} />
                { !pdf && (showAlways || (reportFilter.value && reportFilter.value.length)) ?
                    <div style={{ textAlign: 'center', background: '#fff' }}>
                        <ShowAllButton
                            disabled={this.state.showAll}
                            onClick={this.showAll}>
                            Показать все
                        </ShowAllButton>
                    </div> : null
                }
            </Wrapper>;
        }
    }
