import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const StyledToolbar = styled.div`
    background: #fafafa;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e8e8e8;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
    h1 {
        margin-bottom: 0;
        margin-left: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const ToolbarTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

class Toolbar extends Component {
    goBack = () => {
        const { history, backUrl, onlyBackUrl } = this.props;

        if (history.length <= 2 || onlyBackUrl) {
            history.replace(backUrl || '/');
        } else {
            history.goBack();
        }
    }

    render() {
        const { title, hideBack, buttons } = this.props;

        return <StyledToolbar>
            <Title>
                { !hideBack &&
                    <div>
                        <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={this.goBack} />
                    </div>
                }
                { title }
            </Title>
            { buttons &&
                <div>{ buttons }</div>
            }
        </StyledToolbar>;
    }
}

export default withRouter(Toolbar);
