import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { patchProject } from '../../actions/asyncActions';
import { message } from 'antd';
import ProjectInfoForm from '../forms/ProjectInfoForm';

class ProjectInfoModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Редактировать проект'
            width={950}
            footer={null}>
            <ProjectInfoForm
                formAction={patchProject}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(({ close }) => {
            message.success('Проект успешно изменен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить проект'))
        .withOptions({ resetOnUnmount: true })
})(ProjectInfoModal);
