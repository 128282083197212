import React, { Component } from 'react';
import { InputNumber } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class NumberInput extends Component {
    render() {
        const { input: { value }, onChange, min, max } = this.props;

        return <InputNumber
            value={value}
            onChange={onChange}
            min={min}
            max={max} />;
    }
}

export default withFieldWrapper(NumberInput);
