import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { withRouter } from 'react-router-dom';
import { isEmpty, flatten, toPairs, any, equals, mapObjIndexed, find, propEq, is, has, path, isNil } from 'ramda';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getUrlPagination, extendSearchPath, getUrlParams, getSorting, getFilters } from '../../../utils/urlParams';
import { LINES_PER_PAGE, TABLE_LOADING_DELAY, ORDER_TYPES } from '../../../constants/table';

const ErrorBlock = styled.div`
    color: #f5222d;
`;

const ErrorBlockIcon = styled.div`
    font-size: 36px;
`;

const StyledTable = styled(Table)`
    background: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid rgb(234, 234, 234);
    border-top: none;
    .ant-table-pagination {
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;

class TableComponent extends Component {
    static propTypes = {
        action: PropTypes.object,
        columns: PropTypes.array,
        setOffset: PropTypes.func,
        scroll: PropTypes.string,
        onRow: PropTypes.func,
        location: PropTypes.object,
        rowSelection: PropTypes.object,
        expandedRowRender: PropTypes.func,
        history: PropTypes.object,
        urlPrefix: PropTypes.string,
        showSizeChanger: PropTypes.string
    };

    static defaultProps = {
        showSizeChanger: false
    };

    getPaginationConfig() {
        const { action: { data: { _meta }}, setOffset, location, urlPrefix, showSizeChanger, setLimit } = this.props;

        const { offset = 0, limit = LINES_PER_PAGE } = getUrlPagination(location, urlPrefix);

        if (!_meta || _meta.count <= LINES_PER_PAGE) {
            return false;
        }

        return {
            total: _meta.count,
            pageSize: Number(limit),
            current: (Number(offset) / Number(limit)) + 1,
            defaultCurrent: 0,
            onChange: (page, pageSize) => setOffset((page - 1) * (pageSize || Number(limit))),
            onShowSizeChange: (_, pageSize) => setLimit(pageSize),
            showSizeChanger
        };
    }

    onExpand = (expanded, row) => {
        this.props.history.replace(extendSearchPath(this.props.location, { expanded: expanded ? row.id : null }));
    }

    onChange = (_, filter, sorter) => {
        const currentSorting = getSorting(this.props.location);
        const currentFilter = getFilters(this.props.location) || {};

        if (currentSorting.sort_by !== sorter.columnKey || currentSorting.sort_order !== ORDER_TYPES[sorter.order]) {
            this.props.history.replace(extendSearchPath(this.props.location, {
                sorting: isEmpty(sorter) ? null : {
                    sort_by: sorter.columnKey,
                    sort_order: sorter.order === 'descend' ? ORDER_TYPES.descend : ORDER_TYPES.ascend
                }
            }));
        }

        if (any(([key, value]) => !equals(value, currentFilter[key]), toPairs(filter))) {
            const newFilter = { ...currentFilter, ...mapObjIndexed((value, key) => {
                const field = find(propEq('key', key), this.props.columns) || {};
                return has('filterMultiple', field) && !field.filterMultiple && is(Array, value) ? path([0], value) : value;
            }, filter) };

            this.props.history.replace(extendSearchPath(this.props.location, {
                filter: isEmpty(newFilter) ? null : JSON.stringify(newFilter),
                offset: 0
            }));
        }
    }

    getColumns = () => {
        const sorting = getSorting(this.props.location);
        const filter = getFilters(this.props.location) || {};

        return this.props.columns.map(column => {
            let value = sorting.sort_by === column.key ? {
                ...column,
                defaultSortOrder: sorting.sort_order === 'desc' ? 'descend' : 'ascend'
            } : column;

            return !isNil(filter[column.key]) && column.filters ? {
                ...value,
                filteredValue: has('filterMultiple', column) && !column.filterMultiple ? [filter[column.key]] : filter[column.key]
            } : value;
        });
    }

    render() {
        const pagination = this.getPaginationConfig();
        const {
            action: { data, meta: { pending, error }},
            scroll,
            onRow,
            rowSelection,
            expandedRowRender,
            location,
            parseItems,
            rowClassName,
            getExpanded,
            disablePagination
        } = this.props;
        const dataSource = error ? null : parseItems ? parseItems(data.items) : data.items;
        const expanded = (getExpanded ? getExpanded(dataSource) : null) || getUrlParams(location).expanded;

        const loading = {
            spinning: pending,
            delay: TABLE_LOADING_DELAY,
        };

        const conditionProps = expandedRowRender ? {
            expandedRowRender
        } : {
            scroll: scroll ? { y: scroll } : { y: false }
        };

        const errorProps = error ? {
            locale: {
                emptyText: <ErrorBlock>
                    <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                    <div>Не удалось отобразить данные</div>
                </ErrorBlock>
            }
        } : {};

        return (
            <StyledTable
                columns={this.getColumns()}
                dataSource={dataSource}
                pagination={disablePagination ? false : pagination}
                loading={loading}
                rowKey='id'
                onRow={onRow}
                rowSelection={rowSelection}
                rowClassName={rowClassName}
                expandedRowKeys={expanded ? flatten([expanded]) : []}
                onExpand={this.onExpand}
                onChange={this.onChange}
                indentSize={0}
                showSorterTooltip={false}
                {...errorProps}
                {...conditionProps}
            />
        );
    }
}

export default withRouter(TableComponent);
