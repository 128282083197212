import React from 'react';
import styled from 'styled-components';

import GuestRoutes from './GuestRoutes';

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #e3ebf4;
`;

const Layout = styled.div`
    width: 400px;
`;

const Block = styled.div`
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 35px 40px;
    text-align: center;
`;

const GuestLayout = () =>
    <Content>
        <Layout>
            <Block>
                <GuestRoutes />
            </Block>
        </Layout>
    </Content>;

export default GuestLayout;
