import React, { Component, Fragment } from 'react';
import { map, remove, toPairs, fromPairs, find, propEq, filter, pathOr, keys, unnest, path, uniq } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Form, Button } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Editor from './formComponents/Editor';
import LanguagesSelector from './formComponents/LanguagesSelector';
import ListenerField from './ListenerField';
import Select from './formComponents/Select';
import { PROJECT_THEMES } from '../../constants/project';
import ImageUpload from './formComponents/ImageUpload';
import NumberInput from './formComponents/NumberInput';
import { parseToPatch } from '../../utils/forms';

const ScaleItem = styled.div`
    display: flex;
    align-items: center;
    .ant-form-item {
        margin-bottom: 0;
        margin-right: 15px;
    }
    .ant-form-item:first-of-type {
        width: 100%;
        & + button {
            margin-right: 15px;
            margin-left: -15px;
        }
    }
`;

const required = value => !value ? 'required' : undefined

class ProjectInfoForm extends Component {
    state = {
        welcomeTextLang: null
    };

    removeScaleFromTranslations = (index, translations) => {
        this.props.form.change('translations', map(value => ({
            ...value,
            scale: remove(index, 1, value.scale || [])
        }), translations));
    }

    renderScaleFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <ScaleItem key={name}>
                    <Field
                        name={`${name}.key`}
                        component={Input}
                        hideErrorMsg
                        validate={required} />
                    <LanguagesSelector name={name} />
                    <span style={{ marginRight: 15 }}>:</span>
                    <Field
                        name={`${name}.value`}
                        component={NumberInput}
                        hideErrorMsg />
                    <ListenerField listenFieldName='translations'>
                        { ({ translations }) =>
                            <Button style={{ width: 40 }} danger icon={<DeleteOutlined />} onClick={() => {
                                fields.remove(index);
                                this.removeScaleFromTranslations(index, translations);
                            }} />
                        }
                    </ListenerField>
                </ScaleItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')}>Добавить значение</Button>
        </div>;
    }

    setWelcomeTextLang = welcomeTextLang => this.setState({ welcomeTextLang });

    render() {
        const { welcomeTextLang } = this.state;
        const welcomeName = (!welcomeTextLang || welcomeTextLang === this.props.item.defaultLanguage) ? 'welcomeText' : `translations.${welcomeTextLang}.welcomeText`;

        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Field
                name='title'
                component={Editor}
                label='Заголовок'
                inline
                translations />
            <ListenerField listenFieldName='languages'>
                { ({ languages }) =>
                    <Fragment>
                        <Field
                            key={welcomeName}
                            name={welcomeName}
                            component={Editor}
                            label='Текст приветствия'
                            languages={toPairs(languages)}
                            currentLanguage={welcomeTextLang || this.props.item.defaultLanguage}
                            onSelectLanguage={this.setWelcomeTextLang}
                            disableClear />
                    </Fragment>
                }
            </ListenerField>
            <Form.Item label='Шкала' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                <FieldArray name='scale' render={this.renderScaleFields} />
            </Form.Item>
            <Field
                name='settings.theme.name'
                component={Select}
                label='Тема'
                options={PROJECT_THEMES} />
            <Field
                name='settings.theme.logo'
                component={ImageUpload}
                label='Лого' />
            <Field name='translations' component={() => null} />
            <SubmitButton>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(ProjectInfoForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        scale: (item.scale).map(key => ({
            key,
            value: (item.answerMap || {})[key]
        }))
    }),
    validationSchema,
    mapBeforeSubmit: (values, props) => {
        const data = {
            ...values,
            scale: (values.scale || []).map(({ key }) => key),
            answerMap: fromPairs((values.scale || []).map(({ key, value }) => ([ key, value ]))),
            settings: {
                theme: {
                    ...(pathOr({}, ['settings', 'theme'], values)),
                    scaleColor: fromPairs(filter(([ key ]) => find(propEq('key', key), values.scale || []), toPairs((pathOr({}, ['settings', 'theme', 'scaleColor'], values)))))
                }
            }
        };

        return parseToPatch(data, props.item, [
            'name',
            'title',
            'welcomeText',
            'scale',
            'answerMap',
            ...(path(['settings', 'theme'], props.item) ? ['settings.theme.name', 'settings.theme.logo'] : ['settings.theme']),
            ...uniq(unnest(['title', 'welcomeText', 'scale'].map(field => keys(values.languages).map(lang =>
                path(['translations', lang], props.item) ? `translations.${lang}.${field}` : `translations.${lang}`
            ))))
        ]);
    }
});
