import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Tooltip, Button, Table } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { uniqBy, prop, propEq, filter } from 'ramda';
import { DownloadOutlined, MailOutlined } from '@ant-design/icons';

import TableList from '../table/TableList';
import { getRespondents } from '../../../actions/asyncActions';
import UserName from '../table/UserName';
import RespondentsFilter from '../../forms/filters/RespondentsFilter';
import { RESPONDENT_STATUS_FILTER, RESPONDENT_STATUS_COLORS, RESPONDENT_STATUS_NAMES } from '../../../constants/project';
import { getToken } from '../../../utils/token';
import { openSendCompanyLetterModal } from '../../../actions/modalActions';

const Wrapper = styled.div`
    .table-toolbar {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: none;
    }
    .ant-table-wrapper {
        border: none;
    }
`;

class CompanyRespondents extends Component {
    getColumns = () => {
        return [
            {
                title: 'Респондент',
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                render: (lastName, item) => <UserName user={item} />
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                key: 'status',
                filters: RESPONDENT_STATUS_FILTER,
                filterMultiple: false
            }
        ];
    }

    getExpandedColumns = () => {
        return [
            {
                dataIndex: ['_embedded', 'project'],
                key: 'project',
                render: project => project ? <Link to={`/projects/${project.id}/info`}>{ project.name }</Link> : null
            },
            {
                dataIndex: 'status',
                key: 'status',
                render: status => <Tag color={RESPONDENT_STATUS_COLORS[status]}>{ RESPONDENT_STATUS_NAMES[status] }</Tag>
            },
            {
                key: 'actions',
                width: 50,
                render: item =>
                    <Tooltip title='Скачать отчет'>
                        <Button icon={<DownloadOutlined />} href={`/api/respondents/${item.id}.pdf?token=${getToken()}`} target='_blank' rel='noopener noreferrer' download />
                    </Tooltip>
            }
        ];
    }

    renderButtons = () => {
        const { openSendCompanyLetterModal, company } = this.props;

        return <Button
            icon={<MailOutlined />}
            type='primary'
            onClick={() => openSendCompanyLetterModal({
                company: company.id,
                detailTemplate: '[Project] ([Group]): [TestLink]'
            })}>
            Отправить письмо
        </Button>;
    }

    parseItems = items => {
        return uniqBy(prop('email'), items || []).map(item => ({
            ...item,
            expandedItems: filter(propEq('email', item.email), items)
        }));
    }

    expandedRowRender = item => {
        return <Table
            dataSource={item.expandedItems}
            columns={this.getExpandedColumns()}
            rowKey='id'
            pagination={false}
            showHeader={false} />;
    }

    render() {
        return <Wrapper>
            <TableList
                action={getRespondents}
                columns={this.getColumns()}
                filterForm={RespondentsFilter}
                staticFilter={{ company: this.props.company.id }}
                buttons={this.renderButtons()}
                parseItems={this.parseItems}
                expandedRowRender={this.expandedRowRender}
                showSizeChanger />
        </Wrapper>;
    }
}

export default connect(null, { openSendCompanyLetterModal })(CompanyRespondents);
