import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import styled, { css } from 'styled-components';
import { prop, forEach } from 'ramda';
import ClickOutside from 'react-click-outside';

import withFieldWrapper from '../../hocs/withFieldWrapper';

export const Swatch = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: ${({ pointer }) => pointer ? 'pointer' : 'default'};
`;

export const Color = styled.div`
    width: 36px;
    height: 22px;
    border-radius: 2px;
    background: ${prop('color')};
`;

const Popover = styled.div`
    position: absolute;
    z-index: 1000;
    left: 47px;
    ${({ top }) => top ? css`
        bottom: 15px;
    ` : css`
        top: 0;
    `}
    .chrome=picker {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 4px 8px;
    }
`;

class ColorPicker extends Component {
    state = {
        open: false,
        top: false
    };

    inputs = [];

    componentDidMount() {
        setTimeout(() => {
            this.inputs = this.picker.getElementsByTagName('input');
            forEach(input => input.addEventListener('keypress', this.inputListener), this.inputs);
        });
    }

    componentWillUnmount() {
        forEach(input => input.removeEventListener('keypress', this.inputListener), this.inputs);
    }

    inputListener = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    onChange = ({ hex }) => {
        this.props.input.onChange(hex);
    }

    toggle = () => this.setState(prev => {
        const open = !prev.open;

        return { open, top: open ? this.container.getBoundingClientRect().y + 260 > window.document.body.clientHeight : false };
    });

    close = () => this.setState({ open: false });

    render() {
        const { input: { value }} = this.props;

        return <div style={{ position: 'relative' }} ref={node => this.container = node}>
            <Swatch onClick={this.toggle} pointer>
                <Color color={value} />
            </Swatch>
            <Popover ref={node => this.picker = node} style={{ display: this.state.open ? 'block' : 'none' }} top={this.state.top}>
                <ClickOutside onClickOutside={this.close}>
                    <ChromePicker
                        color={value}
                        onChangeComplete={this.onChange} />
                </ClickOutside>
            </Popover>
        </div>;
    }
}

export const ColorPickerField = withFieldWrapper(ColorPicker);

export default ColorPicker;
