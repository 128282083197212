import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import ProjectExtendedForm from '../../../forms/ProjectExtendedForm';
import { patchProject } from '../../../../actions/asyncActions';

class ProjectExtended extends Component {
    render() {
        const { project, patchProject } = this.props;

        return <div style={{ padding: 15 }}>
            <ProjectExtendedForm
                formAction={patchProject}
                data={project} />
        </div>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
        .withOptions({ resetOnUnmount: true })
})(ProjectExtended);
