import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';

import LoginForm from '../forms/LoginForm';
import { postLogin } from '../../actions/asyncActions';
import { DEFAULT_LOGIN_ERROR } from '../../constants/errors';

class Login extends Component {
    render() {
        const { postLogin } = this.props;

        return <Fragment>
            <h3>Вход</h3>
            <LoginForm
                formAction={postLogin}
                defaultError={DEFAULT_LOGIN_ERROR} />
        </Fragment>;
    }
}

export default withAsyncActions({
    postLogin: postLogin
        .withOptions({ resetOnUnmount: true })
})(Login);
