import React, { Component } from 'react';
import { Switch as AntdSwitch } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class Switch extends Component {
    render() {
        const { input: { value }, disabled, onChange } = this.props;

        return <AntdSwitch
            checked={!!value}
            onChange={onChange}
            disabled={disabled} />
    }
}

export default withFieldWrapper(Switch);
