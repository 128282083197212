import React, { Component, Fragment } from 'react';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { takeEvery } from 'redux-saga/effects';

import { getProject, getReport, getRespondent } from '../../../actions/asyncActions';
import DetailToolbar from '../DetailToolbar';
import ProjectInfo from './ProjectInfo';
import ProjectDrivers from './ProjectDrivers';
import ProjectRespondents from './ProjectRespondents';
import ProjectStatistic from './ProjectStatistic';
import { PUT_PROJECT, PATCH_PROJECT } from '../../../constants/actionTypes';
import CenterSpin from '../../CenterSpin';
import ProjectPdfGenerator from './ProjectPdfGenerator';
import Route from '../../Route';
import ProjectPublicReports from './ProjectPublicReports';
import ProjectPublicReport from './ProjectPublicReport';
import ProjectSettings from './ProjectSettings';
import ProjectRespondent, { ProjectRespondentButtons } from './ProjectRespondent';

const TABS = [
    { name: 'Информация', key: 'info', component: ProjectInfo },
    { name: 'Настройки', path: 'settings/:tab?', key: 'settings',  component: ProjectSettings },
    { name: 'Вопросы', key: 'drivers', component: ProjectDrivers },
    { name: 'Респонденты', key: 'respondents', component: ProjectRespondents, routes: [{
        path: '/projects/:project/respondents/:id', component: ProjectRespondent
    }] },
    { name: 'Статистика', path: 'statistic/:tab?', key: 'statistic', component: ProjectStatistic },
    { name: 'Генератор отчета', key: 'pdf', component: ProjectPdfGenerator },
    { name: 'Публичные отчеты', key: 'reports', component: ProjectPublicReports, routes: [{
        path: '/projects/:project/reports/:id', component: ProjectPublicReport
    }]}
];

const Wrapper = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-tabs-bar {
        margin: 0;
    }
    .ant-tabs {
        overflow: visible;
    }
`;

class Project extends Component {
    onChangeTab = key => {
        const { history, match: { params: { id }}} = this.props;

        history.replace(`/projects/${id}/${key}`);
    }

    getButtons = () => {
        const { match: { params: { type }}, getRespondent } = this.props;

        return type === 'respondents' && getRespondent.data.id ? <ProjectRespondentButtons /> : null;
    }

    render() {
        const { getProject: { data, meta }, match: { params: { id, type }}, getReport, getRespondent } = this.props;

        return <Fragment>
            <DetailToolbar title={
                    <h1>
                        { getRespondent.data.id ? `${getRespondent.data.lastName} ${getRespondent.data.firstName} ${getRespondent.data.middleName || ''}` :
                        (getReport.data.name || data.name) }
                    </h1>
                }
                buttons={this.getButtons()} />
            { meta.pending && !meta.lastSucceedAt ?
                <CenterSpin /> :
                meta.lastSucceedAt ?
                    <Wrapper>
                        <Tabs onChange={this.onChangeTab} animated={false} activeKey={type}>
                            { TABS.map(tab =>
                                <Tabs.TabPane key={tab.key} tab={tab.name}>
                                    { (tab.routes || []).map(route =>
                                        <Route key={route.path} path={route.path} render={props =>
                                            <route.component {...props} project={data} getProject={this.props.getProject} />
                                        } />
                                    )}
                                    <Route path={`/projects/${id}/${tab.path || tab.key}`} exact render={props =>
                                        <tab.component {...props} project={data} getProject={this.props.getProject} />
                                    } />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Wrapper> :
                    null
            }
        </Fragment>;
    }
}

export default withAsyncActions({
    getReport,
    getRespondent,
    getProject: getProject
        .withPayload(({ match: { params: { id }}}) => id)
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_PROJECT), toSuccess(PATCH_PROJECT)], () => {
                getProps().getProject.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(Project);
