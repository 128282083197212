import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { toPairs, pathOr, without } from 'ramda';
import { MenuOutlined } from '@ant-design/icons';
import { withAsyncActions } from 'react-async-client';
import { Field } from 'react-final-form';

import { getReportTable } from '../../../actions/asyncActions';
import Switch from './Switch';
import Input from './Input';

const Item = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    background: #fff;
`;

const ItemFields = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px 5px 0;
    & > .ant-row:first-child {
        width: 100%;
        margin-right: 22px;
    }
    .ant-row {
        margin-bottom: 0;
    }
`;

class DynamicsDndList extends Component {
    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        this.props.fields.move(result.source.index, result.destination.index);
    }

    render() {
        const { fields } = this.props;

        return <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0, top: -40 }}>Показывать</div>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='droppable'>
                    { provided =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            { fields.map((name, index) =>
                                <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                                    { provided =>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={{ ...provided.draggableProps.style, marginBottom: 10 }}>
                                            <Item>
                                                <div style={{ padding: '6px 10px' }} {...provided.dragHandleProps}>
                                                    <MenuOutlined />
                                                </div>
                                                <ItemFields>
                                                    <Field
                                                        name={`${name}.name`}
                                                        component={Input} />
                                                    <Field
                                                        name={`${name}.show`}
                                                        component={Switch} />
                                                </ItemFields>
                                            </Item>
                                        </div>
                                    }
                                </Draggable>
                            )}
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </DragDropContext>
        </div>;
    }
}

export default withAsyncActions(({ fields: { value, name }}) => ({
    getReportTable: getReportTable
        .withPayload(({ project, property }) => ({
            id: project,
            q: { property }
        }))
        .withSuccessHandler(({ getReportTable: { data }, form }) => form.change(name,
            without(['total'], toPairs(pathOr({}, [0, 1], toPairs(data))).map(([item]) => item)).map(name => ({ name, originalName: name, show: true }))
        ))
        .withOptions({ dispatchOnMount: !(value || []).length, resetOnUnmount: true, dispatchOnUpdate: true })
}))(DynamicsDndList);
