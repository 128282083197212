import React, { Component } from 'react';
import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Error = styled.div`
    color: #e71c32;
    text-align: center;
    .anticon-exclamation-circle {
        font-size: 30px;
        margin-bottom: 10px;
    }
`;

export default class Modal extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        this.setState({ error: true });
    }

    render() {
        const { ModalComponent, children, ...props } = this.props;
        const Modal = ModalComponent || AntdModal;

        return <Modal
            {...props}>
            { this.state.error ?
                <Error>
                    <ExclamationCircleOutlined />
                    <div>Не удалось отобразить данные</div>
                </Error> :
                children
            }
        </Modal>;
    }
}
