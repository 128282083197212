import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { reorder } from '../../../utils/dnd';

const Item = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    background: #fff;
`;

class RangeField extends Component {
    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.props.input.value,
            result.source.index,
            result.destination.index
        );

        this.props.onChange(items);
    }

    render() {
        const value = this.props.input.value || [];

        return <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId='droppable'>
                { provided =>
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        { value.map((item, index) =>
                            <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                                { provided =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{ ...provided.draggableProps.style, marginBottom: 10 }}>
                                        <Item>
                                            <div style={{ padding: '0 10px' }} {...provided.dragHandleProps}>
                                                <MenuOutlined />
                                            </div>
                                            { item }
                                        </Item>
                                    </div>
                                }
                            </Draggable>
                        )}
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
        </DragDropContext>
    }
}

export default withFieldWrapper(RangeField);
