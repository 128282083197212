import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import Modal from './Modal';
import { putProject } from '../../actions/asyncActions';
import DefaultProjectLanguageForm from '../forms/DefaultProjectLanguageForm';
import { message } from 'antd';

class DefaultProjectLanguageModal extends Component {
    render() {
        const { modal, params, putProject } = this.props;

        return <Modal
            {...modal}
            title='Редактировать дефолтный язык'
            footer={null}>
            <DefaultProjectLanguageForm
                formAction={putProject}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    putProject: putProject
        .withParams(() => ({ type: 'defaultLanguage' }))
        .withSuccessHandler(({ close }) => {
            message.success('Настройки успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить настройки'))
})(DefaultProjectLanguageModal);
