import React, { Component } from 'react';
import { Input, Spin, List } from 'antd';
import ClickOutside from 'react-click-outside';
import styled from 'styled-components';
import debounce from 'debounce';
import { withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router-dom';
import { path } from 'ramda';

import { pushRollbarError } from '../../../utils/rollbar';
import { getRespondents } from '../../../actions/asyncActions';
import SpanAsLink from '../../user/table/SpanAsLink';
import { ExclamationCircleOutlined, ProjectOutlined, MailOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
    .ant-input-search {
        width: auto;
        input {
            width: ${({ opened }) => opened ? 400 : 200}px;
        }
        input:focus {
            width: 400px;
        }
    }
    position: relative;
    line-height: 0;
`;

const Content = styled.div`
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 400px;
    position: absolute;
    z-index: 90;
    top: 40px;
    left: 0;
    min-height: 40px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
`;

const Error = styled(Content)`
    color: #e71c32;
    text-align: center;
    line-height: 20px;
    padding: 15px;
    .anticon-exclamation-circle {
        font-size: 30px;
        margin-bottom: 10px;
    }
`;

const CenterWrapper = styled.div`
    text-align: center;
    padding-top: 19px;
`;

class Search extends Component {
    state = {
        search: '',
        opened: false,
        focused: false,
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    search = debounce(value => {
        if (value.length > 2) {
            this.props.getRespondents.refresh({
                q: {
                    name: value
                }
            });
        }
    }, 400);

    onChange = e => {
        const { value } = e.target;

        this.setState({ search: value });
        this.search(value);
    }

    resetData = () => {
        this.setState({ opened: false, search: '' });
        this.props.getRespondents.reset();
    }

    close = (e) => {
        if (!this.state.focused && this.state.opened) {
            this.resetData();
        }
    }

    highlightSearch = string => {
        return (string || '').replace(
            new RegExp(this.state.search.replace(/\[|\\|\^|\$|\.|\||\?|\*|\+|\(|\)/gi, sym => `\\${sym}`), 'gi'),
            str => `<u>${str}</u>`
        );
    }

    goToResult = item => {
        this.props.history.push(`/projects/${item.project}/respondents/${item.id}`);
        this.resetData();
    }

    render() {
        const { getRespondents: { meta, data }} = this.props;

        return <Wrapper opened={this.state.opened}>
            <Input.Search
                onFocus={() => this.setState({ opened: true, focused: true })}
                onBlur={() => this.setState({ focused: false })}
                value={this.state.search}
                onChange={this.onChange}
                placeholder='Быстрый поиск' />
            <ClickOutside onClickOutside={this.close}>
                { this.state.error ?
                    <Error>
                        <ExclamationCircleOutlined />
                        <div>Не удалось отобразить данные</div>
                    </Error> :
                    this.state.search.length > 2 && this.state.opened &&
                        <Content>
                            { meta.pending ?
                                <CenterWrapper style={{ paddingTop: 10 }}><Spin /></CenterWrapper> : !(data.items || []).length ?
                                <CenterWrapper style={{ paddingTop: 19 }}>Ничего не найдено</CenterWrapper> :
                                <List
                                    dataSource={data.items}
                                    renderItem={item =>
                                        <List.Item style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div>
                                                <SpanAsLink onClick={() => this.goToResult(item)}>
                                                    <span dangerouslySetInnerHTML={{ __html: this.highlightSearch(`${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`) }} />
                                                </SpanAsLink>
                                                <div><ProjectOutlined /> { path(['_embedded', 'project', 'name'], item) }</div>
                                                { item.email && <div><MailOutlined /> <span dangerouslySetInnerHTML={{ __html: this.highlightSearch(item.email) }} /></div> }
                                            </div>
                                        </List.Item>
                                    } />
                            }
                        </Content>
                }
            </ClickOutside>
        </Wrapper>;
    }
}

export default withRouter(
    withAsyncActions({
        getRespondents: getRespondents
            .withParams(() => ({ type: 'search' }))
            .withOptions({ resetOnMount: true })
    })(Search)
);
