import React, { Component, Fragment } from 'react';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import styled, { css } from 'styled-components';
import { pathOr, path, find, propEq, contains, assocPath, findIndex, flatten, drop } from 'ramda';
import { asyncConnect, withAsyncHandlers } from 'react-async-client';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FilePdfOutlined, PieChartOutlined, BarChartOutlined, SnippetsOutlined, MenuOutlined, TableOutlined, EditOutlined, PlusOutlined, DeleteOutlined, SettingOutlined, DownloadOutlined, CopyOutlined, FileImageOutlined } from '@ant-design/icons';
import { withState } from 'recompose';

import { openProjectPdfModal, openDownloadPdfReportModal, openProjectPdfSettingsModal, openProjectPdfTemplatesModal, openProjectPdfTemplateModal } from '../../../actions/modalActions';
import { REPORT_TYPES } from '../../../constants/charts';
import { getDriverImportance, getReportFilters, deleteFile, getRespondentsStatuses, getReportQuestionsDistribution, patchProject } from '../../../actions/asyncActions';
import { reorder } from '../../../utils/dnd';
import { getToken } from '../../../utils/token';

const Wrapper = styled.div`
    padding: 15px;
`;

export const ActionButtons = styled.div`
    position: absolute;
    top: -15px;
    right: -15px;
    display: ${({ visible }) => visible ? 'block' : 'none'};
    .ant-btn {
        margin-left: 5px;
    }
`;

export const StyledCard = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    position: relative;
    background: #fff;
    &:hover {
        ${ActionButtons} {
            display: ${({ isDraggingOver }) => isDraggingOver ? 'none' : 'block'};
        }
    }
`;

const CardContent = styled.div`
    display: flex;
`;

const DragHandler = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
`;

export const ChartBlock = styled.div`
    .anticon-bar-chart {
        ${({ reverseIcon }) => reverseIcon && css`
            transform: rotate(90deg) scale(-1, 1) ;
        `}
    }
`;

class ProjectPdfGenerator extends Component {
    state = {
        visiblePopConfirm: null
    };

    charts = {};

    renderChartBlock = chart => {
        const title = chart.type === 'filters' ? `Целевые аудитории (${chart.property})` : path(['value'], find(propEq('id', chart.type), REPORT_TYPES));

        return <ChartBlock reverseIcon={contains('distribution', chart.type || '')}>
            { contains(chart.type, ['dynamic', 'questions-distribution-table']) ? <TableOutlined /> : chart.type === 'filters' ? <PieChartOutlined /> : <BarChartOutlined /> }
            <span> { title } {
                contains(chart.type, ['questions-distribution', 'questions-distribution-table']) && chart.drivers && chart.drivers.length ? <span>({ chart.drivers.reduce((res, cur, index) => `${res}${index > 0 ? ', ' : ''}${cur}`, '') })</span> :
                chart.type !== 'filters' && chart.property && <span> ({ chart.property }{ chart.value ? `, ${chart.value}` : '' })</span>
            }</span>
        </ChartBlock>;
    }

    mapBeforeSubmitTemplate = (value, index) => {
        const { project } = this.props;

        return {
            id: project.id,
            data: [{
                op: 'replace',
                path: `/report/templates/${index}`,
                value
            }]
        };
    }

    renderTemplateLink = page => {
        const { project, openProjectPdfTemplateModal } = this.props;
        const index = findIndex(propEq('id', page.template), pathOr([], ['report', 'templates'], project));
        const template = pathOr([], ['report', 'templates'], project)[index];

        return <span
            className='span-link'
            onClick={() => openProjectPdfTemplateModal({
                item: template,
                filters: getReportFilters.data,
                statuses: getRespondentsStatuses.data,
                drivers: getReportQuestionsDistribution.data,
                project,
                submitAction: patchProject,
                mapBeforeSubmit: (value, props) => this.mapBeforeSubmitTemplate(value, props, index)
            })}>
            <FileImageOutlined /> { path(['name'], template) }
        </span>;
    }

    renderPageItem = (page, index, title, segmentIndexes) => {
        const { getReportFilters, getRespondentsStatuses, openProjectPdfModal, project, getReportQuestionsDistribution } = this.props;
        const pageTitle = title || page.title;

        switch (page.type) {
            case 'file':
                return <div>
                    <FilePdfOutlined style= {{ marginRight: 10 }} />
                    <a href={`/api/files/${page.file.id}/download?token=${getToken()}`} target='_blank' rel='noopener noreferrer' download>{ page.file.name }</a>
                </div>;
            case 'one':
                return <Fragment>
                    { pageTitle && <h3>{ pageTitle }</h3> }
                    { this.renderChartBlock(page.charts[0]) }
                </Fragment>;
            case 'two':
                return <Fragment>
                    { pageTitle && <h3>{ pageTitle }</h3> }
                    { this.renderChartBlock(page.charts[0]) }
                    { this.renderChartBlock(page.charts[1]) }
                </Fragment>;
            case 'three':
                return <Fragment>
                    { pageTitle && <h3>{ pageTitle }</h3> }
                    { this.renderChartBlock(page.charts[0]) }
                    { this.renderChartBlock(page.charts[1]) }
                    { this.renderChartBlock(page.charts[2]) }
                </Fragment>;
            case 'questions-distribution-driver':
                return <Fragment>
                    { pageTitle && <h3>{ pageTitle }</h3> }
                    <ChartBlock reverseIcon>
                        <PieChartOutlined />
                        <BarChartOutlined style={{ marginRight: 5 }} />
                        <span>Распределение ответов по вопросам (по драйверам)</span>
                    </ChartBlock>
                </Fragment>;
            case 'segment':
                const dropType = `segment${[...(segmentIndexes || []), index].reduce((res, cur) => res + `-${cur}`, '')}`;

                return <Fragment>
                    <div style={{ marginBottom: page.template ? 0 : 15 }}>
                        <SnippetsOutlined /> { page.property } ({ page.value.map((item, i) => <span key={`segment-value-${index}-${i}`}>{ item }{ i < page.value.length - 1 ? ', ' : '' }</span>) })
                    </div>
                    { page.template ?
                        this.renderTemplateLink(page) :
                        <Fragment>
                            <Droppable droppableId={dropType} type={dropType}>
                                { (provided, snapshot) =>
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        { (page.data || []).map((item, i) =>
                                            <Draggable key={`item-${dropType}-${i}`} draggableId={`item-${dropType}-${i}`} index={i} type={dropType}>
                                                { provided =>
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{ ...provided.draggableProps.style, marginBottom: 20 }}>
                                                        <StyledCard isDraggingOver={snapshot.isDraggingOver} className={`item-${dropType}-${i}`}>
                                                            <CardContent>
                                                                <DragHandler {...provided.dragHandleProps}>
                                                                    <MenuOutlined />
                                                                </DragHandler>
                                                                <div style={{ width: '100%', padding: 20, paddingLeft: 0 }}>
                                                                    { this.renderPageItem(item, i, page.property, [...(segmentIndexes || []), index]) }
                                                                </div>
                                                            </CardContent>
                                                            <ActionButtons visible={this.state.visiblePopConfirm === `item-${dropType}-${i}`}>
                                                                <Tooltip title='Скопировать страницу'>
                                                                    <Button
                                                                        icon={<CopyOutlined />}
                                                                        shape='circle'
                                                                        onClick={() => openProjectPdfModal({
                                                                            project,
                                                                            segment: true,
                                                                            segmentIndexes: [...(segmentIndexes || []), index],
                                                                            index: i,
                                                                            filters: getReportFilters.data,
                                                                            statuses: getRespondentsStatuses.data,
                                                                            drivers: getReportQuestionsDistribution.data,
                                                                            copy: true
                                                                        })} />
                                                                </Tooltip>
                                                                <Button
                                                                    icon={<EditOutlined />}
                                                                    shape='circle'
                                                                    disabled={!getReportFilters.meta.lastSucceedAt || !getRespondentsStatuses.meta.lastSucceedAt}
                                                                    onClick={() => openProjectPdfModal({
                                                                        project,
                                                                        segment: true,
                                                                        segmentIndexes: [...(segmentIndexes || []), index],
                                                                        index: i,
                                                                        filters: getReportFilters.data,
                                                                        statuses: getRespondentsStatuses.data,
                                                                        drivers: getReportQuestionsDistribution.data
                                                                    })} />
                                                                <Popconfirm
                                                                    okText='Да'
                                                                    cancelText='Нет'
                                                                    title='Вы уверены, что хотите удалить страницу?'
                                                                    onConfirm={() => this.deleteSegmentSlide([...(segmentIndexes || []), index], i)}
                                                                    getPopupContainer={() => document.querySelector(`.item-${dropType}-${i}`)}
                                                                    placement='left'
                                                                    onVisibleChange={visible => this.onPopConfirmVisibleChange(visible, `item-${dropType}-${i}`)}>
                                                                    <Button icon={<DeleteOutlined />} danger shape='circle' />
                                                                </Popconfirm>
                                                            </ActionButtons>
                                                        </StyledCard>
                                                    </div>
                                                }
                                            </Draggable>
                                        )}
                                        { provided.placeholder }
                                    </div>
                                }
                            </Droppable>
                            <Button
                                icon={<PlusOutlined />}
                                disabled={!getReportFilters.meta.lastSucceedAt || !getRespondentsStatuses.meta.lastSucceedAt}
                                onClick={() => openProjectPdfModal({
                                    project,
                                    add: true,
                                    segment: true,
                                    segmentIndexes: [...(segmentIndexes || []), index],
                                    filters: getReportFilters.data,
                                    statuses: getRespondentsStatuses.data,
                                    drivers: getReportQuestionsDistribution.data
                                })}>
                                Добавить
                            </Button>
                        </Fragment>
                    }
                </Fragment>
            default:
                return null;
        }
    }

    deleteSegmentSlide = (segmentIndexes, index) => {
        const { patchProject, project } = this.props;
        const segmentsPath = flatten((segmentIndexes || []).map(i => ([i, 'data']))).reduce((res, cur) => `${res}/${cur}`, '');

        patchProject.dispatch({
            id: project.id,
            data: [{
                op: 'remove',
                path: `/report/data${segmentsPath}/${index}`
            }]
        });
    }

    deleteSlide = index => {
        const { patchProject, project, deleteFile } = this.props;
        const isFile = project.report.data[index].type === 'file';

        patchProject.dispatch({
            id: project.id,
            data: [{
                op: 'remove',
                path: `/report/data/${index}`
            }]
        });

        if (isFile) {
            deleteFile.dispatch(project.report.data[index].file.id);
        }
    }

    onDragEnd = result => {
        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        const { project, items, reorderReport, setItems } = this.props;
        let data = items;

        if (result.type === 'main') {
            data = reorder(
                items,
                result.source.index,
                result.destination.index
            );
        } else {
            const indexes = drop(1, result.type.split('-')).map(i => Number(i));
            const segmentData = reorder(
                path(flatten(indexes.map(i => ([i, 'data']))), items),
                result.source.index,
                result.destination.index
            );
            data = assocPath(flatten(indexes.map(i => ([i, 'data']))), segmentData, items);
        }

        setItems(data);
        reorderReport.dispatch({
            id: project.id,
            data: [{
                op: 'replace',
                path: '/report/data',
                value: data
            }]
        });
    }

    onPopConfirmVisibleChange = (visible, id) => {
        this.setState({ visiblePopConfirm: visible ? id : null });
    }

    render() {
        const {
            project,
            openProjectPdfModal,
            openDownloadPdfReportModal,
            items,
            getReportFilters,
            getDriverImportance,
            openProjectPdfSettingsModal,
            getRespondentsStatuses,
            getReportQuestionsDistribution,
            openProjectPdfTemplatesModal
        } = this.props;

        return <Wrapper>
            <div style={{ marginBottom: 20, textAlign: 'right' }}>
                <Button.Group>
                    <Button
                        icon={<FileImageOutlined />}
                        onClick={() => openProjectPdfTemplatesModal({
                            project,
                            filters: getReportFilters.data,
                            statuses: getRespondentsStatuses.data,
                            drivers: getReportQuestionsDistribution.data
                        })}>
                        Шаблоны
                    </Button>
                    <Button
                        icon={<SettingOutlined />}
                        onClick={() => openProjectPdfSettingsModal({ project })}>
                        Настройки
                    </Button>
                    <Button
                        icon={<DownloadOutlined />}
                        type='primary'
                        loading={getReportFilters.meta.pending || getDriverImportance.meta.pending || getRespondentsStatuses.meta.pending || getReportQuestionsDistribution.meta.pending}
                        disabled={!items.length || !getReportFilters.meta.lastSucceedAt || !getDriverImportance.meta.lastSucceedAt || !getRespondentsStatuses.meta.lastSucceedAt || !getReportQuestionsDistribution.meta.lastSucceedAt}
                        onClick={() => openDownloadPdfReportModal({ project, getReportFilters, getDriverImportance, getRespondentsStatuses, getReportQuestionsDistribution })}>
                        Скачать отчет
                    </Button>
                </Button.Group>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='droppable' type='main'>
                    { (provided, snapshot) =>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            { items.map((page, index) =>
                                <Draggable key={`page-${index}`} draggableId={`page-${index}`} index={index} type='main'>
                                    { provided =>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={{ ...provided.draggableProps.style, marginBottom: 20 }}>
                                            <StyledCard isDraggingOver={snapshot.isDraggingOver} className={`page-${index}`}>
                                                <CardContent>
                                                    <DragHandler {...provided.dragHandleProps}>
                                                        <MenuOutlined />
                                                    </DragHandler>
                                                    <div style={{ width: '100%', padding: 20, paddingLeft: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ width: '100%', marginRight: 15, opacity: page.hide ? .4 : 1 }}>
                                                            { this.renderPageItem(page, index) }
                                                        </div>
                                                        <Tooltip title='Скачать страницу' placement='left'>
                                                            <Button
                                                                icon={<DownloadOutlined />}
                                                                shape='circle'
                                                                onClick={() => openDownloadPdfReportModal({
                                                                    project,
                                                                    getReportFilters,
                                                                    getDriverImportance,
                                                                    getRespondentsStatuses,
                                                                    getReportQuestionsDistribution,
                                                                    page: index,
                                                                    openInNewWindow: true
                                                                })} />
                                                        </Tooltip>
                                                    </div>
                                                </CardContent>
                                                <ActionButtons visible={this.state.visiblePopConfirm === `page-${index}`}>
                                                    <Tooltip title={page.type === 'segment' ? 'Скопировать сегмент' : 'Скопировать страницу'}>
                                                        <Button
                                                            icon={<CopyOutlined />}
                                                            shape='circle'
                                                            onClick={() => openProjectPdfModal({ project, index, filters: getReportFilters.data, statuses: getRespondentsStatuses.data, drivers: getReportQuestionsDistribution.data, copy: true })} />
                                                    </Tooltip>
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        shape='circle'
                                                        disabled={!getReportFilters.meta.lastSucceedAt || !getRespondentsStatuses.meta.lastSucceedAt || !getReportQuestionsDistribution.meta.lastSucceedAt}
                                                        onClick={() => openProjectPdfModal({ project, index, filters: getReportFilters.data, statuses: getRespondentsStatuses.data, drivers: getReportQuestionsDistribution.data })} />
                                                    <Popconfirm
                                                        okText='Да'
                                                        cancelText='Нет'
                                                        title='Вы уверены, что хотите удалить страницу?'
                                                        onConfirm={() => this.deleteSlide(index)}
                                                        placement='left'
                                                        getPopupContainer={() => document.querySelector(`.page-${index}`)}
                                                        onVisibleChange={visible => this.onPopConfirmVisibleChange(visible, `page-${index}`)}>
                                                        <Button icon={<DeleteOutlined />} danger shape='circle' />
                                                    </Popconfirm>
                                                </ActionButtons>
                                            </StyledCard>
                                        </div>
                                    }
                                </Draggable>
                            )}
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </DragDropContext>
            <Button
                type='primary'
                icon={<PlusOutlined />}
                disabled={!getReportFilters.meta.lastSucceedAt || !getRespondentsStatuses.meta.lastSucceedAt || !getReportQuestionsDistribution.meta.lastSucceedAt}
                onClick={() => openProjectPdfModal({
                    project,
                    add: true,
                    filters: getReportFilters.data,
                    statuses: getRespondentsStatuses.data,
                    drivers: getReportQuestionsDistribution.data
                })}>
                Добавить
            </Button>
        </Wrapper>;
    }
}

export default withState('items', 'setItems', ({ project }) => pathOr([], ['report', 'data'], project))(
    withAsyncHandlers({
        getProject: {
            successHandler: ({ getProject: { data }, setItems }) => {
                setItems(pathOr([], ['report', 'data'], data));
            }
        }
    })(
        asyncConnect({
            getReportFilters: getReportFilters
                .withParams(() => ({ type: 'generator' }))
                .withPayload(({ project: { id }}) => ({ id }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
            getRespondentsStatuses: getRespondentsStatuses
                .withPayload(({ project: { id }}) => ({
                    q: { project: id }
                }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
            getReportQuestionsDistribution: getReportQuestionsDistribution
                .withParams(() => ({ type: 'generator' }))
                .withPayload(({ project: { id }}) => ({ id }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
            deleteFile: deleteFile
                .withOptions({ resetOnUnmount: true }),
            getDriverImportance: getDriverImportance
                .withPayload(({ project: { id }}) => ({ id }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
            patchProject: patchProject
                .withParams(() => ({ type: 'delete' }))
                .withSuccessHandler(() => message.success('Страница успешно удалена'))
                .withErrorHandler(() => message.error('Не удалось удалить страницу'))
                .withOptions({ resetOnUnmount: true }),
            reorderReport: patchProject
                .withParams(() => ({ type: 'reorder' }))
                .withOptions({ resetOnUnmount: true })
        }, null, { openProjectPdfModal, openDownloadPdfReportModal, openProjectPdfSettingsModal, openProjectPdfTemplatesModal, openProjectPdfTemplateModal })(ProjectPdfGenerator)
    )
);
