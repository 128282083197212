import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';

import Modal from './Modal';
import ProjectPdfTemplatesForm from '../forms/ProjectPdfTemplatesForm';
import { patchProject } from '../../actions/asyncActions';

class ProjectPdfTemplatesModal extends Component {
    render() {
        const { modal, params, patchProject } = this.props;

        return <Modal
            {...modal}
            title='Шаблоны'
            footer={null}>
            <ProjectPdfTemplatesForm
                formAction={patchProject}
                item={params.project}
                filters={params.filters}
                statuses={params.statuses}
                drivers={params.drivers} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchProject: patchProject
        .withParams(() => ({ type: 'templates' }))
        .withSuccessHandler(({ close }) => {
            message.success('Шаблоны успешно сохранены');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось сохранить шаблоны'))
        .withOptions({ resetOnUnmount: true })
})(ProjectPdfTemplatesModal);
