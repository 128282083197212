import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
`;

const PngButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 1;
`;

export default WrappedComponent =>
    class ChartPngDownload extends Component {
        getRef = node => {
            this.props.getRef && this.props.getRef(node);
            this.ref = node;
        }

        download = () => this.ref.chart.exportChart({ type: 'image/png' });

        render() {
            return this.props.pdf ?
                <WrappedComponent {...this.props} /> :
                <Wrapper>
                    <Tooltip title='Скачать в формате PNG' placement='left'>
                        <PngButton
                            icon={<DownloadOutlined />}
                            shape='circle'
                            onClick={this.download} />
                    </Tooltip>
                    <WrappedComponent {...this.props} getRef={this.getRef} />
                </Wrapper>;
        }
    }
