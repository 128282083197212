import React, { Component } from 'react';
import { Tag, Button, message, Dropdown, Menu, Upload, Popconfirm, Tooltip } from 'antd';
import styled from 'styled-components';
import { asyncConnect } from 'react-async-client';
import { keys, equals, path, pathOr, toPairs, filter, includes } from 'ramda';
import { withState } from 'recompose';
import qs from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

import TableList from '../table/TableList';
import { getRespondents, deleteRespondents, postRespondentImport, deleteRespondent } from '../../../actions/asyncActions';
import { RESPONDENT_STATUS_NAMES, RESPONDENT_STATUS_COLORS, RESPONDENT_STATUS_FILTER } from '../../../constants/project';
import RespondentsFilter from '../../forms/filters/RespondentsFilter';
import { openRespondentModal, openSendRespondentLetterModal } from '../../../actions/modalActions';
import { POST_RESPONDENT, PUT_RESPONDENT, DELETE_RESPONDENTS, POST_RESPONDENTS_IMPORT, POST_MESSAGES, DELETE_RESPONDENT } from '../../../constants/actionTypes';
import { getUrlParams, getSorting, getFilters } from '../../../utils/urlParams';
import { getToken } from '../../../utils/token';
import UserName from '../table/UserName';
import { LinkOutlined, DownloadOutlined, DeleteOutlined, EllipsisOutlined, PlusOutlined, MailOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    .table-toolbar {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: none;
    }
    .ant-table-wrapper {
        border: none;
    }
`;

const MenuIcon = styled.div`
    min-width: 12px;
    margin-right: 8px;
    display: inline-block;
`;

let loadingMessage = null;

export class Actions extends Component {
    state = {
        visible: false
    };

    onCopyResponseLink = () => message.success('Ссылка успешно скопирована в буфер обмена');

    getResponseLink = id => `${window.location.origin.replace(/app./, '')}/respondents/${id}`;

    getActionsMenu = () => {
        const { item } = this.props;

        return <Menu
            onClick={this.handleMenuClick}>
            <Menu.Item style={{ padding: 0 }} key='link'>
                <CopyToClipboard text={this.getResponseLink(item.id)} onCopy={this.onCopyResponseLink}>
                    <div style={{ padding: '5px 12px' }}>
                        <MenuIcon>
                            <LinkOutlined />
                        </MenuIcon>
                        Скопировать ссылку
                    </div>
                </CopyToClipboard>
            </Menu.Item>
            <Menu.Item key='pdf'>
                <a href={`/api/respondents/${item.id}.pdf?token=${getToken()}`} target='_blank' rel='noopener noreferrer' download>
                    <MenuIcon>
                        <DownloadOutlined />
                    </MenuIcon>
                    Скачать отчет
                </a>
            </Menu.Item>
            <Menu.Item style={{ padding: 0 }} key='delete'>
                <Popconfirm
                    okText='Да'
                    cancelText='Нет'
                    title='Вы уверены, что хотите удалить респондента?'
                    onConfirm={() => this.props.delete(item.id)}
                    placement='left'>
                    <div style={{ color: '#f5222d', padding: '5px 12px' }}>
                        <MenuIcon>
                            <DeleteOutlined />
                        </MenuIcon>
                        Удалить
                    </div>
                </Popconfirm>
            </Menu.Item>
        </Menu>;
    }

    handleMenuClick = e => {
        if (e.key !== 'delete') {
            this.setState({ visible: false });
        }
    }

    handleMenuVisibleFlag = visible => this.setState({ visible });

    render() {
        return <Button.Group>
            <Button
                icon={<EditOutlined />}
                onClick={this.props.edit}>
                { this.props.single && 'Редактировать' }
            </Button>
            <Dropdown
                trigger={['click']}
                overlay={this.getActionsMenu()}
                visible={this.state.visible}
                onVisibleChange={this.handleMenuVisibleFlag}>
                <Button icon={<EllipsisOutlined />} />
            </Dropdown>
        </Button.Group>;
    }
}

class ProjectRespondents extends Component {
    state = {
        visible: false
    };

    componentDidUpdate(prev) {
        if (!equals(getUrlParams(this.props.location), getUrlParams(prev.location))) {
            this.clearSelected();
        }
    }

    componentWillUnmount() {
        loadingMessage && setTimeout(loadingMessage, 0);
    }

    getColumns = () => {
        return [
            {
                title: 'Респондент',
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: true,
                render: (lastName, item) =>
                    <Link to={`/projects/${item.project}/respondents/${item.id}`}>
                        <UserName user={item} />
                    </Link>
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: 'Группа',
                dataIndex: 'group',
                key: 'group',
                sorter: true
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                filters: RESPONDENT_STATUS_FILTER,
                filterMultiple: false,
                render: (status, item) => {
                    const startedAt = path(['response', 'startedAt'], item);
                    const finishedAt = path(['response', 'finishedAt'], item);

                    return startedAt ?
                        <Tooltip
                            overlayStyle={{ maxWidth: 320 }}
                            title={<div>
                                <div>Дата начала прохождения: {moment(startedAt).format('DD.MM.YYYY')}</div>
                                { finishedAt && <div>Дата завершения прохождения: {moment(finishedAt).format('DD.MM.YYYY')}</div> }
                            </div>}>
                            <Tag color={RESPONDENT_STATUS_COLORS[status]}>{ RESPONDENT_STATUS_NAMES[status] }</Tag>
                        </Tooltip> :
                        <Tag color={RESPONDENT_STATUS_COLORS[status]}>{ RESPONDENT_STATUS_NAMES[status] }</Tag>
                }
            },
            {
                key: 'actions',
                width: 100,
                align: 'right',
                render: item => <Actions
                    item={item}
                    delete={this.delete}
                    edit={() => this.props.openRespondentModal({
                        item,
                        groups: this.getGroups(),
                        languages: toPairs(this.props.project.languages).map(([id, value]) => ({ id, value }))
                    })} />
            }
        ];
    }

    getGroups = () => {
        return keys(this.props.project.groups || {}).map(value => ({ id: value, value }));
    }

    delete = id => {
        this.props.deleteRespondent.dispatch(id);
    }

    deleteSelected = () => {
        this.setState({ visible: false });
        this.props.deleteRespondents.dispatch(this.props.selected);
    }

    getExportLink = () => {
        const { location, project } = this.props;
        const searchPath = qs.stringify({
            ...getSorting(location),
            q: JSON.stringify({
                project: project.id,
                ...getFilters(location)
            }),
            token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });

        return `/api/respondents.xlsx${searchPath}`;
    }

    importRespondents = file => {
        const data = new FormData();
        data.append('file', file);

        loadingMessage = message.loading('Импорт респондентов', 0);
        this.props.postRespondentImport.dispatch({
            id: this.props.project.id,
            data
        });

        return false;
    }

    handleMenuClick = e => {
        if (e.key !== 'delete') {
            this.setState({ visible: false });
        }
    }

    handleMenuVisibleFlag = visible => this.setState({ visible });

    getButtonsMenu = () => {
        const { selected } = this.props;

        return <Menu onClick={this.handleMenuClick}>
            <Menu.Item key='import'>
                <Upload
                    beforeUpload={this.importRespondents}
                    fileList={[]}>
                    Импортировать
                </Upload>
            </Menu.Item>
            <Menu.Item key='export'>
                <a href={this.getExportLink()} target='_blank' rel='noopener noreferrer' download>Экспортировать</a>
            </Menu.Item>
            { !selected.length ?
                <Menu.Item key='delete' disabled>
                    Удалить
                </Menu.Item> :
                <Menu.Item style={{ padding: 0 }} key='delete'>
                    <Popconfirm
                        okText='Да'
                        cancelText='Нет'
                        title='Вы уверены, что хотите удалить респондентов?'
                        onConfirm={this.deleteSelected}
                        placement='left'>
                        <div style={{ color: '#f5222d', padding: '5px 12px' }}>
                            Удалить
                        </div>
                    </Popconfirm>
                </Menu.Item>
            }
        </Menu>;
    }

    sendRespondentLetter = () => {
        const { openSendRespondentLetterModal, selected, project, getRespondents } = this.props;

        openSendRespondentLetterModal({
            item: {
                respondents: filter(({ id }) => includes(id, selected), getRespondents.data.items || []),
                project: project.id,
                subject: path(['messageTemplate', 'subject'], project),
                content: path(['messageTemplate', 'content'], project),
                type: selected.length ? 'selected' : 'unsent',
                translations: pathOr({}, ['messageTemplate', 'translations'], project)
            },
            onSuccess: this.clearSelected
        });
    }

    renderButtons = () => {
        const { project, openRespondentModal } = this.props;

        return <Button.Group>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => openRespondentModal({
                groups: this.getGroups(),
                languages: toPairs(project.languages).map(([id, value]) => ({ id, value })),
                item: { project: project.id }
            })}>
                Добавить
            </Button>
            <Button icon={<MailOutlined />} onClick={this.sendRespondentLetter}>
                Отправить письмо
            </Button>
            <Dropdown
                overlay={this.getButtonsMenu()}
                trigger={['click']}
                visible={this.state.visible}
                onVisibleChange={this.handleMenuVisibleFlag}>
                <Button icon={<DownOutlined />} />
            </Dropdown>
        </Button.Group>;
    }

    setSelected = selected => this.props.setSelected(selected);

    clearSelected = () => this.setSelected([]);

    render() {
        const { project } = this.props;

        return <Wrapper>
            <TableList
                action={getRespondents}
                columns={this.getColumns()}
                staticFilter={{ project: project.id }}
                filterForm={RespondentsFilter}
                buttons={this.renderButtons()}
                refreshActions={[POST_RESPONDENT, PUT_RESPONDENT, DELETE_RESPONDENTS, POST_RESPONDENTS_IMPORT, POST_MESSAGES, DELETE_RESPONDENT]}
                rowSelection={{
                    selectedRowKeys: this.props.selected,
                    onChange: this.setSelected,
                    type: 'checkbox'
                }} />
        </Wrapper>;
    }
}

export default withState('selected', 'setSelected', [])(
    asyncConnect({
        deleteRespondents: deleteRespondents
            .withSuccessHandler(({ setSelected }) => {
                message.success('Респонденты успешно удалены');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондентов'))
            .withOptions({ resetOnUnmount: true }),
        deleteRespondent: deleteRespondent
            .withSuccessHandler(({ setSelected }) => {
                message.success('Респондент успешно удален');
                setSelected([]);
            })
            .withErrorHandler(() => message.error('Не удалось удалить респондента'))
            .withOptions({ resetOnUnmount: true }),
        postRespondentImport: postRespondentImport
            .withSuccessHandler(({ setSelected }) => {
                loadingMessage && setTimeout(loadingMessage, 0);
                message.success('Респонденты успешно импортированы');
                setSelected([]);
            })
            .withErrorHandler(() => {
                loadingMessage && setTimeout(loadingMessage, 0);
                message.error('Не удалось импортировать респондентов')
            })
            .withOptions({ resetOnUnmount: true }),
        getRespondents
    }, null, { openRespondentModal, openSendRespondentLetterModal })(ProjectRespondents)
);
