import React from 'react';
import { withAsyncActions } from 'react-async-client';
import { isEmpty } from 'ramda';
import { withRouter } from 'react-router-dom';

import UserLayout from './user/UserLayout';
import GuestLayout from './guest/GuestLayout';
import { getUser } from '../../actions/asyncActions';
import CenterSpin from '../CenterSpin';

const checkUser = (user, meta) => {
    if (meta.pending && !meta.lastSucceedAt) {
        return null;
    }

    return !isEmpty(user);
}

const SecureLayout = ({ getUser: { data, meta }, ...props }) => {
    const isAuthenticated = checkUser(data, meta);

    if (isAuthenticated === null) {
        return <CenterSpin />;
    }

    return isAuthenticated ? <UserLayout {...props} /> : <GuestLayout {...props} />;
}

export default withRouter(withAsyncActions({ getUser })(SecureLayout));
