import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import { isNil } from 'ramda';

import Modal from './Modal';
import { patchReport } from '../../actions/asyncActions';
import ReportPageForm from '../forms/ReportPageForm';

class ReportPageModal extends Component {
    render() {
        const { modal, params: { index, data, project, add }, patchReport } = this.props;

        return <Modal
            {...modal}
            title={!isNil(index) ? 'Редактировать страницу' : 'Добавить страницу'}
            footer={null}>
            <ReportPageForm
                formAction={patchReport}
                data={data}
                index={index}
                project={project}
                add={add} />
        </Modal>;
    }
}

export default withAsyncActions({
    patchReport: patchReport
        .withSuccessHandler(({ close, params: { index }}) => {
            message.success(!isNil(index) ? 'Страница успешно изменена' : 'Страница успешно добавлена');
            close();
        })
        .withErrorHandler(({ index }) => message.error(!isNil(index) ? 'Не удалось изменить страницу' : 'Не удалось добавить страницу'))
        .withOptions({ dispatchOnUnmount: true })
})(ReportPageModal);
