import axios from 'axios';
import { pick } from 'ramda';

import * as urls from '../../constants/urls';

export const getUserHandler = () => axios.get(urls.USER.stringify());
export const postLoginHandler = ({ payload }) => axios.post(urls.LOGIN.stringify(), payload);
export const postRecoveryHandler = ({ payload }) => axios.post(urls.RECOVERY.stringify(), payload);

export const getProjectsHandler = ({ payload }) => axios.get(urls.PROJECTS.stringify(), {
    params: {
        ...payload,
        relations: ['company']
    }
});
export const getProjectHandler = ({ payload }) => axios.get(urls.PROJECTS.stringify({ id: payload }), {
    params: {
        relations: ['company']
    }
});
export const postProjectHandler = ({ payload }) => axios.post(urls.PROJECTS.stringify(), payload);
export const putProjectHandler = ({ payload }) => axios.put(urls.PROJECTS.stringify({ id: payload.id }), payload);
export const patchProjectHandler = ({ payload: { id, data }}) => axios.patch(urls.PROJECTS.stringify({ id }), data);
export const importProjectHandler = ({ payload }) => axios.post(urls.PROJECTS.stringify(), pick(['name', 'company'], payload)).then(({ id }) =>
    axios.put(urls.PROJECTS.stringify({ id }), payload)
);
export const deleteProjectHandler = ({ payload }) => axios.delete(urls.PROJECTS.stringify({ id: payload }));

export const getCompaniesHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify(), { params: payload });
export const postCompanyHandler = ({ payload }) => axios.post(urls.COMPANIES.stringify(), payload);
export const putCompanyHandler = ({ payload }) => axios.put(urls.COMPANIES.stringify({ id: payload.id }), payload);
export const getCompanyHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify({ id: payload }));

export const getRespondentsStatusesHandler = ({ payload }) => axios.get(urls.RESPONDENTS_STATUSES.stringify(), { params: payload });

export const getRespondentsHandler = ({ payload }) => axios.get(urls.RESPONDENTS.stringify(), {
    params: {
        ...payload,
        relations: ['project', 'project.company']
    }
});
export const postRespondentHandler = ({ payload }) => axios.post(urls.RESPONDENTS.stringify(), payload);
export const putRespondentHandler = ({ payload }) => axios.put(urls.RESPONDENTS.stringify({ id: payload.id }), payload);
export const deleteRespondentsHandler = ({ payload }) => axios.all(payload.map(id =>
    axios.delete(urls.RESPONDENTS.stringify({ id }))
));
export const deleteRespondentHandler = ({ payload }) => axios.delete(urls.RESPONDENTS.stringify({ id: payload }));
export const getRespondentHandler = ({ payload }) => axios.get(urls.RESPONDENTS.stringify({ id: payload }), {
    params: {
        relations: ['project']
    }
});

export const postRespondentImportHandler = ({ payload }) => axios.post(urls.RESPONDENTS_IMPORT.stringify({ id: payload.id }), payload.data);

export const postMessagesHandler = ({ payload }) => axios.post(urls.MESSAGES.stringify(), payload);

export const getQuestionnairesHandler = ({ payload }) => axios.get(urls.QUESTIONNAIRES.stringify(), { params: payload });
export const getQuestionnaireHandler = ({ payload }) => axios.get(urls.QUESTIONNAIRES.stringify({ id: payload }));
export const postQuestionnaireHandler = ({ payload }) => axios.post(urls.QUESTIONNAIRES.stringify(), payload);
export const putQuestionnaireHandler = ({ payload }) => axios.put(urls.QUESTIONNAIRES.stringify({ id: payload.id }), payload);
export const deleteQuestionnairesHandler = ({ payload }) => axios.all(payload.map(id =>
    axios.delete(urls.QUESTIONNAIRES.stringify({ id }))
));
export const patchQuestionnaireHandler = ({ payload: { id, data }}) => axios.patch(urls.QUESTIONNAIRES.stringify({ id }), data);

export const postMessagesCompanyHandler = ({ payload }) => axios.post(urls.MESSAGES_COMPANY.stringify(), payload);

export const getReportBlockTotalHandler = ({ payload: { id, ...params }}) => axios.get(urls.REPORT_BLOCK_TOTAL.stringify({ id }), { params });
export const getReportDriverTotalHandler = ({ payload: { id, ...params }}) => axios.get(urls.REPORT_DRIVER_TOTAL.stringify({ id }), { params });
export const getReportBlockDistributionHandler = ({ payload: { id, ...params } }) => axios.get(urls.REPORT_BLOCK_DISTRIBUTION.stringify({ id }), { params });
export const getReportDriverDistributionHandler = ({ payload: { id, ...params } }) => axios.get(urls.REPORT_DRIVER_DISTRIBUTION.stringify({ id }), { params });
export const getReportFiltersHandler = ({ payload: { id, ...params }}) => axios.get(urls.REPORT_FILTERS.stringify({ id }), { params });
export const getReportTableHandler = ({ payload: { id, ...params }}) => axios.get(urls.REPORT_TABLE.stringify({ id }), { params });
export const getDriverImportanceHandler = ({ payload: { id, ...params } }) => axios.get(urls.DRIVER_IMPORTANCE.stringify({ id }), { params });
export const getReportQuestionsDistributionHandler = ({ payload: { id, ...params }}) => axios.get(urls.REPORT_QUESTIONS_DISTRIBUTION.stringify({ id }), { params });

export const deleteFileHandler = ({ payload }) => axios.delete(urls.FILE.stringify({ id: payload }));
export const getFileDownloadHandler = ({ payload }) => axios.get(urls.FILE_DOWNLOAD.stringify({ id: payload }), {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf'
    }
});

export const getReportsHandler = ({ payload }) => axios.get(urls.REPORTS.stringify(), { params: payload });
export const getReportHandler = ({ payload }) => axios.get(urls.REPORTS.stringify({ id: payload }));
export const postReportHandler = ({ payload }) => axios.post(urls.REPORTS.stringify(), payload);
export const putReportHandler = ({ payload}) => axios.put(urls.REPORTS.stringify({ id: payload.id }), payload);
export const patchReportHandler = ({ payload: { id, data }}) => axios.patch(urls.REPORTS.stringify({ id }), data);
export const deleteReportHandler = ({ payload }) => axios.delete(urls.REPORTS.stringify({ id: payload }));

export const getPublicReportsHandler = ({ payload }) => axios.get(urls.PUBLIC_REPORTS.stringify({ id: payload.id, key: payload.key }), {
    params: {
        relations: ['project']
    }
});

export const postRefreshTokenHandler = ({ payload }) => axios.post(urls.REFRESH_TOKEN.stringify(), {
    refresh_token: payload
});
