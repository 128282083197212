import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { MailOutlined } from '@ant-design/icons';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

class RecoveryForm extends Component {
    render() {
        return <Fragment>
            <p>Введите свой адрес электронной почты ниже, чтобы сбросить пароль</p>
            <Field
                name='email'
                component={Input}
                icon={<MailOutlined />}
                placeholder='E-mail' />
            <SubmitButton>Отправить</SubmitButton>
            <div style={{ marginTop: 15 }}>
                <Link to='/'>Войти</Link>
            </div>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    email: yup.string().email().required()
});

export default withFormWrapper(RecoveryForm, {
    validationSchema
});
