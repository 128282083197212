import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

import Modal from './Modal';

const StyledModal = styled(AntdModal)`
    top: 0;
    padding-bottom: 0;
    .ant-modal-content {
        height: 100vh;
        border-radius: 0;
    }
    .ant-modal-body {
        padding: 0;
        height: calc(100% - 55px);
    }
`;

export default class ProjectPreviewModal extends Component {
    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            ModalComponent={StyledModal}
            width='100%'
            title={params.title}
            footer={null}>
            <iframe
                title='preview'
                src={`${process.env.REACT_APP_URL || window.location.origin.replace(/app./, '')}/respondents/${params.project}${params.main ? '' : `/driver/${params.index}`}?demo=1`}
                width='100%'
                height='100%'
                frameBorder='0'
            />
        </Modal>;
    }
}
