import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { contains } from 'ramda';
import styled from 'styled-components';

import RequiredFieldsContext from '../contexts/RequiredFieldsContext';
import FormServerErrorsContext from '../contexts/FormServerErrorsContext';

const ErrorMessage = styled.div`
    margin-top: 5px;
    color: rgb(245, 34, 45);
    line-height: normal;
`;

export default WrappedComponent =>
    class FieldWrapper extends Component {
        static propTypes = {
            field: PropTypes.object,
            form: PropTypes.object,
            onChange: PropTypes.func,
            label: PropTypes.string,
            displayError: PropTypes.bool,
            required: PropTypes.bool,
            wrapperClassName: PropTypes.string,
            extra: PropTypes.string,
            validateOnChange: PropTypes.bool,
            disableClear: PropTypes.bool,
            getRef: PropTypes.func,
            parse: PropTypes.func,
            highlightError: PropTypes.bool,
            wrapperCol: PropTypes.object,
            labelCol: PropTypes.object,
            meta: PropTypes.object,
            input: PropTypes.object
        };

        static defaultProps = {
            displayError: true,
        };

        componentWillUnmount() {
            if (!this.props.disableClear) {
                this.onChange();
            }
        }

        onChange = value => {
            const { input, onChange } = this.props;

            input.onChange(value);
            onChange && onChange(value);
        }

        renderField = serverError => {
            const { input: { name }, label, displayError, required, wrapperClassName, extra, getRef, meta, highlightError, wrapperCol, labelCol, hideErrorMsg } = this.props;
            const error = serverError && !meta.dirtySinceLastSubmit ? serverError : meta.invalid && (meta.submitFailed || highlightError) ? meta.error : null;

            return <RequiredFieldsContext.Consumer>
                { fields =>
                    <Form.Item
                        className={wrapperClassName}
                        label={label}
                        required={contains(name, fields) || required}
                        extra={extra}
                        validateStatus={error && displayError ? 'error' : undefined}
                        wrapperCol={wrapperCol || { span: 24 }}
                        labelCol={labelCol || { span: 24 }}
                    >
                        <WrappedComponent
                            {...this.props}
                            onChange={this.onChange}
                            getRef={getRef}
                        />
                        { error && displayError && !hideErrorMsg && (
                            <ErrorMessage>
                                {error}
                            </ErrorMessage>
                        )}
                    </Form.Item>
                }
            </RequiredFieldsContext.Consumer>;
        }

        render() {
            const { input: { name }} = this.props;

            return (
                <FormServerErrorsContext.Consumer>
                    { serverErrors => this.renderField(serverErrors[name]) }
                </FormServerErrorsContext.Consumer>
            );
        }
    }
