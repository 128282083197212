import React, { Component, Fragment } from 'react';
import { Select, Row, Col } from 'antd';
import styled from 'styled-components';
import { toPairs, is } from 'ramda';

const FilterRow = styled(Row)`
    padding: 15px;
    .ant-select {
        width: 100%;
    }
`;

export default (WrappedComponent, options = {}) =>
    class ReportFiltersWrapper extends Component {
        state = {
            property: undefined,
            value: undefined
        };

        onChangeProperty = property => {
            this.setState({ property, value: undefined });
        }

        onChangeValue = value => {
            this.setState({ value: is(Array, value) ? (value.length ? value : undefined) : value });
        }

        render() {
            const { getReportFilters: { data, meta }} = this.props;
            const { property, value } = this.state;
            const valuesOptions = property ? data[property] : [];

            return <Fragment>
                <FilterRow gutter={15}>
                    <Col span={12}>
                        <Select
                            value={property}
                            onChange={this.onChangeProperty}
                            placeholder='Сегмент'
                            loading={meta.loading}
                            allowClear>
                            { toPairs(data).map(([ name ]) =>
                                <Select.Option value={name} key={name}>
                                    { name }
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                    { !!property && !options.hideValue &&
                        <Col span={12}>
                            <Select
                                mode={options.multiple ? 'multiple' : 'default'}
                                value={value}
                                onChange={this.onChangeValue}
                                placeholder='Значение'
                                loading={meta.loading}
                                allowClear>
                                { valuesOptions.map(option =>
                                    <Select.Option key={option._id} value={option._id}>
                                        { option._id }
                                    </Select.Option>
                                )}
                            </Select>
                        </Col>
                    }
                </FilterRow>
                <WrappedComponent {...this.props} key={value} reportFilter={{ property, value }} />
            </Fragment>;
        }
    }
