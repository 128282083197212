import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Upload, Button, Alert, message } from 'antd';
import { is } from 'ramda';
import { InboxOutlined } from '@ant-design/icons';

import Modal from './Modal';
import { patchProject } from '../../actions/asyncActions';

class ImportQuestionsModal extends Component {
    state = {
        data: null,
        error: false
    };

    onChangeFile = file => {
        const fr = new FileReader();

        fr.readAsText(file);
        fr.onload = () => {
            const text = fr.result;

            try {
                const data = JSON.parse(text);

                if (is(Array, data) && data.length) {
                    this.setState({ data, error: false });
                } else {
                    this.setState({ error: 'Вопросы не найдены' });
                }
            } catch (e) {
               this.setState({ error: 'Не удалось загрузить файл' });
            }
        }

        return false;
    }

    delete = () => this.setState({ data: null });

    import = () => {
        this.props.patchProject.dispatch({
            data: this.state.data.map(value => ({
                op: 'add',
                path: '/drivers/-',
                value
            })),
            id: this.props.params.project
        });
    }

    render() {
        const { modal, patchProject } = this.props;
        const { data, error } = this.state;

        return <Modal
            {...modal}
            title='Импортировать вопросы'
            footer={null}>
            { !!error &&
                <Alert type='error' message={error} style={{ marginBottom: 15 }} />
            }
            <div>
                { data ?
                    <Fragment>
                        { data.map((item, index) =>
                            <div key={`question-${index}`}>
                                { index + 1 }. { item.name }
                            </div>
                        )}
                        <Button
                            style={{ marginTop: 15 }}
                            danger
                            onClick={this.delete}>
                            Удалить вопросы
                        </Button>
                    </Fragment> :
                    <Upload.Dragger
                        beforeUpload={this.onChangeFile}
                        multiple={false}
                        fileList={[]}
                        accept='application/json'>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Для загрузки нажмите или перетащите файл в эту область</p>
                        <p className='ant-upload-hint'>
                            JSON-файл
                        </p>
                    </Upload.Dragger>
                }
            </div>
            <Button
                style={{ marginTop: 15 }}
                type='primary'
                disabled={!data}
                onClick={this.import}
                loading={patchProject.meta.pending}>
                Импортировать
            </Button>
        </Modal>;
    }
}

export default withAsyncActions(({ params: { patchAction }}) => ({
    patchProject: (patchAction || patchProject)
        .withParams(() => ({ type: 'import' }))
        .withSuccessHandler(({ close }) => {
            message.success('Вопросы были успешно импортированы');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось импортировать вопросы'))
        .withOptions({ resetOnUnmount: true })
}))(ImportQuestionsModal);
