import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { prop } from 'ramda';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { Form, Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';

const FieldItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
        margin-right: 10px;
    }
`;

class QuestionnaireForm extends Component {
    renderScaleFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <FieldItem key={`variable-field-${index}`}>
                    <Field
                        name={name}
                        component={Input}
                        validate={value => !value ? 'required' : undefined}
                        hideErrorMsg />
                    <Button danger icon={<DeleteOutlined />} onClick={() => fields.remove(index)} />
                </FieldItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push('')} style={{ marginBottom: 15 }}>
                Добавить значение
            </Button>
        </div>;
    }

    render() {
        const { item } = this.props;

        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            { item &&
                <Form.Item label='Шкала' wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                    <FieldArray name='scale'>
                        { this.renderScaleFields }
                    </FieldArray>
                </Form.Item>
            }
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required()
});

export default withFormWrapper(QuestionnaireForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
