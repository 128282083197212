import React, { Component } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { withAsyncActions } from 'react-async-client';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import SpanAsLink from '../../user/table/SpanAsLink';
import { getProject } from '../../../actions/asyncActions';
import LanguagesSelector from './LanguagesSelector';

const Container = styled.div`
    display: flex;
    align-items: start;
`;

const Variables = styled.div`
    width: 270px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-left: 10px;
    padding: 15px;
    & > div {
        line-height: normal;
        margin-bottom: 5px;
    }
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    & > .ant-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export class VariablesInput extends Component {
    static defaultProps = {
        variables: [],
    };

    onChange = e => {
        this.props.onChange(e.target.value);
    }

    addVariable = id => {
        this.textarea.focus();
        document.execCommand('insertText', false, `[${id}]`);
    }

    render() {
        const {
            input: { value, name },
            translations,
            translationName,
            getTranslationPath,
            translationsWithoutRewrite,
            variables
        } = this.props;
        const hasVariables = !!variables.length;

        return <Container>
            <InputWrapper>
                <Input.TextArea
                    ref={node => this.textarea = node}
                    autoSize={{ minRows: 3 }}
                    value={value}
                    onChange={this.onChange} />
                { translations && <LanguagesSelector
                    name={translationName || name}
                    defaultValue={value}
                    translationsWithoutRewrite={translationsWithoutRewrite}
                    getTranslationPath={getTranslationPath}
                    modal />
                }
            </InputWrapper>
            { hasVariables &&
                <Variables>
                    <h3>Вставить переменную</h3>
                    { variables.map(variable =>
                        <div key={variable.id}>
                            <SpanAsLink
                                onClick={() => this.addVariable(variable.id)}
                                onMouseDown={this.onMouseDown}>
                                { variable.name }
                            </SpanAsLink>
                        </div>
                    )}
                </Variables>
            }
        </Container>
    }
}

export default withFieldWrapper(
    withAsyncActions({ getProject })(VariablesInput)
);
